import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../../common/components";
import PropTypes from "prop-types";

const BufferyardParkingResult = (props) => {
  const parkingSpaces = props.parkingSpaces;
  const bufferYardRequired = (parkingSpaces >= 10);
  return (
    <Table id="bufferyardClassDetail" className="bufferyardClassTable reactTable">
      <colgroup>
        <col key="aspect" style={{width: '50%'}}/>
        <col key="value" style={{width: '50%'}}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell key="aspect">
            <h3>Bufferyard Required?</h3>
          </TableCell>
          <TableCell key="value">
            {bufferYardRequired ? "Yes" : "No"}
          </TableCell>
        </TableRow>
      </TableHead>
      {bufferYardRequired && <TableBody>
        <TableRow>
          <TableCell key="aspect">
            <h4>Min. Bufferyard Width (ft. from property line)</h4>
          </TableCell>
          <TableCell key="value">
            <h4>5 ft.</h4>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
            Setback (from parking spaces)
          </TableCell>
          <TableCell key="value">
            <ul>
              <li>3 ft. from edge of pavement if no curbs or wheel stops</li>
              <li>3 ft. from face of curb or parking bumper that faces the parking space</li>
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
            Screening Device (choose 1 of these options)
          </TableCell>
          <TableCell key="value">
            <ul>
              <li>2 ft. tall continuous hedge</li>
              <li>2 ft. tall continuous row of ornamental grasses</li>
              <li>2 ft. tall berm with a hedge or ornamental grasses along 90% of the linear dimension of the berm</li>
              <li>2 ft. tall masonry wall</li>
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
            Exemptions
          </TableCell>
          <TableCell key="value">
            A parking area bufferyard is not required under the following circumstances:
            <ol>
              <li>The elevation of the parking lot is a minimum of 3 ft. below the crown of the street;</li>
              <li>The area between the parking lot and the street is occupied by a building or access point to the parcel proposed for development;</li>
              <li>The nearest edge of a parking area is 50 ft. or greater from a public or private street or a residentially used or zoned property and vehicle headlights are not visible from such streets or properties; or</li>
              <li>When an existing wall, fence, or berm a minimum of 3 feet in height above grade is in the same location as the required parking bufferyard.</li>
            </ol>
          </TableCell>
        </TableRow>
      </TableBody>}
    </Table>

  );
};
export default BufferyardParkingResult;

BufferyardParkingResult.propTypes = {
  parkingSpaces: PropTypes.number
};