import React from 'react';
import BufferyardDistrictSelection from "./BufferyardDistrictSelection";
import {Button, TextField} from "../../../common/components";
import PropTypes from "prop-types";
import {DISTRICT} from "./BufferyardTypes";

const BufferyardDistrictForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <BufferyardDistrictSelection
        label="Zoning of Parcel Proposed for Development"
        bufferyardType={DISTRICT}
        currentValue={props.developmentDistrict}
        onChange={props.handleChange}
        listName="developmentDistrict"
      />
      <BufferyardDistrictSelection
        label="Zoning of Adjoining District"
        bufferyardType={DISTRICT}
        currentValue={props.adjoiningDistrict}
        onChange={props.handleChange}
        listName="adjoiningDistrict"
      />
      <TextField
        id="propertyLength"
        name="propertyLength"
        label="Length of Property Line (linear feet)"
        type="number"
        value={props.propertyLength}
        onChange={props.handleChange}
        placeholder="Linear Feet"
        inputProps={{ min: 0}}
        fullWidth
      />
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardDistrictForm;
BufferyardDistrictForm.propTypes = {
  adjoiningDistrict: PropTypes.string,
  clearForm: PropTypes.func,
  developmentDistrict: PropTypes.string,
  propertyLength: PropTypes.number,
  handleChange: PropTypes.func,
};