import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import {If} from "../../common/containers";
import {Checkbox, FormControlLabel, MenuItem, Select, TextField} from "../../common/components";
import {PageControl, PageControlContent, PageControlHeader} from "../../common/components/pagecontrol";

class LawsPageControls extends React.PureComponent {

  static propTypes = {
    laws: PropTypes.array,
    filterText: PropTypes.string,
    filterYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterChapters: PropTypes.array,
    filterYearOptions: PropTypes.array,
    filterChapterOptions: PropTypes.array,
    hasLawDeletedViewPermission: PropTypes.bool,
    setFilterText: PropTypes.func.isRequired,
    setFilterYear: PropTypes.func.isRequired,
    showDeletedLaws: PropTypes.bool,
    addFilterChapter: PropTypes.func.isRequired,
    removeFilterChapter: PropTypes.func.isRequired,
    setShowDeletedLaws: PropTypes.func.isRequired
  };

  handleFilterTextChange = (e) => {
    const {setFilterText} = this.props;
    setFilterText(e.target.value);
  };

  handleFilterYearChange = (e) => {
    const {setFilterYear} = this.props;
    setFilterYear(e.target.value);
  };

  handleFilterChapterChange = (guid) => (e) => {
    const {filterChapters, addFilterChapter, removeFilterChapter} = this.props;
    if (e.target.checked) {
      addFilterChapter(filterChapters, guid);
    } else {
      removeFilterChapter(filterChapters, guid);
    }
  };

  handleShowDeletedLawsChange = (e) => {
    const {setShowDeletedLaws} = this.props;
    setShowDeletedLaws(e.target.checked);
  };

  render() {
    const {filterText, filterYear, filterYearOptions, filterChapters, filterChapterOptions, hasLawDeletedViewPermission, laws, showDeletedLaws} = this.props;
    return (!laws || laws.length === 0) ? null : (
      <Fragment>
        {hasLawDeletedViewPermission &&
          <PageControl>
            <PageControlHeader label="Manage laws"/>
            <PageControlContent>
              <FormControlLabel
                key={"show-deleted-laws"}
                className="filterLabel"
                control={
                  <Checkbox
                    id={"show-deleted-laws-check"}
                    checked={showDeletedLaws}
                    onChange={this.handleShowDeletedLawsChange}
                    style={{height: 'auto'}}
                  />
                }
                label={"Show Deleted laws"}
              />
            </PageControlContent>
          </PageControl>
        }
        <PageControl>
          <PageControlHeader label="Filter By Text" htmlFor="filterTextInput"/>
          <PageControlContent>
            <div className="page-control-input">
              <TextField id="filterTextInput" onChange={this.handleFilterTextChange} value={filterText} fullWidth/>
            </div>
          </PageControlContent>
        </PageControl>

        <If test={filterYearOptions.length > 1}>
          <PageControl>
            <PageControlHeader label="Filter By Adoption Year" htmlFor="adoptionYearInput"/>
            <PageControlContent>
              <div className="page-control-input">
                <Select value={filterYear} onChange={this.handleFilterYearChange}>
                  <MenuItem value=""/>
                  {filterYearOptions.map((filterYearOption) => (
                    <MenuItem key={filterYearOption.value} value={filterYearOption.value}>
                      {filterYearOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </PageControlContent>
          </PageControl>
        </If>
        <If test={filterChapterOptions.length > 0}>
          <PageControl scrollable>
            <PageControlHeader label="Filter By Affected Chapter"/>
            <PageControlContent>
              {filterChapterOptions.map((filterChapterOption) => (
                <FormControlLabel
                  key={filterChapterOption.value}
                  className="filterLabel"
                  control={
                    <Checkbox
                      id={"filterChapter-" + filterChapterOption.value}
                      checked={filterChapters.indexOf(filterChapterOption.value) !== -1}
                      onChange={this.handleFilterChapterChange(filterChapterOption.value)}
                      style={{height: 'auto'}}
                    />
                  }
                  label={filterChapterOption.label + " (" + filterChapterOption.count + ")"}
                />
              ))}
            </PageControlContent>
          </PageControl>
        </If>
      </Fragment>
    );
  }
}

export default LawsPageControls;
