import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import ContextHelpContainer from "../../containers/ContextHelpContainer";
import If from "../../containers/If";
import {Button, ButtonGroup, CircularProgress, PrimaryButton} from "../";
import {default as MuiDialog} from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {default as MuiDialogTitle} from "@material-ui/core/DialogTitle";
import {getMediaHeight, getMediaWidth} from "../../selectors";
import {connect} from "react-redux";
import {mediaHeights, mediaWidths} from "../../reducers";

import "./Dialog.css";

class DialogTitle extends React.PureComponent {

  static propTypes = {
    id: PropTypes.string,
    contextId: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    additionalContent: PropTypes.any,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    fullWidth: PropTypes.bool,
    DialogTitleProps: PropTypes.object,
    actions: PropTypes.any,
    content: PropTypes.string,
    DialogActionsProps: PropTypes.object,
    DialogContentProps: PropTypes.object,
    cancelLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    submitDisabled: PropTypes.string,
    footer: PropTypes.any,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
  };

  render() {
    const {id, contextId, className = "", title = null, additionalContent = null, children = null, ...otherProps} = this.props;
    return (
      <MuiDialogTitle id={id ? id : (contextId + "DialogTitle")} className={"muiDialogTitle " + className} {...otherProps}>
        <span>{title ? title : children}</span>
        <ContextHelpContainer contextId={contextId} />
        <If test={additionalContent}>
          <div>{additionalContent}</div>
        </If>
      </MuiDialogTitle>
    );
  }
}

class Dialog extends React.PureComponent {

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  }

  handleClose = (e) => {
    const {onCancel, onClose} = this.props;
    if (onClose) {
      onClose(e);
    }
    if (onCancel) {
      onCancel(e);
    }
  };

  handleSubmit = (e) => {
    const {onSubmit, onClose, submitDisabled} = this.props;
    if (!submitDisabled && (onSubmit || onClose)) {
      e.preventDefault();
      e.stopPropagation();
      if (onClose) {
        onClose(e);
      }
      if (onSubmit) {
        onSubmit(e);
      }
    }
  };

  isElement = (obj) => (obj instanceof Element || obj instanceof HTMLDocument || obj instanceof HTMLElement);

  isString = (obj) => (typeof obj === "string" || obj instanceof String);

  renderElement = (content) => {
    if (!content) {
      return null;
    }
    if (this.isElement(content)) {
      // If this is a javascript element, insert it into the DOM
      return <div ref={(element) => {element && element.appendChild(content);}}/>;
    } else if (this.isString(content) && content.indexOf("<") !== -1 && content.indexOf(">") !== -1) {
      // If this is HTML, render the HTML
      return (<div dangerouslySetInnerHTML={{__html: content}}/>);
    } else {
      // Otherwise, just display it as-is
      return content;
    }
  };

  renderContent = (content, children) => {
    if (content) {
      return this.renderElement(content);
    } else {
      return children;
    }
  };

  renderActions = (actions, onCancel, onSubmit, processing, cancelLabel, submitLabel, submitDisabled) => {
    if (actions) {
      if (actions instanceof Array) {
        return (<Fragment>{actions.map(a => this.renderElement(a))}</Fragment>);
      } else {
        return this.renderElement(actions);
      }
    } else {
      const hasCancelAndSubmit = Boolean(onCancel && onSubmit);
      return (
        <ButtonGroup>
          {processing && <CircularProgress size={30}/>}
          <If test={hasCancelAndSubmit}>
            <Button onClick={this.handleClose}>
              {cancelLabel}
            </Button>
          </If>
          <PrimaryButton variant="contained" onClick={this.handleSubmit} disabled={submitDisabled}>
            {submitLabel}
          </PrimaryButton>

        </ButtonGroup>
      );
    }
  };

  render() {
    const {onClose, onCancel, onSubmit, processing, children, width = null, maxWidth = null,
      fullWidth = null, open = true, title = "", contextId = "",
      className = "", DialogTitleProps = {}, actions = null, content = null,
      PaperProps = {}, DialogActionsProps = {}, DialogContentProps = {},
      cancelLabel = "Cancel", submitLabel = "Ok", submitDisabled = false,
      footer = null, mediaWidth, mediaHeight, fullScreen = false,
      ...otherProps
    } = this.props;
    const { className: dialogPaperClassName = "", ...otherDialogPaperProps} = PaperProps;
    const { className: dialogContentClassName = "", ...otherDialogContentProps} = DialogContentProps;
    const { style: dialogActionsStyle = {}, className: dialogActionsClassName = "", ...otherDialogActionsProps} = DialogActionsProps;
    let dialogMaxWidth;
    if (width != null) {
      dialogMaxWidth = width;
    } else {
      dialogMaxWidth = (fullWidth == null && maxWidth == null) ? "sm" : maxWidth;
    }
    const dialogIsFullWidth = (width != null || fullWidth);
    return (
      <MuiDialog
        open={open}
        maxWidth={dialogMaxWidth}
        fullWidth={dialogIsFullWidth}
        fullScreen={mediaWidth <= mediaWidths.small || mediaHeight <= mediaHeights.short || fullScreen}
        onClose={(e, reason) => { if (reason !== "backdropClick") this.handleClose(e); }}
        onKeyDown={this.handleEnter}
        className={"muiDialog " + className}
        PaperProps={{className: "muiDialogPaper " + dialogPaperClassName, ...otherDialogPaperProps}}
        {...otherProps}
      >
        <If test={title}>
          <DialogTitle {...DialogTitleProps} contextId={contextId}>{this.renderElement(title)}</DialogTitle>
        </If>
        <DialogContent className={"muiDialogContent " + dialogContentClassName} {...otherDialogContentProps}>
          {this.renderContent(content, children)}
        </DialogContent>
        <DialogActions
          className={"muiDialogActions " + dialogActionsClassName}
          style={{display: (footer ? "block" : "flex"), ...dialogActionsStyle}}
          {...otherDialogActionsProps}
        >
          {this.renderActions(actions, onCancel, onSubmit, processing, cancelLabel, submitLabel, submitDisabled)}
          <If test={footer}><div style={{padding: "0 16px 4px 16px"}}>{footer}</div></If>
        </DialogActions>
      </MuiDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  mediaWidth: getMediaWidth(state),
  mediaHeight: getMediaHeight(state),
});

export default connect(mapStateToProps)(Dialog);
export {Dialog, DialogTitle};