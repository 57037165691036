import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {getBufferyardType} from "../selectors";
import {toJS} from "../../common/utils";
import BufferyardCalculator from "../components/bufferyard/BufferyardCalculator";
import {setBufferyardType} from "../actions";

const mapStateToProps = createStructuredSelector({
  bufferyardType: getBufferyardType
});
const mapDispatchToProps = (dispatch) => ({
  setBufferyardType: (bufferyardType) => {
    dispatch(setBufferyardType(bufferyardType));
  },
});

const BufferyardContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(BufferyardCalculator);
export default BufferyardContainer;