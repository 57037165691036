import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Checkbox, Dialog, IconButton, Paper, Tooltip} from "../../common/components";
import {Menu, MenuItem} from "../../common/components/menu";
import {Accordion} from "../../common/components/accordion";
import If from "../../common/containers/If";
import Metadata from "../../notes/components/Metadata";
import {AudioFile as AudioFileIcon, DocumentFile as DocumentFileIcon,
  MenuOptionsVert as MenuOptionsVertIcon, VideoFile as VideoFileIcon,
  VisibilityOff as VisibilityOffIcon} from "../../common/components/icons";
import "./PubDocType.css";

const rowHeight = 40;

class PubDoc extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocIds: PropTypes.array.isRequired,
    doc: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    prevDoc: PropTypes.object
  }

  state = {
    documentAnchorEl: null,
    documentDeleteOpen: false
  }

  documentCheckboxChange = (event) => {
    const {setDocumentChecked, setDocumentUnchecked} = this.props;
    if (event.target.checked) {
      setDocumentChecked();
    } else {
      setDocumentUnchecked();
    }
  }

  openDocumentMenu = (documentId) => (event) => {
    this.setState({
      documentAnchorEl: event.currentTarget,
      documentEditId: documentId
    });
  };

  closeDocumentMenu = () => {
    this.setState({documentAnchorEl: null});
  };

  openEditDocumentDialog = () => {
    this.setState({
      documentAnchorEl: null,
      typeAnchorEl: null
    });
    this.props.setDocumentEditOpen(true);
  }

  openMoveDocumentDialog = () => {
    this.setState({
      documentAnchorEl: null,
      typeAnchorEl: null
    });
    this.props.setDocumentMoveOpen(true);
  }

  handleOpenDeleteDocument = () => {
    this.setState({
      documentAnchorEl: null,
      documentDeleteOpen: true
    });
  }

  handleCloseDeleteDocument = () => {
    this.setState({documentDeleteOpen: false});
  }

  deleteDocument = () => {
    this.setState({documentDeleteOpen: false});
    this.props.deleteDocument(this.props.custId);
  }

  handleDownloadOriginal = () => {
    const {custId, doc} = this.props;
    window.location.href = `/documents/${custId}/public/${doc.id}.${doc.origExtension}?download=true`;
  }

  render() {
    const {custId, hasPubDocPrivateViewPermission, hasDocumentAdminPermission, selectedDocIds, category, doc, prevDoc} = this.props;
    const {documentAnchorEl, documentDeleteOpen} = this.state;
    const selected = selectedDocIds.includes(doc.id);
    const documentYear = doc.year;
    const previousDocumentYear = prevDoc ? prevDoc.year : null;
    const displayYear = ((previousDocumentYear === null || documentYear !== previousDocumentYear) ? documentYear : null);
    return(
      <div>
        <If test={(category.groupByType === "Year_Ascending" || category.groupByType === "Year_Descending") && displayYear}>
          <Paper elevation={0} className={"pubDocYearPaper"}>
            <span id={`category-${category.id}-year-${displayYear?.replace(/ /g, "")}`} className={"pubDocYearRow"}>
              {displayYear}
            </span>
          </Paper>
        </If>
        <Accordion
          className={"pubDocAccordion"}
          SummaryProps={{ classes: {content: "pubDocRowContent"}, className: "pubDocAccordionSummary"}}
          summary={(<>
            <If test={hasDocumentAdminPermission}>
              <Checkbox className={"pubDocCheckbox"} onChange={this.documentCheckboxChange} name={String(doc.id)} checked={selected}/>
            </If>
            <div className={"pubDocTitleContainer"}>
              <If test={doc.type === "DOCUMENT"}>
                <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={"/" + custId + "/document/" + doc.id + "." + doc.extension}>{doc.title}</a>
                <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                  <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                </If>
                <Tooltip title="Public Document">
                  <DocumentFileIcon className="pubDocIcon"/>
                </Tooltip>
              </If>
              <If test={doc.type === "AUDIO_FILE"}>
                <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={"/" + custId + "/document/" + doc.id + "." + doc.extension}>{doc.title}</a>
                <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                  <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                </If>
                <Tooltip title="Audio File">
                  <AudioFileIcon className="pubDocIcon"/>
                </Tooltip>
              </If>
              <If test={doc.type === "AUDIO_LINK"}>
                <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={doc.url}>{doc.title}</a>
                <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                  <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                </If>
                <Tooltip title="Linked Audio">
                  <AudioFileIcon className="pubDocIcon"/>
                </Tooltip>
              </If>
              <If test={doc.type === "VIDEO_LINK"}>
                <a className="smallTitle pubDocTitle" target={"_blank"} rel={"noreferrer noopener"} href={doc.url}>{doc.title}</a>
                <If test={!category.private && doc.private && hasPubDocPrivateViewPermission}>
                  <VisibilityOffIcon className={"pubDocVisibilityIcon"} titleAccess={"private"}/>
                </If>
                <Tooltip title="Linked Video">
                  <VideoFileIcon className="pubDocIcon"/>
                </Tooltip>
              </If>
            </div>
            <If test={hasDocumentAdminPermission}>
              <IconButton className="pubDocRowButton" onClick={this.openDocumentMenu(doc.id)}>
                <MenuOptionsVertIcon/>
              </IconButton>
              <Menu
                anchorEl={documentAnchorEl}
                className="pubDocTypeMenu"
                open={documentAnchorEl !== null}
                onClose={this.closeDocumentMenu}
              >
                <MenuItem onClick={this.openEditDocumentDialog}>
                  Edit Document
                </MenuItem>
                <MenuItem onClick={this.openMoveDocumentDialog}>
                  Move Document
                </MenuItem>
                <MenuItem onClick={this.handleOpenDeleteDocument}>
                  Delete Document
                </MenuItem>
                <If test={doc.type === "DOCUMENT" || doc.type === "AUDIO_FILE"}>
                  <MenuItem onClick={this.handleDownloadOriginal}>
                    Download Original
                  </MenuItem>
                </If>
              </Menu>
            </If>
          </>)}
          DetailsProps={{className: "pubDocAccordionDetails"}}
          details={(<>
            <If test={doc.description}>
              <div>{doc.description}</div>
            </If>
            <If test={doc.date}>
              <div><b>Date: </b>{doc.date ? doc.date.substr(0, 10) : ""}</div>
            </If>
            <Metadata
              className={"noteMetadataNoBottom"}
              editable={hasDocumentAdminPermission}
              createdOn={doc.created ? doc.created.substr(0, 10) : null}
              createdBy={{name: doc.createdBy}}
              updatedOn={doc.updated ? doc.updated.substr(0, 10) : null}
              updatedBy={{name: doc.updatedBy}}
            />
          </>)}
          elevation={0}
          PaperProps={{ style: {margin: 8} }}
          paperOnExpand
          iconExpandOnly
        />
        <If test={documentDeleteOpen}>
          <Dialog
            title="Delete Public Document?"
            open={documentDeleteOpen}
            onCancel={this.handleCloseDeleteDocument}
            onSubmit={this.deleteDocument}
          >
            Are you sure you want to delete: {doc.title}?
          </Dialog>
        </If>
      </div>
    );
  }
}

export {rowHeight};
export default PubDoc;
