const BufferyardDistrictXref = {
  "RE": {
    "RE": null,
    "SF-1": null,
    "SF-2": null,
    "MU-1": null,
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "SF-1": {
    "RE": "A",
    "SF-1": null,
    "SF-2": null,
    "MU-1": null,
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "SF-2": {
    "RE": "A",
    "SF-1": null,
    "SF-2": null,
    "MU-1": null,
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "MU-1": {
    "RE": "A",
    "SF-1": null,
    "SF-2": null,
    "MU-1": null,
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "MDR": {
    "RE": "B",
    "SF-1": "A",
    "SF-2": "A",
    "MU-1": "A",
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "HDR": {
    "RE": "B",
    "SF-1": "A",
    "SF-2": "A",
    "MU-1": "A",
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "MU-2": {
    "RE": "B",
    "SF-1": "B",
    "SF-2": "B",
    "MU-1": "B",
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "MU-3": {
    "RE": "B",
    "SF-1": "B",
    "SF-2": "B",
    "MU-1": "B",
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "MU-5": {
    "RE": "B",
    "SF-1": "B",
    "SF-2": "B",
    "MU-1": "B",
    "MDR": null,
    "HDR": null,
    "MU-2": null,
    "MU-3": null,
    "MU-5": null,
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "NC": {
    "RE": "B",
    "SF-1": "B",
    "SF-2": "B",
    "MU-1": "B",
    "MDR": "B",
    "HDR": "B",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "OF": {
    "RE": "B",
    "SF-1": "B",
    "SF-2": "B",
    "MU-1": "B",
    "MDR": "B",
    "HDR": "B",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": null,
    "OF": null,
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "AC": {
    "RE": "C",
    "SF-1": "C",
    "SF-2": "C",
    "MU-1": "C",
    "MDR": "C",
    "HDR": "C",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": "A",
    "OF": "A",
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "HC": {
    "RE": "C",
    "SF-1": "C",
    "SF-2": "C",
    "MU-1": "C",
    "MDR": "C",
    "HDR": "C",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": "A",
    "OF": "A",
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "IP": {
    "RE": "C",
    "SF-1": "C",
    "SF-2": "C",
    "MU-1": "C",
    "MDR": "C",
    "HDR": "C",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": "A",
    "OF": "A",
    "AC": null,
    "HC": null,
    "IP": null,
    "LI": null,
    "GI": null
  },
  "LI": {
    "RE": "C",
    "SF-1": "C",
    "SF-2": "C",
    "MU-1": "C",
    "MDR": "C",
    "HDR": "C",
    "MU-2": "B",
    "MU-3": "B",
    "MU-5": "B",
    "NC": "B",
    "OF": "B",
    "AC": "B",
    "HC": "B",
    "IP": "B",
    "LI": null,
    "GI": null
  },
  "GI": {
    "RE": "D",
    "SF-1": "D",
    "SF-2": "D",
    "MU-1": "D",
    "MDR": "D",
    "HDR": "D",
    "MU-2": "C",
    "MU-3": "C",
    "MU-5": "C",
    "NC": "C",
    "OF": "C",
    "AC": "C",
    "HC": "C",
    "IP": "C",
    "LI": "B",
    "GI": null
  },
};

const lookupDistrictBufferyardClass = (development,adjoining) => {
  return BufferyardDistrictXref[development][adjoining];
};
export default lookupDistrictBufferyardClass;