import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
// noinspection ES6CheckImport
import {toJS} from "../../common/utils";

import SearchBar from "../components/SearchBar";
import {
  getCustId,
  getCustomerWarnings,
  getSearchSuggestionWaitTime,
  hasAdvancedSearchPermission,
  hasMulticodePermission
} from "../../common/selectors";
import {
  getQuery,
  getScope,
  getSearchedCodeCount,
  getSelectedCustIds,
  getSelectedCustomerFilters,
  getSelectedStructures,
  isSearchFieldActive,
} from "../selectors";

import {
  getSuggestions,
  resetSelectedCustomers,
  resetSelectedStructures,
  setAdvancedSearchOpen,
  setCodeFinderOpenAndLog,
  setQuery,
  setScopeAndGetResults,
  setSearchFieldActive,
  submitNewSearch
} from "../actions";

const mapStateToProps = createStructuredSelector({
  query: getQuery,
  scope: getScope,
  selectedStructures: getSelectedStructures,
  selectedCustIds: getSelectedCustIds,
  selectedCustomerFilters: getSelectedCustomerFilters,
  searchedCodeCount: getSearchedCodeCount,
  searchSuggestionWaitTime: getSearchSuggestionWaitTime,
  isSearchFieldActive: isSearchFieldActive,
  hasAdvancedSearchPermission: hasAdvancedSearchPermission,
  hasMulticodePermission: hasMulticodePermission,
  custId: getCustId,
  customerWarnings: getCustomerWarnings,
});

const mapDispatchToProps = (dispatch) => ({
  setQuery: (query) => {
    dispatch(setQuery(query));
  },
  setScope: (sortOrder) => {
    dispatch(setScopeAndGetResults(sortOrder));
  },
  submitSearch: () => {
    dispatch(submitNewSearch(false, false));
  },
  setCodeFinderOpen: (isCodeFinderOpen) => {
    dispatch(setCodeFinderOpenAndLog(isCodeFinderOpen));
  },
  resetSelectedCustomers: () => {
    dispatch(resetSelectedCustomers());
  },
  resetSelectedStructures: () => {
    dispatch(resetSelectedStructures());
  },
  setAdvancedSearchOpen: (isAdvancedSearchOpen) => {
    dispatch(setAdvancedSearchOpen(isAdvancedSearchOpen));
  },
  getSearchSuggestions: (query) => {
    dispatch(getSuggestions(query));
  },
  setSearchFieldActive: (isSearchFieldActive) => {
    dispatch(setSearchFieldActive(isSearchFieldActive));
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SearchBar);
