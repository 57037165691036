import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {getSignageClass} from "../selectors";
import {toJS} from "../../common/utils";
import SignageCalculator from "../components/signage/SignageCalculator";
import {setSignageClass} from "../actions";

const mapStateToProps = createStructuredSelector({
  signageClass: getSignageClass
});
const mapDispatchToProps = (dispatch) => ({
  setSignageClass: (signageClass) => {
    dispatch(setSignageClass(signageClass));
  },
});

const SignageContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SignageCalculator);
export default SignageContainer;