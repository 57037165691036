import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";

import {toJS} from "../../common/utils";
import LawsPageControls from "../components/LawsPageControls";
import {
  getLaws, getFilterText, getFilterYear, getFilterChapters, getFilterYearOptions,
  getFilterChapterOptions, getShowDeletedLaws
} from "../selectors";
import {hasLawDeletedViewPermission} from "../../common/selectors";
import {setFilterText, setFilterYear, setFilterChapters, setShowDeletedLaws} from "../actions";
import {addPageFilter, removePageFilter} from "../../common/actions";
import * as PropTypes from "prop-types";


const mapStateToProps = createStructuredSelector({
  laws: getLaws,
  filterText: getFilterText,
  filterYear: getFilterYear,
  filterChapters: getFilterChapters,
  filterYearOptions: getFilterYearOptions,
  filterChapterOptions: getFilterChapterOptions,
  hasLawDeletedViewPermission: hasLawDeletedViewPermission,
  showDeletedLaws: getShowDeletedLaws
});
const mapDispatchToProps = (dispatch, state) => ({
  setFilterText: (filterText) => {
    dispatch(setFilterText(filterText));
    if (filterText) {
      dispatch(addPageFilter("filterText"));
    } else {
      dispatch(removePageFilter("filterText"));
    }
  },
  setFilterYear: (filterYear) => {
    dispatch(setFilterYear(filterYear));
    if (filterYear) {
      dispatch(addPageFilter("filterYear"));
    } else {
      dispatch(removePageFilter("filterYear"));
    }
  },
  setShowDeletedLaws: (showDeletedLaws) => {
    dispatch(setShowDeletedLaws(showDeletedLaws));
  },
  addFilterChapter: (currentFilterChapters, newFilterChapter) => {
    dispatch(setFilterChapters([...currentFilterChapters, newFilterChapter]));
    dispatch(addPageFilter(newFilterChapter));
  },
  removeFilterChapter: (currentFilterChapters, removedFilterChapter) => {
    dispatch(setFilterChapters(currentFilterChapters.filter(ch => ch !== removedFilterChapter)));
    dispatch(removePageFilter(removedFilterChapter));
  }
});

const LawsPageControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(LawsPageControls);
LawsPageControlsContainer.propTypes = {
  lawCount: PropTypes.number
};
export default LawsPageControlsContainer;
