import React from "react";
import * as PropTypes from "prop-types";
import {CircularProgress, Dialog, FormControlLabel, MenuItem, Select, Switch, TextField} from "../../common/components";
import {If} from "../../common/containers";

import "./PubDocCategoryEditDialog.css";

class PubDocCategoryEditDialog extends React.Component {

  static propTypes = {
    categoryEditOpen: PropTypes.bool,
    categoryEditId: PropTypes.number,
    categories: PropTypes.array,
    pubDocTypes: PropTypes.array,
    hasPubDocPrivateViewPermission: PropTypes.bool,
    categoryEditLoading: PropTypes.bool,
    addCategory: PropTypes.func.isRequired,
    editCategory: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    custId: PropTypes.string.isRequired,
    typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    typeTitle: PropTypes.string,
    typeSelectDisabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      name: "",
      description: "",
      isPrivate: false,
      groupByType: "",
      sortByFirstType: "Alphabetical_Ascending",
      sortBySecondType: "None",
      pubDocTypeId: props.typeId
    };
  }

  handleSaveClick = () => {
    const {name, description, isPrivate, groupByType, sortByFirstType, sortBySecondType, pubDocTypeId} = this.state;
    const {custId, typeTitle, categoryEditId} = this.props;
    if (categoryEditId) {
      this.props.editCategory(custId, name, description, pubDocTypeId, typeTitle, categoryEditId, isPrivate, groupByType, sortByFirstType, sortBySecondType);
    } else {
      this.props.addCategory(custId, name, description, pubDocTypeId, typeTitle, isPrivate, groupByType, sortByFirstType, sortBySecondType);
    }
  }

  handleCancelClick = () => {
    this.setState({name: "", description: ""});
    this.props.onCancel();
  };

  handleTypeChange = (event) => {
    const value = event.target.value;
    this.setState({pubDocTypeId: value});
  };

  handleNameChange = (event) => {
    const value = event.target.value;
    this.setState({name: value});
  };

  handleDescriptionChange = (event) => {
    const value = event.target.value;
    this.setState({description: value});
  };

  handleChangePrivate = (event) => {
    this.setState({isPrivate: event.target.checked});
  }

  handleGroupByTypeChange = (event) => {
    this.setState({groupByType: event.target.value});
  }

  handleSortByFirstTypeChange = (event) => {
    this.setState({sortByFirstType: event.target.value});
  }

  handleSortBySecondTypeChange = (event) => {
    this.setState({sortBySecondType: event.target.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.categoryEditOpen;
    const { categoryEditOpen, categoryEditId, categories, typeId } = this.props;
    if (categoryEditOpen && !prevEditOpen) {
      if (categoryEditId) {
        const currCategory = categories.find(category => category.id === categoryEditId);
        if (currCategory) {
          this.setState({
            title: "Edit a Category",
            name: currCategory.title ? currCategory.title : "",
            description: currCategory.description ? currCategory.description : "",
            isPrivate: currCategory.private ? currCategory.private : false,
            groupByType: currCategory.groupByType ? currCategory.groupByType : "",
            sortByFirstType: currCategory.sortByFirstType ? currCategory.sortByFirstType : "Alphabetical_Ascending",
            sortBySecondType: currCategory.sortBySecondType ? currCategory.sortBySecondType : "None",
            pubDocTypeId: typeId
          });
        }
      } else {
        this.setState({
          title: "Add a Category",
          name: "",
          description: "",
          isPrivate: false,
          groupByType: "Ungrouped",
          sortByFirstType: "Alphabetical_Ascending",
          sortBySecondType: "None",
          pubDocTypeId: typeId
        });
      }
    }
  }

  render() {
    const {categoryEditOpen, hasPubDocPrivateViewPermission, categoryEditLoading, pubDocTypes, typeSelectDisabled} = this.props;
    const {title, name, description, isPrivate, groupByType, sortByFirstType, sortBySecondType, pubDocTypeId} = this.state;
    return (
      <div>
        <Dialog
          title={title}
          open={categoryEditOpen}
          onCancel={this.handleCancelClick}
          onSubmit={this.handleSaveClick}
          submitLabel="Save"
          submitDisabled={categoryEditLoading || name.length < 1 || !name.trim() || pubDocTypeId == null}
          width="md"
          DialogContentProps={{className: `pubDocCategoryEditDialogContent ${!categoryEditLoading ? "" : "loading"}`}}
        >
          <If test={categoryEditLoading}>
            <div className="spinner">
              <CircularProgress size={80}/>
            </div>
          </If>
          <If test={!categoryEditLoading}>
            <div className="dialogRow">
              <If test={typeSelectDisabled}>
                <div>
                  <label
                    id="edit-doc-type-label"
                    htmlFor="edit-doc-type"
                    className={"pubDocEditDialogTypeLabel"}
                  >
                    Type:
                  </label>
                  <span id="edit-doc-type">{this.props.typeTitle}</span>
                </div>
              </If>
              <If test={!typeSelectDisabled}>
                <Select
                  variant="outlined"
                  id="edit-doc-type"
                  className="pubDocEditDialogTypeSelect"
                  label="Type"
                  value={pubDocTypeId ? pubDocTypeId : 0}
                  onChange={this.handleTypeChange}
                >
                  {pubDocTypes.map((pubDocType) => (
                    <MenuItem key={pubDocType.id} value={pubDocType.id}>{pubDocType.title}</MenuItem>)
                  )}
                </Select>
              </If>
            </div>
            <div className="dialogRow">
              <TextField
                variant="outlined"
                label="Title"
                value={!name ? "" : name}
                onChange={this.handleNameChange}
                className={"pubDocCategoryEditTitle"}
              />
            </div>
            <div className="dialogRow">
              <TextField
                variant="outlined"
                label="Description"
                value={!description ? "" : description}
                onChange={this.handleDescriptionChange}
                className={"pubDocCategoryEditDescription"}
                minRows={6}
                multiline
              />
            </div>
            <div className="dialogRow">
              <div className={"pubDocCategoryEditPrivateSelector"}>
                <If test={hasPubDocPrivateViewPermission}>
                  <FormControlLabel
                    label="Private?"
                    control={<Switch checked={isPrivate} onChange={this.handleChangePrivate}/>}
                  />
                </If>
              </div>
            </div>
            <div style={{display: "flex", columnGap: "4px"}}>
              <Select className="pubDocCategoryEditForm" label="Group By" value={groupByType} onChange={this.handleGroupByTypeChange}>
                <MenuItem value="Ungrouped">Ungrouped</MenuItem>
                <MenuItem value="Year_Ascending">By Year (Ascending)</MenuItem>
                <MenuItem value="Year_Descending">By Year (Descending)</MenuItem>
              </Select>
              <Select className="pubDocCategoryEditForm" label="First Sort By" value={sortByFirstType} onChange={this.handleSortByFirstTypeChange}>
                <MenuItem value="Alphabetical_Ascending">Alphabetical Ascending</MenuItem>
                <MenuItem value="Alphabetical_Descending">Alphabetical Descending</MenuItem>
                <MenuItem value="Date_Ascending">Date Ascending</MenuItem>
                <MenuItem value="Date_Descending">Date Descending</MenuItem>
              </Select>
              <Select className="pubDocCategoryEditForm" label="Then Sort By" value={sortBySecondType} onChange={this.handleSortBySecondTypeChange}>
                <MenuItem value="None">No Sorting</MenuItem>
                <MenuItem value="Alphabetical_Ascending">Alphabetical Ascending</MenuItem>
                <MenuItem value="Alphabetical_Descending">Alphabetical Descending</MenuItem>
                <MenuItem value="Date_Ascending">Date Ascending</MenuItem>
                <MenuItem value="Date_Descending">Date Descending</MenuItem>
              </Select>
            </div>
          </If>
        </Dialog>
      </div>
    );
  }
}

export default PubDocCategoryEditDialog;