import {ModuleKey} from "./ModuleKey";
import {getPageSubType, getPageType} from "../common/selectors";
import ReactDOM from "react-dom";
import React from "react";
import {BUFFERYARD, PARKING, SIGNAGE} from "../common/utils/page-sub-types";
import BufferyardContainer from "./containers/BufferyardContainer";

import * as actions from "./actions";
import reducer, {initialState} from "./reducers";
import "./calculators.css";
import ParkingContainer from "./containers/ParkingContainer";
import SignageContainer from "./containers/SignageContainer";
import CalulatorPageTitleContainer from "./containers/CalclatorPageTitleContainer";
import {CALCULATOR} from "../common/utils/page-types";


export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }

  let calcState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, calcState.withMutations(loadingCalcState => {
    return loadingCalcState;
  }));
};

const init = (Providers, dispatch, state) => {
  const pt = getPageType(state);
  const pst = getPageSubType(state);
  let pageTitleContainer = document.getElementById('pageTitleContainer');
  if (pt === CALCULATOR && pageTitleContainer) {
    ReactDOM.render(
      <Providers key="calculator-page-title-container-providers" name="calculator-page-title-container">
        <CalulatorPageTitleContainer/>
      </Providers>,
      pageTitleContainer
    );
    pageTitleContainer.removeAttribute('id');
  }
  if (pst === BUFFERYARD) {
    const calculator = document.querySelector("#bufferyardCalculator");
    ReactDOM.render(
      <Providers key="calculators-providers" name="bufferyard-calculator">
        <BufferyardContainer/>
      </Providers>,
      calculator
    );
  }
  if (pst === PARKING) {
    const calculator = document.querySelector("#parkingCalculator");
    ReactDOM.render(
      <Providers key="calculators-providers" name="parking-calculator">
        <ParkingContainer/>
      </Providers>,
      calculator
    );
  }
  if (pst === SIGNAGE) {
    const calculator = document.querySelector("#signageCalculator");
    ReactDOM.render(
      <Providers key="calculators-providers" name="signage-calculator">
        <SignageContainer/>
      </Providers>,
      calculator
    );
  }
};

export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer
};