import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {getPubdocsLongName, hasDocumentAdminPermission, hasPubDocPrivateViewPermission,
  hasPubDocTypeEditPermission, hasPubDocTypeDeletePermission} from "../../common/selectors";
import PageTitleContainer from "../../common/containers/PageTitleContainer";
import {getAllCategories, getPubDocTypes} from "../selectors";
import {
  Accordion,
  IconButton,
  Menu,
  MenuItem,
  Paper
} from "../../common/components";
import If from "../../common/containers/If";
import {
  MenuOptionsVert as MenuOptionsVertIcon,
  VisibilityOff as VisibilityOffIcon
} from "../../common/components/icons";
import {custId} from "../../common/utils/server-data";
import {
  loadPubDocsCategories,
  setCategoryDeleteErrorOpen,
  setCategoryDeleteOpen,
  setPubDocCategoryEditOpen,
  setPubDocEditOpen,
  setPubDocTypeEditOpen,
  setPubDocTypeDeleteOpen,
} from "../actions";
import PubDocDialogsContainer from "../containers/PubDocDialogsContainer";

import "./PubDocType.css";
import "./PubDocCategory.css";

const PubDocsLandingPage =
({
  pubDocsPageName, hasDocumentAdminPermission, hasPubDocPrivateViewPermission,
  hasPubDocTypeEditPermission, hasPubDocTypeDeletePermission, pubDocTypes, categories,
  loadPubDocsCategories, openCategoryEditDialog, openTypeEditDialog, openTypeDeleteDialog,
  openCategoryDeleteDialog, openCategoryDeleteErrorDialog, openDocumentAddDialog
}) => {

  const [typeAnchorEl, setTypeAnchorEl] = useState(null);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    loadPubDocsCategories(custId);
  }, [loadPubDocsCategories]);

  const onTypeMenuOpen = (typeId) => (e) => {
    setTypeAnchorEl(e.currentTarget);
    setSelectedTypeId(typeId);
  };

  const onTypeMenuClose = () => {
    setTypeAnchorEl(null);
  };

  const onEditType = () => {
    setTypeAnchorEl(null);
    openTypeEditDialog(selectedTypeId);
  };

  const onDeleteType = () => {
    setTypeAnchorEl(null);
    openTypeDeleteDialog(selectedTypeId);
  };

  const onCategoryMenuOpen = (typeId, categoryId) => (e) => {
    setCategoryAnchorEl(e.currentTarget);
    setSelectedTypeId(typeId);
    setSelectedCategoryId(categoryId);
  };

  const onCategoryMenuClose = () => {
    setCategoryAnchorEl(null);
  };

  const onEditCategory = () => {
    setCategoryAnchorEl(null);
    openEditDialog(selectedTypeId, selectedCategoryId);
  };

  const onAddCategory = () => {
    setTypeAnchorEl(null);
    openEditDialog(selectedTypeId, null);
  };

  const openEditDialog = (typeId, categoryId) => {
    setSelectedCategoryId(null);
    openCategoryEditDialog(categoryId, typeId);
  };

  const onDeleteCategory = () => {
    const category = categories.find(c => c.id === selectedCategoryId);
    if (!category || category.documentCount > 0) {
      openCategoryDeleteErrorDialog();
    } else {
      openCategoryDeleteDialog(selectedCategoryId);
    }
    setCategoryAnchorEl(null);
  };

  const onDocumentAdd = () => {
    setCategoryAnchorEl(null);
    setTypeAnchorEl(null);
    openDocumentAddDialog(selectedTypeId, selectedCategoryId);
  };

  const sortCategories = (sortType) => (a, b) => {
    if (!a.title || a.title === "(Uncategorized)") return -1;
    if (!b.title || b.title === "(Uncategorized)") return 1;
    if (sortType === "CATEGORY_NAME_DESC") {
      return new Intl.Collator([], {numeric: true, sensitivity: 'base'})
        .compare(b.title.toLowerCase(), a.title.toLowerCase());
    } else {
      return new Intl.Collator([], {numeric: true, sensitivity: 'base'})
        .compare(a.title.toLowerCase(), b.title.toLowerCase());
    }
  };

  const renderType = (typeId, typeName = "", sortType = "CATEGORY_NAME_ASC") => {
    const typeUrl = `/${custId}/documents/${encodeURIComponent(typeName?.replace(/ /g, "_"))}`;
    const typeCategories = categories.filter(category => category.typeId === typeId
      && (!category.private || hasPubDocPrivateViewPermission)).sort(sortCategories(sortType));
    return (

      <Accordion
        key={typeId}
        className={"pubDocRow pubDocAccordion"}
        style={{marginBottom: 4}}
        SummaryProps={{
          classes: {content: "pubDocRowContent"},
          className: "pubDocAccordionSummary",
        }}
        summary={(<>
          <div className={"pubDocTitleContainer"}>
            <a className="mediumTitle customerTitleColor pubDocCategoryTitle"
              href={typeUrl} target={"_blank"} rel={"noreferrer noopener"}
            >
              {typeName}
            </a>
          </div>
          <If test={hasDocumentAdminPermission}>
            <IconButton className="pubDocRowButton" onClick={onTypeMenuOpen(typeId)}>
              <MenuOptionsVertIcon/>
            </IconButton>
          </If>
        </>)}
        DetailsProps={{className: "pubDocAccordionDetails"}}
        details={(<>
          {typeCategories.map((category) => (
            <div key={category.id}>
              <Paper elevation={0} className={"pubDocRow"}>
                <div className={"pubDocTitleContainer"}>
                  <div className="pubDocTitle">
                    <a className="smallTitle" href={`${typeUrl}#category-${category.id}`} target={"_blank"}
                      rel={"noreferrer noopener"}
                    >
                      {category.title}
                    </a>
                  </div>
                  <span className="pubDocCategoryCount">{category.documentCount} Document{(category.documentCount === 0 || category.documentCount > 1) ? "s" : ""}</span>
                  <If test={category.private && hasPubDocPrivateViewPermission}>
                    <VisibilityOffIcon className={"pubDocCategoryVisiblityIcon"} title={"private"}/>
                  </If>
                  <If test={hasDocumentAdminPermission}>
                    <IconButton className="pubDocRowButton" onClick={onCategoryMenuOpen(typeId, category.id)}>
                      <MenuOptionsVertIcon/>
                    </IconButton>
                  </If>
                </div>
              </Paper>
            </div>
          ))}
          <If test={(typeCategories.length || typeCategories.size) < 1}>
            <div>No categories have been added to this type yet.</div>
          </If>
        </>)}
      />);
  };

  return (
    <div className={"pubDocsLandingPage"}>
      <PageTitleContainer pageTitle={pubDocsPageName + " Management"}/>
      {pubDocTypes.map((pubDocType) => renderType(pubDocType.id, pubDocType.title, pubDocType.sortType))}
      <If test={hasDocumentAdminPermission}>
        <Menu
          anchorEl={typeAnchorEl}
          className="pubDocTypeMenu"
          open={typeAnchorEl !== null}
          onClose={onTypeMenuClose}
        >
          <If test={hasPubDocTypeEditPermission}>
            <MenuItem onClick={onEditType}>
              Edit Type
            </MenuItem>
          </If>
          <If test={hasPubDocTypeDeletePermission}>
            <MenuItem onClick={onDeleteType}>
              Delete Type
            </MenuItem>
          </If>
          <MenuItem onClick={onAddCategory}>
            Add Category
          </MenuItem>
          <MenuItem onClick={onDocumentAdd}>
            Add Document
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={categoryAnchorEl}
          className="pubDocCategoryMenu"
          open={categoryAnchorEl !== null}
          onClose={onCategoryMenuClose}
        >
          <MenuItem onClick={onEditCategory}>
            Edit Category
          </MenuItem>
          <MenuItem onClick={onDeleteCategory}>
            Delete Category
          </MenuItem>
          <MenuItem onClick={onDocumentAdd}>
            Add Document
          </MenuItem>
        </Menu>
      </If>
      <PubDocDialogsContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  pubDocsPageName: getPubdocsLongName(state),
  hasDocumentAdminPermission: hasDocumentAdminPermission(state),
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission(state),
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission(state),
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission(state),
  pubDocTypes: getPubDocTypes(state),
  categories: getAllCategories(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadPubDocsCategories: (custId) => {
    dispatch(loadPubDocsCategories(custId));
  },
  openTypeEditDialog: (typeId) => {
    dispatch(setPubDocTypeEditOpen(true, typeId));
  },
  openTypeDeleteDialog: (typeId) => {
    dispatch(setPubDocTypeDeleteOpen(true, typeId));
  },
  openCategoryEditDialog: (categoryId, typeId) => {
    dispatch(setPubDocCategoryEditOpen(true, categoryId, typeId));
  },
  openCategoryDeleteDialog: (categoryId) => {
    dispatch(setCategoryDeleteOpen(true, categoryId));
  },
  openCategoryDeleteErrorDialog: () => {
    dispatch(setCategoryDeleteErrorOpen(true));
  },
  openDocumentAddDialog: (typeId, categoryId) => {
    dispatch(setPubDocEditOpen(true, null, categoryId, typeId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PubDocsLandingPage);