import {createAction} from "redux-actions";
import {patchJson} from "../../common/utils";
import {custId} from "../../common/utils/server-data";
import {registerError} from "../../common/actions";


const initLaw = createAction('LAWS/LAW/INIT', lawData => ({lawData}));
const initLawLedger = createAction('LAWS/LAW_LEDGER/INIT', lawData => ({lawData}));
const startEditing = createAction('LAWS/EDITING/START', documentId => ({documentId}));
const cancelEditing = createAction('LAWS/EDITING/CANCEL');

const saveEditingStart = createAction('LAWS/EDITING/SAVE/START', (documentId, title, comment) => ({documentId, title, comment}));
const saveEditingSuccess = createAction('LAWS/EDITING/SAVE/SUCCESS', (updatedLaw) => ({updatedLaw}));
const saveEditingFail = createAction('LAWS/EDITING/SAVE/FAIL', (documentId, error) => ({documentId, error}));
const saveEditingFinally = createAction('LAWS/EDITING/SAVE/FINALLY', (documentId) => ({documentId}));

const saveEditing = (documentId, editedLaw) => async (dispatch) => {
  dispatch(saveEditingStart(documentId, editedLaw));
  try {
    const updateData = editedLaw;//{customTitle: editedLaw.title, comment: editecomment.replace(/[\r\n]+/g, " ")};
    const updatedLaw = await patchJson(`/api/${custId}/laws/${documentId}`, updateData);
    sessionStorage.setItem(`law-${documentId}`, JSON.stringify(updatedLaw));
    dispatch(saveEditingSuccess(updatedLaw));
  } catch (error) {
    dispatch(registerError("There was a problem saving this law", null, [documentId], error));
    dispatch(saveEditingFail(documentId, error));
  } finally {
    dispatch(saveEditingFinally(documentId));
  }
};

const setFilterText = createAction('LAWS/FILTER/TEXT',(filterText) => ({filterText}));
const setFilterYear = createAction('LAWS/FILTER/YEAR', (filterYear) => ({filterYear}));
const setFilterChapters = createAction('LAWS/FILTER/CHAPTER', (filterChapters) => ({filterChapters}));
const setShowDeletedLaws = createAction('LAWS/SHOW/DELETED',(showDeletedLaws) => ({showDeletedLaws}));
const setSort = createAction('LAWS/SORT', (sort) => ({sort}));

const initHistory = createAction('LAWS/HISTORY/INIT', (filterText, filterYear, filterChapters, sort) => ({filterText, filterYear, filterChapters, sort}));
const updateHistory = createAction('LAWS/HISTORY/UPDATE');

export {
  initLaw,
  initLawLedger,
  startEditing,
  cancelEditing,
  saveEditing,
  saveEditingStart,
  saveEditingSuccess,
  saveEditingFail,
  saveEditingFinally,
  setFilterText,
  setFilterYear,
  setFilterChapters,
  setShowDeletedLaws,
  setSort,
  initHistory,
  updateHistory
};