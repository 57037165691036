import * as PropTypes from "prop-types";
import React from "react";
import { Accordion } from "../../common/components";
import IndexPageWordReference from "./IndexPageWordReference";

const IndexPageWord = (props) => {

  const { word } = props;

  return (
    <Accordion
      key={word.word}
      summary={word.word}
      details={(<>{ word.references.map((reference) => (<IndexPageWordReference key={reference.label} reference={reference} />)) }</>)}
    />
  );
};
IndexPageWord.propTypes = {
  word: PropTypes.object.isRequired,
};
export default IndexPageWord;
