import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {custId} from "../../../common/utils/server-data";
import SignageClassSelection from "./SignageClassSelection";
import {capitalize} from "lodash";
import SignageDistrictSelection from "./SignageDistrictSelection";
import {PermanentSignageUseTypes, TemporarySignageUseTypes} from "./SignageTypes";
import {isNotBlank} from "../../../common/utils";
import SignageTypeForm from "./SignageTypeForm";
import SignageTypeSelection from "./SignageTypeSelection";
import SignageResults from "./SignageResults";
import PropTypes from "prop-types";

const SignageCalculator = (props) => {
  const signageClass = props.signageClass;
  const setSignageClass = props.setSignageClass;
  const searchParams = new URLSearchParams(document.location.search);
  const [signageDistrict, setSignageDistrict] = useState("");
  const [signType, setSignType] = useState("");
  const [formData, setFormData] = useState({});
  const [descriptor, setDescriptor] = useState(null);

  const [pinned, setPinned] = useState([]);

  const pinResult =  (result) => {
    let newPinned = pinned.concat();
    newPinned.unshift(result);
    setPinned(newPinned);
    setFormData({});
  };

  const unpinResult = (key) => {
    let newPinned = pinned.filter((item) => item.key !== key);
    setPinned(newPinned);
  };

  const handleSignageClassChange = (e) => {
    window.location.href = `/calculators/${custId}/signage?signageClass=${e.toLowerCase()}`;
  };

  const handleFormDataChange = (key, value) => {
    setFormData({...formData, [key]: value});
  };

  const hasMetReq = useCallback((inp) => {
    return formData[inp.req];
  },[formData]);

  const haveFormData = useCallback(() => {
    if (descriptor) {
      let signForm = (descriptor.form) ? descriptor.form : [];
      let valueCount = signForm.length;
      signForm.forEach((inp) => {
        if (isNotBlank(inp.req)) {
          if (!hasMetReq(inp) || (hasMetReq(inp) && isNotBlank(formData[inp.value_designator]))) {
            valueCount--;
          }
        } else {
          if (inp.type === 'switch' || isNotBlank(formData[inp.value_designator])) {
            valueCount--;
          }
        }
      });
      if (valueCount === 0) {
        return true;
      }
    }
    return false;
  }, [descriptor, formData, hasMetReq]);

  const useTypes = useMemo(() => (signageClass === 'Permanent')
    ? PermanentSignageUseTypes[signageDistrict] : TemporarySignageUseTypes[signageDistrict],
  [signageClass, signageDistrict]
  );

  useEffect( () => {
    setSignageClass(capitalize(searchParams.get('signageClass')));
  }, [searchParams, setSignageClass]);

  useEffect(() => {
    setSignType("");
    setFormData({});
  }, [signageDistrict]);

  useEffect(() => {
    if (isNotBlank(signageDistrict) && isNotBlank(signType)) {
      setDescriptor(useTypes ? useTypes[signType] : "");
    } else {
      setDescriptor(null);
    }
    setFormData({});
  }, [signageDistrict, signageClass, signType, useTypes]);

  return (
    <div className="calculator">
      {!signageClass &&
        <SignageClassSelection
          label="Choose Signage Type"
          currentValue={signageClass}
          onChange={handleSignageClassChange}
          listName="signageClass"
        />
      }
      {signageClass && <div className="calculatorForm">
        <h2>Required Information</h2>
        <SignageDistrictSelection
          label="Select a Zoning District Category"
          currentValue={signageDistrict}
          onChange={setSignageDistrict}
          listName="signageDistrict"
          signageDistricts={Object.keys(PermanentSignageUseTypes)}
        />
      </div>}
      {signageClass &&
        <SignageTypeSelection
          label="Select a Sign Type"
          currentValue={signType}
          onChange={setSignType}
          signageTypes={useTypes ? Object.keys(useTypes) : []}
        />
      }
      {descriptor && <SignageTypeForm
        formData={formData}
        signageTypeForm={descriptor.form}
        handleChange={handleFormDataChange}
      />}
      {((pinned.length > 0) || haveFormData()) &&
        <SignageResults signageClass={signageClass} signageDistrict={signageDistrict} signType={signType} formData={formData} formDataComplete={haveFormData()}  pinned={pinned} pin={pinResult} unpin={unpinResult} />
      }
      {signageClass && <div className="calculatorNotes">
        <h2>General Notes</h2>
        <ol>
          <li>
            For signs located in Lubbock’s extraterritorial jurisdiction, refer to <a href={'/45662274#45662274'}>Section 39.03.026</a>, Signs in Extraterritorial Jurisdiction of City.
          </li>
          <li>
            This calculator is for the purpose of estimating dimensions for new signs and does not take into account measurements of existing signs.
          </li>
        </ol>

      </div>}
    </div>
  );
};

export default SignageCalculator;
SignageCalculator.propTypes = {
  signageClass: PropTypes.string,
  setSignageClass: PropTypes.func
};