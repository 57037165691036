import * as PropTypes from "prop-types";
import React from "react";
import {Autocomplete, BoxedSection, Checkbox, FormControlLabel, MenuItem, Select,
  TextField
} from "../../common/components";
import {Button, ButtonGroup, PrimaryButton} from "../../common/components/button";
import {Table, TableBody, TableCell, TableRow} from "../../common/components/table";
import If from "../../common/containers/If";
import SelectReferenceDialogContainer from "../../common/containers/SelectReferenceDialogContainer";
import {codeReferenceType} from "../../common/ReferenceGroupFactory";
import "./GeneralCodeAdminSection.css";

class GeneralCodeAdminSection extends React.PureComponent {
  static propTypes = {
    featureViewJson: PropTypes.string,

    isOverridden: PropTypes.bool,
    maximumLevelFriendly: PropTypes.string,

    showCountyLineValue: PropTypes.string,
    bulkUploadValue: PropTypes.string,

    customerName: PropTypes.string,

    allCounties: PropTypes.string,
    muniCounties: PropTypes.string,
    counties: PropTypes.string,

    eReaderSubscribers: PropTypes.string,

    isIa2Admin: PropTypes.string,
    ia2Value: PropTypes.string,
    ia2ImportEnabled: PropTypes.string,

    codifiedLawViewEnabled: PropTypes.string,
    newNavEnabled: PropTypes.string,

    isCodeReviewAdmin: PropTypes.string,
    codeReviewPhase: PropTypes.string,

    allowRemoteAuth: PropTypes.string,

    isMixedContentAdmin: PropTypes.string,
    mixedContentEnabled: PropTypes.string,

    apiAdminEnable: PropTypes.string,
    contentApiAdminValue: PropTypes.string,
    searchApiAdminValue: PropTypes.string,
    codeApiAdminValue: PropTypes.string,

    isNavModeAdmin: PropTypes.string,

    xmlSourceDownloadValue: PropTypes.string,

    mapUrl: PropTypes.string,
    zoningStructure: PropTypes.object,
    zoningStructureLoading: PropTypes.bool,

    setMapUrl: PropTypes.func,
    setZoningChapter: PropTypes.func,

    sidebarLabel: PropTypes.string,
    pubDocsOnlyText: PropTypes.string
  };

  constructor(props) {
    super(props);
    const featureView = JSON.parse(props["featureViewJson"]);
    const allCounties = this.convertCountiesList(JSON.parse(props['allCounties']), true);
    const municipalCounties = this.convertCountiesList(JSON.parse(props['muniCounties']), true);
    const counties = this.convertCountiesList(JSON.parse(props['counties']), false);
    const zoningGuid = !props['zoningStructure'] ? null : props['zoningStructure'].get('guid');
    const zoningStructure = !zoningGuid ? null : {
      'guid': zoningGuid, 'text': props['zoningStructure'].get('text')
    };
    const featureLawLedgerView = JSON.parse(props["featureLawLedgerViewJson"]);
    this.state = {
      isGcAdmin: (props['isGcAdmin'] === 'true'),
      isCustomizationAdmin: (props['isCustomizationAdmin'] === 'true'),
      isFeatureAdmin: (props['isFeatureAdmin'] === 'true'),
      canGrantPermissions: (props['canGrantPermissions'] === 'true'),
      calculatorsEnabled: props.customization?.calculatorsEnabled,

      privateView: featureView["isOverridden"],
      privateViewDefault: featureView["maximumLevelFriendly"],

      showCountyLine: props.customization?.showCountyLine,
      bulkUpload: (props['bulkUploadValue'] === 'true'),
      trackbackEnabled: props.customization?.trackbackEnabled,

      customerName: props.customization.customerName,
      lastAppliedCustomerName: props['nameOverride'],

      allCounties: allCounties,
      counties: counties,
      lastAppliedCounties: counties,
      municipalCounties: municipalCounties,

      eReaderSubscribers: props['eReaderSubscribers'],
      lastAppliedSubscribers: props['eReaderSubscribers'],

      isIa2Admin: (props['isIa2Admin'] === 'true'),
      ia2Value: (props['ia2Value'] === 'true'),
      ia2ImportEnabled: props.customization?.ia2ImportEnabled,

      isCodeReviewAdmin: (props['isCodeReviewAdmin'] === 'true'),
      isMixedContentAdmin: (props['isMixedContentAdmin'] === 'true'),
      codeReviewPhase: (props.customization.codeReviewPhase),

      remoteAuthEnabled: (props['remoteAuthEnabled'] === 'true'),
      allowRemoteAuth: props.customization?.allowRemoteAuth,

      shallowSchemeEnabled: props.customization?.shallowSchemeEnabled,
      mixedContentEnabled: props.customization?.mixedContentEnabled,


      codifiedLawViewEnabled: props.customization?.codifiedLawViewEnabled,

      isNavModeAdmin: props['navModeAdmin'] === 'true',
      newNavEnabled: props.customization?.newNavEnabled,

      apiAdminEnable: props['apiAdminEnable'] === 'true',
      apiKeyView: props['apiKeyView'] === 'true',
      apiKeyCreate: props['apiKeyCreate'] === 'true',
      apiKeyModify: props['apiKeyModify'] === 'true',

      contentApiAdmin: props['contentApiAdminValue'] === 'true',
      searchApiAdmin: props['searchApiAdminValue'] === 'true',
      codeApiAdmin: props['codeApiAdminValue'] === 'true',

      xmlSourceDownload: props['xmlSourceDownloadValue'] === 'true',

      sidebarLabel: props.customization.sidebarLabel,
      lastAppliedSidebarLabel: props.customization.sidebarLabel,

      isEreaderAdmin: (props['isEreaderAdmin'] === 'true'),

      openZoningStructureDialog: false,
      selectedZoningStructure: zoningStructure,

      pubDocsOnlyText: props.customization.pubDocsOnlyText,
      lastAppliedPubDocsOnlyText: props.customization.pubDocsOnlyText,

      homeLongName: props.customization.homeLongName,
      homeShortName: props.customization.homeShortName,
      codeLongName: props.customization.codeLongName,
      codeShortName: props.customization.codeShortName,
      zoningLongName: props.customization.zoningLongName,
      zoningShortName: props.customization.zoningShortName,
      lawsLongName: props.customization.lawsLongName,
      lawsShortName: props.customization.lawsShortName,
      notesLongName: props.customization.notesLongName,
      notesShortName: props.customization.notesShortName,
      pubdocsLongName: props.customization.pubdocsLongName,
      pubdocsShortName: props.customization.pubdocsShortName,
      questionsLongName: props.customization.questionsLongName,
      questionsShortName: props.customization.questionsShortName,
      adminLongName: props.customization.adminLongName,
      adminShortName: props.customization.adminShortName,
      helpLongName: props.customization.helpLongName,
      helpShortName: props.customization.helpShortName,

      zoningMapName: props.customization.zoningMapName,
      zoningChapterName: props.customization.zoningChapterName,
      newLawsName: props.customization.newLawsName,
      lawLedgerName: props.customization.lawLedgerName,

      analysisName: props.customization.analysisName,

      trackbackLawLedgerLevel: featureLawLedgerView["currentLevel"]
    };
  }

  convertCountiesList = (oldList, isOptionList) => {
    let newList = [];
    for (const county of oldList) {
      if (isOptionList) {
        newList.push({label: (county.name + ", " + county.state), value: county.id, countyName: county.name});
      } else {
        newList.push(county.id);
      }
    }
    return newList;
  };

  handlePrivateModeClick = (event) => {
    const privateView = event.target.checked;
    this.props.setPrivateMode(privateView);
    this.setState({privateView});
  };
  handleIa2ModeClick = (event) => {
    const ia2Value = event.target.checked;
    this.props.setIa2Mode(ia2Value);
    this.setState({ia2Value});
  };
  handleIa2ImportClick = (event) => {
    const ia2ImportEnabled = event.target.checked;
    this.props.setIa2Import(ia2ImportEnabled);
    this.setState({ia2ImportEnabled});
  };
  handleShallowSchemeClick = (event) => {
    console.log(event.target.checked);
    const shallowSchemeEnabled = event.target.checked;
    this.props.setShallowSchemeEnabled(shallowSchemeEnabled);
    this.setState({shallowSchemeEnabled});
  };
  handleMixedContentClick = (event) => {
    console.log(event.target.checked);
    const mixedContentEnabled = !event.target.checked;
    this.props.setMixedContentEnabled(mixedContentEnabled);
    this.setState({mixedContentEnabled});
  };
  handleNewNavClick = (event) => {
    const newNavEnabled = event.target.checked;
    this.props.setNewNavEnabled(newNavEnabled);
    this.setState({newNavEnabled});
  };
  handleCodifiedLawsClick = (event) => {
    const codifiedLawViewEnabled = event.target.checked;
    this.props.setCodifiedLawsEnabled(codifiedLawViewEnabled);
    this.setState({codifiedLawViewEnabled});
  };
  handleAllowRemoteAuthClick = (event) => {
    const allowRemoteAuth = event.target.checked;
    this.props.setAllowRemoteAuth(allowRemoteAuth);
    this.setState({allowRemoteAuth});
  };
  handleCodeReviewPhaseChange = (event) => {
    const codeReviewPhase = event.target.value;
    this.props.setCodeReviewPhase(codeReviewPhase);
    this.setState({codeReviewPhase});
  };
  handleShowCountyLineClick = (event) => {
    const {subdivisionName} = this.props;
    const showCountyLine = event.target.checked;
    this.props.setShowCountyLine(showCountyLine, subdivisionName);
    this.setState({showCountyLine});
  };
  handleBulkUploadClick = (event) => {
    const bulkUpload = event.target.checked;
    this.props.setBulkUpload(bulkUpload);
    this.setState({bulkUpload});
  };
  handleCalculatorsEnableClick = (event) => {
    const calculatorsEnabled = event.target.checked;
    this.props.setCalculatorsEnabled(calculatorsEnabled);
    this.setState({calculatorsEnabled});
  };
  handleTrackbackEnableClick = (event) => {
    const trackbackEnabled = event.target.checked;
    this.props.setTrackbackEnabled(trackbackEnabled);
    this.setState({trackbackEnabled});
  };
  handleContentApiAdminClick = (event) => {
    const contentApiAdmin = event.target.checked;
    this.props.setContentApiAdmin(contentApiAdmin);
    this.setState({contentApiAdmin});
  };
  handleSearchApiAdminClick = (event) => {
    const searchApiAdmin = event.target.checked;
    this.props.setSearchApiAdmin(searchApiAdmin);
    this.setState({searchApiAdmin});
  };
  handleCodeApiAdminClick = (event) => {
    const codeApiAdmin = event.target.checked;
    this.props.setCodeApiAdmin(codeApiAdmin);
    this.setState({codeApiAdmin});
  };
  handleXmlSourceDownloadClick = (event) => {
    const xmlSourceDownload = event.target.checked;
    this.props.setXmlSourceDownload(xmlSourceDownload);
    this.setState({xmlSourceDownload});
  };
  handleCustomerNameChange = (event) => {
    this.setState({customerName: event.target.value});
  };
  handleApplyCustomerNameChangeClick = () => {
    this.props.applyCustomerName(this.state.customerName);
    this.setState({lastAppliedCustomerName: this.state.customerName});
  };
  handleSidebarLabelChange = (event) => {
    this.setState({sidebarLabel: event.target.value});
  }
  handleSidebarLabelChangeClick = () => {
    this.props.setSidebarLabel(this.state.sidebarLabel);
    this.setState({lastAppliedSidebarLabel: this.state.sidebarLabel});
  }
  handlePubDocsOnlyTextChange = (event) => {
    this.setState({pubDocsOnlyText: event.target.value});
  }
  handlePubDocsOnlyTextChangeClick = () => {
    this.props.setPubDocsOnlyText(this.state.pubDocsOnlyText);
    this.setState({lastAppliedPubDocsOnlyText: this.state.pubDocsOnlyText});
  }
  handleCountiesChange = (e, values) => {
    this.setState({
      counties: values.filter(value => value?.value).map(value => (value ? value.value : [])),
    });
  };
  handleApplyCountiesChangeClick = () => {
    this.props.applyCounties(this.state.counties);
    this.setState({lastAppliedCounties: this.state.counties});
  };
  handleEReaderChange = (event) => {
    this.setState({eReaderSubscribers: event.target.value});
  };
  handleEReaderReset = () => {
    this.setState({eReaderSubscribers: this.state.lastAppliedSubscribers});
  };
  handleApplyEReaderClick = () => {
    this.props.applyEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };
  handleApplySendAllEReaderClick = () => {
    this.props.applySendAllEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };
  handleApplySendNewEReaderClick = () => {
    this.props.applySendNewEReader(this.state.eReaderSubscribers, this.eReaderOverlay);
    this.setState({lastAppliedSubscribers: this.state.eReaderSubscribers});
  };

  setMapUrl = (e) => {
    const {setMapUrl} = this.props;
    setMapUrl(e.target.value);
  };

  setDisplayDate = (e) => {
    const {setDisplayDate} = this.props;
    setDisplayDate(e.target.value);
  };

  openReferenceSelect = () => {
    this.setState({openZoningStructureDialog: true});
  };

  handleReferenceSelectKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.openReferenceSelect();
    }
  };

  closeReferenceSelect = () => {
    this.setState({openZoningStructureDialog: false});
  };

  onReferenceSelectSubmit = (reference) => {
    this.setState({selectedZoningStructure: reference});
    this.closeReferenceSelect();
  };

  clearZoningChapter = () => {
    this.setState({selectedZoningStructure: null});
  };

  updateZoningChapter = () => {
    const {selectedZoningStructure} = this.state;
    const zoningStructureGuid = (selectedZoningStructure === null ? null : selectedZoningStructure.guid);

    // Make call to update the backend
    const {setZoningChapter} = this.props;
    setZoningChapter(zoningStructureGuid);
  };

  handleChangeNavigationName = (event, nameToUpdate) => {
    this.setState({[nameToUpdate]: event.target.value});
  }

  handleUpdateNavigationButton = () => {
    let navigationNames = {};
    navigationNames["homeLongName"] = this.state.homeLongName;
    navigationNames["homeShortName"] = this.state.homeShortName;
    navigationNames["codeLongName"] =  this.state.codeLongName;
    navigationNames["codeShortName"] = this.state.codeShortName;
    navigationNames["zoningLongName"] = this.state.zoningLongName;
    navigationNames["zoningShortName"] = this.state.zoningShortName;
    navigationNames["lawsLongName"] = this.state.lawsLongName;
    navigationNames["lawsShortName"] = this.state.lawsShortName;
    navigationNames["notesLongName"] = this.state.notesLongName;
    navigationNames["notesShortName"] = this.state.notesShortName;
    navigationNames["questionsLongName"] = this.state.questionsLongName;
    navigationNames["questionsShortName"] = this.state.questionsShortName;
    navigationNames["pubdocsLongName"] = this.state.pubdocsLongName;
    navigationNames["pubdocsShortName"] = this.state.pubdocsShortName;
    navigationNames["adminLongName"] = this.state.adminLongName;
    navigationNames["adminShortName"] = this.state.adminShortName;
    navigationNames["helpLongName"] = this.state.helpLongName;
    navigationNames["helpShortName"] = this.state.helpShortName;
    this.props.setNavigationNames(navigationNames);
  }

  handleUpdateSubPageNavigationButton = () => {
    let subNavigationNames = {};
    subNavigationNames["zoningMapName"] = this.state.zoningMapName;
    subNavigationNames["zoningChapterName"] = this.state.zoningChapterName;
    subNavigationNames["newLawsName"] = this.state.newLawsName;
    subNavigationNames["lawLedgerName"] = this.state.lawLedgerName;
    this.props.setSubNavigationNames(subNavigationNames);
  }

  handleAnalysisNameChange = (event) => {
    this.setState({analysisName : event.target.value});
  }

  handleAnalysisNameSubmit = () => {
    const {analysisName} = this.state;
    this.props.applyAnalysisName(analysisName);
  }

  handleTrackbackLawLedgerPermissionChange = (e) => {
    this.setState({trackbackLawLedgerLevel : e.target.value});
    this.props.setTrackbackLawLedgerLevel(e.target.value);
  }

  render() {
    const {
      customerLevel, displayDate, isDisplayDateAdmin, isNewNav, isPubDocsOnly, mapUrl, oldDisplayDate,
      oldMapUrl, subdivisionName, subdivisionNamePlural, zoningStructure, zoningStructureLoading
    } = this.props;
    const {
      adminLongName, adminShortName, allCounties, allowRemoteAuth, analysisName, apiAdminEnable, bulkUpload, calculatorsEnabled,
      canGrantPermissions, codeApiAdmin, codeLongName, codeReviewPhase, codeShortName, codifiedLawViewEnabled,
      contentApiAdmin, counties, customerName, eReaderSubscribers, helpLongName, helpShortName, homeLongName,
      homeShortName, ia2ImportEnabled, ia2Value, isCodeReviewAdmin, isCustomizationAdmin, isEreaderAdmin, isFeatureAdmin,
      isIa2Admin, isMixedContentAdmin, isNavModeAdmin, lastAppliedCounties, lastAppliedCustomerName, lastAppliedPubDocsOnlyText,
      lastAppliedSidebarLabel, lastAppliedSubscribers, lawsLongName, lawsShortName, trackbackEnabled,
      mixedContentEnabled, municipalCounties, newLawsName, newNavEnabled, notesLongName, notesShortName,
      openZoningStructureDialog, lawLedgerName, privateView, privateViewDefault, pubDocsOnlyText, pubdocsLongName,
      pubdocsShortName, questionsLongName, questionsShortName, remoteAuthEnabled, searchApiAdmin, selectedZoningStructure,
      shallowSchemeEnabled, showCountyLine, sidebarLabel, trackbackLawLedgerLevel, xmlSourceDownload, zoningChapterName, zoningLongName, zoningMapName, zoningShortName
    } = this.state;
    const previousZoningGuid = zoningStructure === null ? null : zoningStructure.guid;
    const eReaderChanged = eReaderSubscribers !== lastAppliedSubscribers;
    let countiesPreviouslyClear = true;
    for (const lastAppliedCounty of lastAppliedCounties) {
      for (const county of allCounties) {
        if (county.value === lastAppliedCounty) {
          countiesPreviouslyClear = false;
          break;
        }
      }
    }
    let muniCountyName = "No County";
    if (municipalCounties.length !== 0 && municipalCounties[0].countyName.length !== 0) {
      muniCountyName = "";
      for (const municipalCounty of municipalCounties) {
        muniCountyName = muniCountyName + " " + municipalCounty.label;
      }
    }
    return (
      <BoxedSection title="GC Admin" id={"gcAdminSection"}>
        <If test={isFeatureAdmin || isCustomizationAdmin || isDisplayDateAdmin}>
          <div className="gcAdminSettings-Code gcAdminSettings-section">
            <h3 id="codeSettingsTitle">Code Settings</h3>
            <If test={isMixedContentAdmin}>
              <ul className="gcAdminSettingsList">
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-shallowScheme"
                        checked={shallowSchemeEnabled}
                        onChange={this.handleShallowSchemeClick}
                      />
                    }
                    label="Only Show Shallow Schemes"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-hideMixedContent"
                        checked={!mixedContentEnabled || shallowSchemeEnabled}
                        disabled={shallowSchemeEnabled}
                        onChange={this.handleMixedContentClick}
                      />
                    }
                    label="Hide Mixed Types in Schemes"
                  />
                </li>
              </ul>
            </If>
            <If test={isFeatureAdmin || isCustomizationAdmin}>
              <ul className="gcAdminSettingsList">
                <If test={isFeatureAdmin}>
                  <li>
                    <FormControlLabel
                      className="gcAdminSettingsList-CheckboxLabel"
                      control={
                        <Checkbox
                          id="adminSettingsPage-privateMode"
                          checked={privateView}
                          onChange={this.handlePrivateModeClick}
                        />
                      }
                      label={
                        <span>Private Mode (The current eCode level is <strong>{customerLevel}</strong> with default view permission of <strong>{privateViewDefault}</strong>).</span>
                      }
                    />
                  </li>
                </If>
                <If test={isCustomizationAdmin}>
                  <li>
                    <FormControlLabel
                      className="gcAdminSettingsList-CheckboxLabel"
                      control={
                        <Checkbox
                          id="adminSettingsPage-showCountyLine"
                          checked={showCountyLine}
                          onChange={this.handleShowCountyLineClick}
                        />
                      }
                      label={"Show " + subdivisionName + " Line in Banner Area"}
                    />
                  </li>
                </If>
              </ul>
            </If>
            <If test={isCustomizationAdmin}>
              <div>
                <TextField
                  id="adminSettingsPage-customerName"
                  className="adminSettingsPage-customerName"
                  label="Code Name Override"
                  value={customerName}
                  onChange={this.handleCustomerNameChange}
                  helperText="** Publishing production will need to make the same code name change in the customer's XML in order for this change to persist after a supplement."
                  fullWidth={true}
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleApplyCustomerNameChangeClick}
                    disabled={customerName === lastAppliedCustomerName}
                    id="adminSettingsPage-customerNameApply"
                  >
                    Apply Code Name Override
                  </PrimaryButton>
                </ButtonGroup>
              </div>
            </If>
            <If test={isDisplayDateAdmin}>
              <div>
                <TextField
                  id="adminSettingsPage-displayDate"
                  className="adminSettingsPage-displayDate"
                  label="Code Display Date"
                  value={displayDate || ""}
                  onChange={this.setDisplayDate}
                  helperText="The text displayed at the top of the code, letting users know the last time the code was updated"
                  fullWidth
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.props.updateDisplayDate}
                    id="adminSettingsPage-displayDateApply"
                    disabled={displayDate === oldDisplayDate}
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
            </If>
            <If test={isCustomizationAdmin}>
              <div>
                <h5>{subdivisionName}: {muniCountyName}</h5>
                <Autocomplete
                  id="adminSettingsPage-counties"
                  className="adminSettingsPage-counties selectField"
                  label={`Additional ${subdivisionNamePlural}`}
                  options={allCounties}
                  value={counties}
                  onChange={this.handleCountiesChange}
                  multiple
                  disableClearable
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleApplyCountiesChangeClick}
                    disabled={counties === lastAppliedCounties || (counties.length === 0 && countiesPreviouslyClear)}
                    id="adminSettingsPage-countiesApply"
                  >
                    Apply {subdivisionNamePlural} Change
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <div id="adminSettingsPage-mapUrl-container">
                <TextField
                  id="adminSettingsPage-mapUrl"
                  className="adminSettingsPage-mapUrl"
                  label="Zoning Map URL"
                  value={mapUrl || ""}
                  onChange={this.setMapUrl}
                  helperText="URL where the Zoning Map can be accessed online"
                  fullWidth
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.props.updateMapUrl}
                    id="adminSettingsPage-mapUrlApply"
                    disabled={mapUrl === oldMapUrl}
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <div id="sidebarLabel">
                <TextField
                  id="adminSettingsPage-sidebarLabel"
                  className="adminSettingsPage-sidebarLabel"
                  label="Change Sidebar Label"
                  value={sidebarLabel}
                  onChange={this.handleSidebarLabelChange}
                  helperText="Change the sidebar label, default Code"
                  fullWidth
                />
                <ButtonGroup>
                  <PrimaryButton
                    onClick={this.handleSidebarLabelChangeClick}
                    disabled={sidebarLabel === lastAppliedSidebarLabel}
                    id="adminSettingsPage-sidebarLabelApply"
                  >
                    Update
                  </PrimaryButton>
                </ButtonGroup>
              </div>
              <If test={isPubDocsOnly}>
                <div id="pubDocsOnlyText">
                  <TextField
                    id="adminSettingsPage-pubDocsOnlyText"
                    className="adminSettingsPage-pubDocsOnlyText"
                    label="PubDocs Standalone Text"
                    value={pubDocsOnlyText}
                    onChange={this.handlePubDocsOnlyTextChange}
                    helperText="Change the text displayed for this PubDocs Standalone code"
                    fullWidth
                  />
                  <ButtonGroup>
                    <PrimaryButton
                      onClick={this.handlePubDocsOnlyTextChangeClick}
                      disabled={pubDocsOnlyText === lastAppliedPubDocsOnlyText}
                      id="adminSettingsPage-pubDocsOnlyTextApply"
                    >
                      Update
                    </PrimaryButton>
                  </ButtonGroup>
                </div>
              </If>
            </If>
          </div>
        </If>
        <If test={isFeatureAdmin && canGrantPermissions}>
          <div className="gcAdminSettings-Feature gcAdminSettings-section">
            <h3 id="featureSettingsTitle">Feature Settings</h3>
            <ul className="gcAdminSettingsList">
              <If test={isNavModeAdmin}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-newNav"
                        checked={newNavEnabled}
                        onChange={this.handleNewNavClick}
                      />
                    }
                    label="Enable New Nav"
                  />
                </li>
              </If>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-calculators"
                      checked={calculatorsEnabled}
                      onChange={this.handleCalculatorsEnableClick}
                    />
                  }
                  label="Enable Calculators"
                />
              </li>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-trackback"
                      checked={trackbackEnabled}
                      onChange={this.handleTrackbackEnableClick}
                    />
                  }
                  label="Enable TrackBack"
                />
              </li>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-codifiedLaws"
                      checked={codifiedLawViewEnabled}
                      onChange={this.handleCodifiedLawsClick}
                    />
                  }
                  label="Enable Law Ledger"
                />
              </li>
              <Select
                id='adminSettingsPage-trackbackLawLedger'
                label='Trackback and Law Ledger Min Permission Level'
                value={trackbackLawLedgerLevel}
                onChange={this.handleTrackbackLawLedgerPermissionChange}
                disabled={!trackbackEnabled && !codifiedLawViewEnabled}
              >
                <MenuItem value="NOBODY">None</MenuItem>
                <MenuItem value="USER">User</MenuItem>
                <MenuItem value="ADMIN">Admin</MenuItem>
                <MenuItem value="PUBLIC">All</MenuItem>
              </Select>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-bulkUpload"
                      checked={bulkUpload}
                      onChange={this.handleBulkUploadClick}
                    />
                  }
                  label="PubDocs Bulk Uploader"
                />
              </li>
              <If test={apiAdminEnable}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-contentApi"
                        checked={contentApiAdmin}
                        onChange={this.handleContentApiAdminClick}
                      />
                    }
                    label="Content API"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-searchApi"
                        checked={searchApiAdmin}
                        onChange={this.handleSearchApiAdminClick}
                      />
                    }
                    label="Search API"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-codeApi"
                        checked={codeApiAdmin}
                        onChange={this.handleCodeApiAdminClick}
                      />
                    }
                    label="Code Finder API"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-xmlSourceDownload"
                        checked={xmlSourceDownload}
                        onChange={this.handleXmlSourceDownloadClick}
                      />
                    }
                    label="Xcential LegisWeb Export"
                  />
                </li>
              </If>
            </ul>
          </div>
        </If>
        <If test={isIa2Admin || remoteAuthEnabled || isCodeReviewAdmin}>
          <div className="gcAdminSettings-CodeReview gcAdminSettings-section">
            <h3 id="codeReviewSettingsTitle">Code Review Settings</h3>
            <ul className="gcAdminSettingsList">
              <If test={isIa2Admin}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-ia2Mode"
                        checked={ia2Value}
                        onChange={this.handleIa2ModeClick}
                      />
                    }
                    label="IA2 Mode"
                  />
                </li>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-ia2ImportDisable"
                        checked={ia2ImportEnabled}
                        onChange={this.handleIa2ImportClick}
                      />
                    }
                    label="Enable IA2 Import"
                  />
                </li>
              </If>
              <If test={remoteAuthEnabled}>
                <li>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-allowRemoteAuth"
                        checked={allowRemoteAuth}
                        onChange={this.handleAllowRemoteAuthClick}
                      />
                    }
                    label="Manage Users On eCode360®"
                  />
                </li>
              </If>
            </ul>
            <If test={isCodeReviewAdmin}>
              <div>
                <Select
                  id="adminSettingsPage-codeReviewPhase"
                  label="Code Review Phase"
                  value={codeReviewPhase}
                  onChange={this.handleCodeReviewPhaseChange}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Manuscript">Manuscript</MenuItem>
                  <MenuItem value="Draft">Draft</MenuItem>
                  <MenuItem value="Second Draft">Second Draft</MenuItem>
                  <MenuItem value="Final Pending Adoption">Final Pending Adoption</MenuItem>
                </Select>
              </div>
            </If>
            <TextField
              id="adminSettingsPage-analysisName"
              label="Analysis Name"
              fullWidth={true}
              value={analysisName}
              onChange={this.handleAnalysisNameChange}
            />
            <ButtonGroup>
              <PrimaryButton id="adminSettingsPage-analysisNameUpdate" onClick={this.handleAnalysisNameSubmit} disabled={!analysisName}>
                Update
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </If>
        <If test={isEreaderAdmin}>
          <div className="gcAdminSettings-eReader gcAdminSettings-section">
            <h3 id="eReaderSettingsTitle">eReader Settings</h3>
            <div>
              <TextField
                id="adminSettingsPage-manageEreader"
                label="Manage eReader Subscriptions"
                multiline
                rowsMax="6"
                fullWidth={true}
                value={eReaderSubscribers}
                onChange={this.handleEReaderChange}
              />
              <ButtonGroup>
                <Button id="adminSettingsPage-manageEreaderReset" onClick={this.handleEReaderReset} disabled={!eReaderChanged}>Reset</Button>
                <Button id="adminSettingsPage-manageEreaderApply" onClick={this.handleApplyEReaderClick} disabled={!eReaderChanged}>Apply</Button>
                <Button id="adminSettingsPage-manageEreaderApplySendAll" onClick={this.handleApplySendAllEReaderClick} disabled={!eReaderChanged}>Apply & Send to all users</Button>
                <PrimaryButton id="adminSettingsPage-manageEreaderApplySendNew" onClick={this.handleApplySendNewEReaderClick} disabled={!eReaderChanged}>Apply & Send to new users</PrimaryButton>
              </ButtonGroup>
            </div>
            <div id="eReaderOverlay" style={{display: 'none'}} ref={(div) => { this.eReaderOverlay = div; }}>
              <div className="lds-dual-ring"/>
            </div>
          </div>
        </If>
        <div className="gcAdminSettings-section">
          <h3 id="taxonomyTitle">Taxonomy</h3>
          <SelectReferenceDialogContainer
            open={openZoningStructureDialog}
            groupName={codeReferenceType}
            onClose={this.closeReferenceSelect}
            onSubmit={this.onReferenceSelectSubmit}
            showNewWindowOption={false}
            title="Select the Zoning Chapter"
            fetchNodes={true}
          />
          <div id="adminSettingsPage-zoningChapter-container">
            <TextField
              id="adminSettingsPage-zoningChapter"
              className="adminSettingsPage-zoningChapter"
              label="Zoning Chapter"
              value={selectedZoningStructure === null || selectedZoningStructure.text === null ? '' : selectedZoningStructure.text}
              onClick={this.openReferenceSelect}
              onKeyDown={this.handleReferenceSelectKeyPress}
              InputProps={{readOnly: true}}
              fullWidth
            />
            <ButtonGroup>
              <PrimaryButton
                onClick={this.clearZoningChapter}
                id="adminSettingsPage-zoningChapterClear"
                disabled={zoningStructureLoading || selectedZoningStructure === null}
              >
                Clear
              </PrimaryButton>
              <PrimaryButton
                onClick={this.updateZoningChapter}
                id="adminSettingsPage-zoningChapterApply"
                disabled={zoningStructureLoading || ((selectedZoningStructure === null && previousZoningGuid === null)
                  || (selectedZoningStructure !== null && selectedZoningStructure.guid === previousZoningGuid))}
              >
                Update
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </div>
        <If test={isNewNav}>
          <div className="gcAdminSettings-section">
            <h3 id="pageNamesTitle">Navigation Page Type Names</h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell/>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Long Name</TableCell>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Short Name</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Home Page</TableCell>
                  {/*adding a padding-right: 0 to a table cell makes it extend all the way, but may cause other styling issues idk*/}
                  <TableCell>
                    <TextField
                      defaultValue={homeLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "homeLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={homeShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "homeShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Code Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={codeLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "codeLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={codeShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "codeShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Zoning Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={zoningLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "zoningLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={zoningShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "zoningShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Laws Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={lawsLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "lawsLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={lawsShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "lawsShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Notes Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={notesLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "notesLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={notesShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "notesShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Questions Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={questionsLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "questionsLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={questionsShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "questionsShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Public Documents Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={pubdocsLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "pubdocsLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={pubdocsShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "pubdocsShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Admin Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={adminLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "adminLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={adminShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "adminShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Help Page</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={helpLongName}
                      onChange={event => {this.handleChangeNavigationName(event, "helpLongName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={helpShortName}
                      onChange={event => {this.handleChangeNavigationName(event, "helpShortName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <ButtonGroup>
              <PrimaryButton
                onClick={this.handleUpdateNavigationButton}
                id="adminSettingsPage-updateNavNames"
              >
                Update
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </If>
        <If test={isNewNav}>
          <div className="gcAdminSettings-section">
            <h3 id="subPageNamesTitle">Navigation Sub Page Type Names</h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell/>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Name</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Zoning Map</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={zoningMapName}
                      onChange={event => {this.handleChangeNavigationName(event, "zoningMapName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Zoning Chapter</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={zoningChapterName}
                      onChange={event => {this.handleChangeNavigationName(event, "zoningChapterName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">New Laws</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={newLawsName}
                      onChange={event => {this.handleChangeNavigationName(event, "newLawsName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="adminSettingsPage-navigationCellHeader">Law Ledger</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={lawLedgerName}
                      onChange={event => {this.handleChangeNavigationName(event, "lawLedgerName");}}
                      InputProps={{ disableUnderline: true, className: "adminSettingsPage-navigationCell" }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <ButtonGroup>
              <PrimaryButton
                onClick={this.handleUpdateSubPageNavigationButton}
                id="adminSettingsPage-updateNavNames"
              >
                Update
              </PrimaryButton>
            </ButtonGroup>
          </div>
        </If>
      </BoxedSection>

    );
  }
}

export default GeneralCodeAdminSection;
