const ADMIN = "ADMIN";
const CALCULATOR = "CALCULATOR";
const CODE = "CODE";
const HELP = "HELP";
const HOME = "HOME";
const LAWS = "LAWS";
const NOTES = "NOTES";
const OTHER = "OTHER";
const PUBLIC_DOCUMENTS = "PUBLIC_DOCUMENTS";
const QUESTIONS = "QUESTIONS";
const SEARCH = "SEARCH";
const ZONING = "ZONING";

export {
  ADMIN,
  CALCULATOR,
  CODE,
  HELP,
  HOME,
  LAWS,
  NOTES,
  OTHER,
  PUBLIC_DOCUMENTS,
  QUESTIONS,
  SEARCH,
  ZONING,
};