import * as PropTypes from "prop-types";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";

import {Checkbox, Dialog, Divider, FormControlLabel, IconButton, MenuItem, Select, TextField,
  Typography} from "../../common/components";
import {DatePicker, MuiPickersUtilsProvider} from "../../common/components/DatePicker";
import {Clear as ClearIcon} from "../../common/components/icons";
import If from "../../common/containers/If";
import {archiveId} from "../../common/utils/server-data";

import "./AdvancedSearchDialog.css";

const getInitialState = (props) => ({
  anyWords: "",
  allWords: "",
  notWords: "",
  phraseWords: "",
  titleWords: "",
  slopWords: "",
  slopDistance: "",
  code: false,
  content: false,
  definition: false,
  attachment: false,
  termWords: "",
  doc: false,
  docsDateTo: null,
  docsDateFrom: null,
  categoryWords: "",
  law: false,
  subjectWords: "",
  commentWords: "",
  affectsCode: null,
  lawsDateFrom: null,
  lawsDateTo: null,
  note: false,
  publicVisibility: false,
  privateVisibility: false,
  checkedTypes: {},
  groupVisibility: props.groups ? props.groups.map(group => ({id: group.key, name: group.name, checked: false})) : [],
});

class AdvancedSearchDialog extends React.PureComponent {

  parseQuery = (query, props) => {

    let state = getInitialState(props);

    // Parse the query string into tokens.  Tokens include words, field terms, phrase terms (quotes), and nested queries (parentheses).
    const tokenFinder = /[+-]?[A-Z_]*:\[[^\]]+]|[+-]?[A-Z]*:?"[^"]+"(~\d+)?|\S+/g;
    const tokens = [];
    let nestedPos = query.indexOf("(");
    while (nestedPos >= 0) {
      // If there are tokens before the next nested query, parse and add those first
      if (nestedPos > 0) {
        const precedingTokens = query.substring(0, nestedPos).match(tokenFinder);
        if (precedingTokens && precedingTokens.length > 0) {
          tokens.push(...precedingTokens);
        }
      }
      // Remove the preceding tokens from the query string
      query = query.substring(nestedPos);
      // Find the full string of this nested query, taking into account there may be further nested queries
      nestedPos = 1;
      let level = 1;
      while (true) {
        if (query.charAt(nestedPos) === "(") {
          level++;  // If we find an open parentheses go one level deeper
        } else if (query.charAt(nestedPos) === ")") {
          level--;  // If we find an open parentheses go one level up
        }
        // Once we're back to level 0 we have the full nested query, make it it's own token.
        if (level === 0) {
          tokens.push(query.substring(0, nestedPos + 1));
          query = query.substring(nestedPos + 1);
          break;
        }
        nestedPos++;
      }
      nestedPos = query.indexOf("(");
    }
    // Convert the rest of the query to tokens
    const remainingTokens = query.match(tokenFinder);
    if (remainingTokens && remainingTokens.length > 0) {
      tokens.push(...remainingTokens);
    }

    console.log('tokenized', tokens);

    // Clean up any and/or/not keywords by normalizing on +/-
    for (let i = tokens.length - 1; i >= 0; i--) {
      const token = tokens[i].toLowerCase();
      if (token === "not") {
        if (tokens[i + 1].startsWith("+")) {
          tokens[i + 1] = '-' + tokens[i + 1].substring(1);
        } else if (!tokens[i + 1].startsWith("-")) {
          tokens[i + 1] = '-' + tokens[i + 1];
        }
        tokens.splice(i, 1);
      } else if (token === "or") {
        tokens.splice(i, 1);
      } else if (token === "and") {
        if (!tokens[i + 1].startsWith("+") && !tokens[i + 1].startsWith("-")) {
          tokens[i + 1] = '+' + tokens[i + 1];
        }
        if (!tokens[i - 1].startsWith("+") && !tokens[i - 1].startsWith("-")) {
          tokens[i - 1] = '+' + tokens[i - 1];
        }
        tokens.splice(i, 1);
      }
    }

    console.log('normalized', tokens);

    tokens.forEach(token => {
      const mustInclude = token.startsWith("+");
      const doesNotInclude = token.startsWith("-");
      if (mustInclude || doesNotInclude) token = token.substring(1);
      // Handle nested queries here
      if (token.startsWith("(")) {
        if (doesNotInclude) throw new Error(`Cannot parse a NOT nested query: -${token}`);
        if (!token.endsWith(")")) throw new Error(`Malformed nested query: ${token}`);
        const parsedSubQuery = this.parseQuery(token.substring(1, token.length - 1), props);
        if (parsedSubQuery.anyWords.length > 0 && this.otherFieldsEmpty(["anyWords"], parsedSubQuery, props)) {
          state.anyWords = `${state.anyWords} ${parsedSubQuery.anyWords}`.trim();
        } else if (parsedSubQuery.titleWords.length > 0 && this.otherFieldsEmpty(["titleWords"], parsedSubQuery, props)) {
          state.titleWords = `${state.titleWords} ${parsedSubQuery.titleWords}`.trim();
        } else if (this.isTypeSubQuery(parsedSubQuery, props)) {
          state = this.applyTypeState(state, parsedSubQuery, props);
        } else {
          console.log(token, parsedSubQuery);
          throw new Error("Nested query does not parse into known fields");
        }
      }
      // Handle phrase terms and slop terms here
      else if (token.startsWith("\"")) {
        if (doesNotInclude) {
          state.noneWords = `${state.noneWords} ${token}`.trim();
        } else if (!mustInclude) {
          state.anyWords = `${state.anyWords} ${token}`.trim();
        } else {
          const tilda = token.indexOf("~");
          if (tilda > 0 && state.slopWords === "") {
            state.slopWords = token.substring(1, tilda - 1);
            state.slopDistance = token.substring(tilda + 1);
          } else if (token.endsWith("\"") && state.phraseWords === "") {
            state.phraseWords = token.substring(1, token.length - 1);
          } else {
            state.allWords = `${state.allWords} ${token}`.trim();
          }
        }
      }
      // Handle TYPE field terms here
      else if (token.startsWith("TYPE:")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        state = {...state, ...this.getTypeState(token.substring(5), state)};
      }
      // Handle TITLE field terms here
      else if (token.startsWith("TITLE:")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        state.titleWords = `${state.titleWords} ${token.substring(6)}`.trim();
      }
      // Handle TERM field terms here
      else if (token.startsWith("TERM:")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        state.termWords = `${state.termWords} ${token.substring(5)}`.trim();
      }
      // Handle CATEGORY field terms here
      else if (token.startsWith("CATEGORY:")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        state.categoryWords = `${state.categoryWords} ${token.substring(9)}`.trim();
      }
      // Handle DATE ranged field terms here
      else if (token.startsWith("DATE:[")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        if (!token.endsWith("]")) throw new Error("Unable to parse date term: " + token);
        token = token.substring(6, token.length - 1);
        const toPos = token.indexOf(" TO ");
        if (toPos < 0) throw new Error("Unable to parse date term: " + token);
        state.docsDateFrom = this.parseDate(token.substring(0, 10));
        state.docsDateTo = this.parseDate(token.substring(14));
      }
      // Handle SUBJECT field terms here
      else if (token.startsWith("SUBJECT:")) {
        state.subjectWords = `${state.subjectWords} ${token.substring(8)}`.trim();
      }
      // Handle COMMENT field terms here
      else if (token.startsWith("COMMENT:")) {
        state.commentWords = `${state.commentWords} ${token.substring(8)}`.trim();
      }
      // Handle AFFECTS_CODE field terms here
      else if (token.startsWith("AFFECTS_CODE:")) {
        const affectsCode = token.substring(13).trim();
        state.affectsCode = (affectsCode === "true" ? true : (affectsCode === "false" ? false : null));
      }
      // Handle ADOPTED_DATE ranged field terms here
      else if (token.startsWith("ADOPTED_DATE:[")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        if (!token.endsWith("]")) throw new Error("Unable to parse date term: " + token);
        token = token.substring(14, token.length - 1);
        const toPos = token.indexOf(" TO ");
        if (toPos < 0) throw new Error("Unable to parse date term: " + token);
        state.lawsDateFrom = this.parseDate(token.substring(0, 10));
        state.lawsDateTo = this.parseDate(token.substring(14));
      }
      // Handle VISIBILITY field terms here
      else if (token.startsWith("VISIBILITY:")) {
        if (doesNotInclude) throw new Error("Cannot parse NOT field query terms: " + token);
        const term = token.substring(11).toUpperCase();
        if (term === "PRIVATE") state.privateVisibility = true;
        else if (term === "PUBLIC") state.publicVisibility = true;
        else {
          state.groupVisibility.forEach(group => {
            if (group.id === term) group.checked = true;
          });
        }
      }
      // Handle generic "must include" terms here
      else if (mustInclude) {
        state.allWords = `${state.allWords} ${token}`.trim();
      }
      // Handle generic "does not include" terms here
      else if (doesNotInclude) {
        state.notWords = `${state.notWords} ${token}`.trim();
      }
      // Handle generic "should include" terms here
      else {
        state.anyWords = `${state.anyWords} ${token}`.trim();
      }
    });

    return state;
  };

  // When we see a TYPE: field term in the query, do some mapping to populate the correct data in the state
  getTypeState = (typeWord, currentState) => {
    if (typeWord === "\"misc. documents\"") {
      typeWord = "miscDocs";
    } else if (typeWord === "\"comprehensive plans\"") {
      typeWord = "plans";
    } else if (typeWord === "pdf") {
      typeWord = "attachment";
    } else if(typeWord.includes("\"")) {
      typeWord = typeWord.replaceAll("\"", "");
    }
    let typeState;
    if (currentState.hasOwnProperty(typeWord.toLowerCase())) {
      typeState = {[typeWord.toLowerCase()]: true};
    }
    else {
      let newCheckedTypes = currentState.checkedTypes;
      newCheckedTypes[typeWord.toLowerCase()] = true;
      typeState = {checkedTypes: newCheckedTypes};
    }
    if (typeWord === "code") {
      typeState = {...typeState, content: true, definition: true, attachment: true};
    } else if (typeWord === "content") {
      typeState = {...typeState, content: true, definition: false, attachment: false};
    } else if (typeWord === "doc") {
      this.handleSetAllDocs(true);
    }
    return typeState;
  };

  // Create some static lists of fields which we will allow in our nested type queries
  typeStringFields = ["termWords", "categoryWords", "subjectWords", "commentWords"];
  typeBooleanFields = ["affectsCode"];
  typeDateFields = ["lawsDateFrom", "lawsDateTo", "docsDateFrom", "docsDateTo"];
  visibilityFields = ["privateVisibility", "publicVisibility", "groupVisibility"];
  getDocTypeFields = (props) => props.pubDocTypes.map(type => type.title.toLowerCase());


  getTypeFields = (props) => {
    return ["code", "content", "attachment", "definition", "doc", "agendas", "budgets", "plans", "legislation",
      "minutes", "miscDocs", "resolutions", "law", "note", ...this.getDocTypeFields(props), ...this.typeStringFields, ...this.typeBooleanFields, ...this.typeDateFields,
      ...this.visibilityFields];
  };

  // Check the data from a parsed query to make sure it is something we can handle in our form
  isTypeSubQuery = (parsedSubQuery, props) => {
    return this.getTypeFields(props).reduce((acc, field) => acc || this.hasTypeFieldValue(field, parsedSubQuery), false);
  };
  hasTypeFieldValue = (field, parsedSubQuery) => {
    if (field === "groupVisibility") {
      return parsedSubQuery.groupVisibility.reduce((acc, group) => acc || group.checked, false);
    }
    if (field === "affectsCode") {
      return parsedSubQuery.affectsCode;
    }
    if(parsedSubQuery["checkedTypes"][field.toLowerCase()]) {
      return parsedSubQuery["checkedTypes"][field.toLowerCase()];
    }
    return parsedSubQuery[field];
  };
  // Merge the data from a parsed query into the parent state data
  applyTypeState = (currentState, typeState, props) => {
    const appliedState = {...currentState};
    this.getTypeFields(props).forEach(field => {
      if (appliedState.hasOwnProperty(field)) {
        appliedState[field] = this.calculateTypeValue(field, appliedState, typeState);
      }
      if (this.getDocTypeFields(props).includes(field.toLowerCase()) && typeState["checkedTypes"][field.toLowerCase()]) {
        appliedState["checkedTypes"][field.toLowerCase()] = typeState["checkedTypes"][field.toLowerCase()];
      }
    }
    );
    return appliedState;
  };
  calculateTypeValue = (field, appliedState, typeState) => {
    if (field === "groupVisibility") {
      return appliedState.groupVisibility.map((group, i) => ({...group, checked: group.checked || typeState.groupVisibility[i].checked}));
    } else if (this.typeStringFields.indexOf(field) >= 0) {
      return `${appliedState[field]} ${typeState[field]}`.trim();
    } else if (this.typeDateFields.indexOf(field) >= 0) {
      return typeState[field] ? typeState[field] : appliedState[field];
    }
    return typeState[field] || appliedState[field];
  };

  // Check if all the that are not given have their default values
  otherFieldsEmpty = (fields, state, props) => {
    const blankState = getInitialState(props);
    const stateToCheck = {...state};
    fields.forEach(field => {
      delete blankState[field];
      delete stateToCheck[field];
      delete blankState["checkedTypes"][field.toLowerCase()];
      delete stateToCheck["checkedTypes"][field.toLowerCase()];
    });
    return JSON.stringify(blankState) === JSON.stringify(stateToCheck);
  };

  // Parse a date string that is in the YYYY-MM-DD format
  parseDate = (dateString) => {
    const parts = dateString.split('-');
    return new Date(parts[0], parts[1]-1, parts[2]);
  };

  areAllCodeChildrenChecked = (state) => {
    return state.content && state.definition && state.attachment;
  };

  areAnyCodeChildrenChecked = (state) => {
    return state.content || state.definition || state.attachment;
  };

  areAllDocsChildrenChecked = (state, props) => {
    const pubDocTypes = props.pubDocTypes;
    const checkedTypes = state.checkedTypes;
    let allChecked = true;

    pubDocTypes.map(currType => checkedTypes.hasOwnProperty(currType.title.toLowerCase()) ? (checkedTypes[currType.title.toLowerCase()] ? true : allChecked = false ) : allChecked = false);

    return (allChecked);
  };

  areAnyDocsChildrenChecked = (state, props) => {
    const pubDocTypes = props.pubDocTypes;
    const checkedTypes = state.checkedTypes;
    let anyChecked = false;

    pubDocTypes.map(currType => checkedTypes.hasOwnProperty(currType.title.toLowerCase()) ? (checkedTypes[currType.title.toLowerCase()] ? anyChecked = true : false ) : false);

    return anyChecked;
  };

  areAllNotesChildrenChecked = (state) => {
    return state.publicVisibility && state.privateVisibility && state.groupVisibility.every(group => group.checked);
  };

  areAnyNotesChildrenChecked = (state) => {
    return state.publicVisibility || state.privateVisibility || state.groupVisibility.some(group => group.checked);
  };

  static propTypes = {
    isAdvancedSearchOpen: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
    setAdvancedSearchOpen: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(props),
      queryString: this.props.query
    };
  }

  onDialogOpen = () => {
    try {
      let state = this.parseQuery(this.props.query, this.props);

      // Do some post parsing checks on the integrity of the checkbox selection state
      if (!state.definition && state.termWords.length > 0) {
        state.definition = true;
      }
      if (!state.code && this.areAllCodeChildrenChecked(state)) {
        state.code = true;
      }
      if (!state.law && (state.subjectWords.length > 0 || state.commentWords.length > 0 || state.affectsCode !== null
        || state.lawsDateFrom !== null || state.lawsDateTo !== null)
      ) {
        state.law = true;
      }
      if (!state.doc && this.areAllDocsChildrenChecked(state, this.props)) {
        state.doc = true;
      }
      if (state.note && !this.areAnyNotesChildrenChecked(state)) {
        state = {...state, publicVisibility: true, privateVisibility: true, groupVisibility: state.groupVisibility.map(group => ({...group, checked: true}))};
      } else if (state.note && this.areAnyNotesChildrenChecked(state)) {
        state.note = false;
      } else if (!state.note && this.areAllNotesChildrenChecked(state)) {
        state.note = true;
      }

      this.setState(state, this.updateQueryString);
    } catch (e) {
      console.log("Parsing Error", e);
      this.setState({...getInitialState(this.props), anyWords: this.props.query}, this.updateQueryString);
    }
  };

  onDialogClose = () => {
    this.props.setAdvancedSearchOpen(false);
  };

  onDialogSave = () => {
    this.props.setQuery(this.state.queryString);
    this.props.submitSearch();
    this.onDialogClose();
  };

  handleDialogSubmit = (event) => {
    event.preventDefault();
    this.onDialogSave();
  };

  formatDate = (date) => {
    return date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length < 2 ? "0" : "") + (date.getMonth() + 1).toString()
      + "-" + (date.getDate().toString().length < 2 ? "0" : "") + date.getDate().toString();
  };

  handleFieldChange = (event) => {
    if (event.target) {
      if (event.target.type === "checkbox") {
        this.setState({[event.target.name]: event.target.checked}, this.updateQueryString);
      } else {
        if (typeof event.target.min === "undefined" || event.target.value === "" || event.target.min <= event.target.value) {
          this.setState({[event.target.name]: event.target.value}, this.updateQueryString);
        }
      }
    }
  };

  handleFromDateFieldChange = (field) => (newDate) => {
    if (field) this.setState({[field]: newDate}, this.updateQueryString);
  };

  handleCodeCheckboxChange = (event) => {
    if (event.target) this.setState({
      code: event.target.checked,
      content: event.target.checked,
      definition: event.target.checked,
      attachment: event.target.checked
    }, this.updateQueryString);
  };

  handleCodeChildCheckboxChange = (event) => {
    if (event.target) {
      this.setState({
        [event.target.name]: event.target.checked
      }, () => {
        this.setState({code: this.areAllCodeChildrenChecked(this.state)}, this.updateQueryString);
      });
    }
  };

  handleDocsCheckboxChange = (event) => {
    if (event.target) {
      this.setState({doc: event.target.checked});
      this.handleSetAllDocs(event.target.checked);
    }
  };

  handleSetAllDocs = (checkedState) => {
  //  Loop through and set all to checkedState
    const {pubDocTypes} = this.props;
    const {checkedTypes} = this.state;

    let newCheckedTypes = checkedTypes;
    pubDocTypes.map(currType => newCheckedTypes[currType.title.toLowerCase()] = checkedState);
    this.setState({checkedTypes: newCheckedTypes}, this.updateQueryString);
  }

  handleDocsChildCheckboxChange = (event) => {
    if (event.target) {
      let newCheckedTypes = this.state.checkedTypes;
      newCheckedTypes[event.target.name.toLowerCase()] = event.target.checked;
      this.setState({
        checkedTypes: newCheckedTypes
      }, () => {
        this.setState({doc: this.areAllDocsChildrenChecked(this.state, this.props)}, this.updateQueryString);
      });
    }
  };

  handleNotesCheckboxChange = (event) => {
    if (event.target) {
      let groupVisibility = [];
      this.state.groupVisibility.forEach(group => groupVisibility.push({id: group.id, name: group.name, checked: event.target.checked}));
      this.setState({
        note: event.target.checked,
        publicVisibility: event.target.checked,
        privateVisibility: event.target.checked,
        groupVisibility: groupVisibility
      }, this.updateQueryString);
    }
  };

  handleNotesChildCheckboxChange = (event) => {
    if (event.target) {
      this.setState({
        [event.target.name]: event.target.checked
      }, () => {this.setState({note: this.areAllNotesChildrenChecked(this.state)}, this.updateQueryString);});
    }
  };

  handleGroupVisibilityCheckboxChange = (event) => {
    if (event.target) {
      let groupVisibility = [];
      this.state.groupVisibility.forEach(group => {
        if (group.id === event.target.name) {
          groupVisibility.push({id: group.id, name: group.name, checked: event.target.checked});
        } else {
          groupVisibility.push({id: group.id, name: group.name, checked: group.checked});
        }
      });
      this.setState({groupVisibility: groupVisibility},
        () => {this.setState({note: this.areAllNotesChildrenChecked(this.state)}, this.updateQueryString);}
      );
    }
  };

  setStateValue = (state) => () => {
    if (state) this.setState(state, this.updateQueryString);
  };

  updateQueryString = () => {
    const {anyWords, allWords, notWords, phraseWords, titleWords, slopWords, slopDistance, code, content, definition,
      attachment, termWords, doc,
      categoryWords, docsDateFrom, docsDateTo, law, subjectWords, commentWords, affectsCode, lawsDateFrom, lawsDateTo,
      note, publicVisibility, privateVisibility, groupVisibility} = this.state;
    let queryStringBuilder = [];

    if (anyWords && anyWords !== "\"") {
      const any = anyWords.match(/\S+/g).join(" ");
      queryStringBuilder.push((any.startsWith("(") && any.endsWith(")")) ? any : `(${any})`);
    }

    if (allWords && allWords !== "\"") {
      queryStringBuilder.push(allWords.match(/\w+|"(?:\\"|[^"])+"/g).join(" AND "));
    }

    if (notWords && notWords !== "\"") {
      queryStringBuilder.push(notWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("-" + word)).join(" AND "));
    }

    if (phraseWords) {
      queryStringBuilder.push("\"" + phraseWords.replace(/"/g, "") + "\"");
    }

    if (titleWords && titleWords !== "\"") {
      queryStringBuilder.push("(" + titleWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("TITLE:" + word)).join(" ") + ")");
    }

    if (slopWords) {
      queryStringBuilder.push("\"" + slopWords.replace(/"/g, "") + "\"~" + (slopDistance ? slopDistance : "0"));
    }

    let typeStringBuilder = [];
    if (code || this.areAnyCodeChildrenChecked(this.state)) {
      let codeQueryString;
      if (code) {
        codeQueryString = "TYPE:code";
      } else if (this.areAnyCodeChildrenChecked(this.state)) {
        let codeTypeStringBuilder = [];
        if (content) {
          codeTypeStringBuilder.push("TYPE:content");
        }
        if (definition) {
          codeTypeStringBuilder.push("TYPE:definition");
        }
        if (attachment) {
          codeTypeStringBuilder.push("TYPE:pdf");
        }
        if (codeTypeStringBuilder.length > 1) {
          codeQueryString = "(" + codeTypeStringBuilder.join(" ") + ")";
        } else {
          codeQueryString = codeTypeStringBuilder.join(" ");
        }
      }
      if (definition && termWords && termWords !== "\"") {
        typeStringBuilder.push("(" + codeQueryString + " AND (" + termWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("TERM:" + word)).join(" ") + "))");
      } else {
        typeStringBuilder.push(codeQueryString);
      }
    }

    if (doc || this.areAnyDocsChildrenChecked(this.state, this.props)) {
      let docQueryString;
      if (doc) {
        docQueryString = "TYPE:doc";
      } else if (this.areAnyDocsChildrenChecked(this.state, this.props)) {
        let docTypeStringBuilder = [];
        //Iterate over pubdoctypes and if it is present in checkedTypes then store its name in the string builder
        const pubDocTypes = this.props.pubDocTypes;
        const checkedTypes = this.state.checkedTypes;

        pubDocTypes.map(currType =>
          checkedTypes.hasOwnProperty(currType.title.toLowerCase()) ? (checkedTypes[currType.title.toLowerCase()] ? docTypeStringBuilder.push("TYPE:\"" + currType.title + "\"") : false) : false
        );

        if (docTypeStringBuilder.length > 1) {
          docQueryString = "(" + docTypeStringBuilder.join(" ") + ")";
        } else {
          docQueryString = docTypeStringBuilder.join(" ");
        }
      }
      if ((categoryWords && categoryWords !== "\"") || docsDateFrom || docsDateTo) {
        if (categoryWords && categoryWords !== "\"") {
          docQueryString += " AND (" + categoryWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("CATEGORY:" + word)).join(" ") + ")";
        }
        if (docsDateFrom || docsDateTo) {
          docQueryString += " AND DATE:[" + (docsDateFrom ? this.formatDate(docsDateFrom) : "1900-01-01") + " TO "
            + (docsDateTo ? this.formatDate(docsDateTo) : this.formatDate(new Date())) + "]";
        }
        docQueryString = "(" + docQueryString + ")";
      }
      typeStringBuilder.push(docQueryString);
    }

    if (law) {
      let lawQueryString = "TYPE:law";
      if ((subjectWords && subjectWords !== "\"") || (commentWords && commentWords !== "\"") || affectsCode !== null
        || lawsDateFrom || lawsDateTo
      ) {
        if (subjectWords && subjectWords !== "\"") {
          lawQueryString += " AND (" + subjectWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("SUBJECT:" + word)).join(" ") + ")";
        }
        if (commentWords && commentWords !== "\"") {
          lawQueryString += " AND (" + commentWords.match(/\w+|"(?:\\"|[^"])+"/g).map(word => ("COMMENT:" + word)).join(" ") + ")";
        }
        if (affectsCode === true || affectsCode === "true" || affectsCode === false || affectsCode === "false") {
          lawQueryString += " AND (AFFECTS_CODE:" + affectsCode + ")";
        }
        if (lawsDateFrom || lawsDateTo) {
          lawQueryString += " AND ADOPTED_DATE:[" + (lawsDateFrom ? this.formatDate(lawsDateFrom) : "1900-01-01")
            + " TO " + (lawsDateTo ? this.formatDate(lawsDateTo) : this.formatDate(new Date())) + "]";
        }
        lawQueryString = "(" + lawQueryString + ")";
      }
      typeStringBuilder.push(lawQueryString);
    }

    if (note) {
      typeStringBuilder.push("TYPE:note");
    } else if (this.areAnyNotesChildrenChecked(this.state)) {
      let noteVisibilityStringBuilder = [];
      if (publicVisibility) {
        noteVisibilityStringBuilder.push("VISIBILITY:public");
      }
      if (privateVisibility) {
        noteVisibilityStringBuilder.push("VISIBILITY:private");
      }
      groupVisibility.filter(group => group.checked).map(group => ("VISIBILITY:" + group.id))
        .forEach(groupId => noteVisibilityStringBuilder.push(groupId));
      if (noteVisibilityStringBuilder.length > 1) {
        typeStringBuilder.push("(TYPE:note AND (" + noteVisibilityStringBuilder.join(" ") + "))");
      } else {
        typeStringBuilder.push("(TYPE:note AND " + noteVisibilityStringBuilder.join(" ") + ")");
      }
    }

    if (typeStringBuilder.length > 1) {
      queryStringBuilder.push("(" + typeStringBuilder.join(" ") + ")");
    } else if (typeStringBuilder.length > 0) {
      queryStringBuilder.push(typeStringBuilder.join(" "));
    }

    this.setState({queryString: queryStringBuilder.join(" AND ")});
  };

  render() {
    const {isAdvancedSearchOpen, pubDocTypes} = this.props;
    const {checkedTypes} = this.state;
    return (
      <Dialog
        id="advancedSearchDialog"
        title={"Advanced Search"}
        contextId="advancedSearchModal"
        open={isAdvancedSearchOpen}
        onCancel={this.onDialogClose}
        onSubmit={this.handleDialogSubmit}
        submitLabel="Search"
        submitDisabled={this.otherFieldsEmpty(["queryString", "slopDistance"], this.state, this.props)}
        PaperProps={{id: "advancedSearchPaper"}}
        onEnter={this.onDialogOpen}
        width="md"
        DialogTitleProps={{
          contextId: "advancedSearchModal",
          additionalContent: (this.state.queryString ? (<div id="advancedSearchQueryString">Search Query: <span className="example">{this.state.queryString}</span></div>) : null)
        }}
        DialogContentProps={{id: "advancedSearchContent"}}
      >
        <div className="rowContainer">
          <div className="headingRow row">
            <div className="column1">
              <Typography variant="subtitle1">Find results with...</Typography>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div className="fieldContainer">
                <TextField
                  id="advancedSearchAnyWords"
                  name="anyWords"
                  label="Any of these words:"
                  value={this.state.anyWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.anyWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({anyWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div className="fieldContainer">
                <TextField
                  id="advancedSearchAllWords"
                  name="allWords"
                  label="All of these words:"
                  value={this.state.allWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.allWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({allWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div className="fieldContainer">
                <TextField
                  id="advancedSearchNoWords"
                  name="notWords"
                  label="None of these words:"
                  value={this.state.notWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.notWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({notWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div className="fieldContainer">
                <TextField
                  id="advancedSearchExactWords"
                  name="phraseWords"
                  label="This phrase:"
                  value={this.state.phraseWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.phraseWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({phraseWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div className="fieldContainer">
                <TextField
                  id="advancedSearchTitleWords"
                  name="titleWords"
                  label="Any of these words in the title:"
                  value={this.state.titleWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.titleWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({titleWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <div className="textField row">
            <div className="column1">
              <div id="advancedSearchSlop" className="fieldContainer">
                <TextField
                  id="advancedSearchSlopWords"
                  name="slopWords"
                  label="Any of these words:"
                  value={this.state.slopWords}
                  onChange={this.handleFieldChange}
                  className="words field"
                />
                <span className="label"> within </span>
                <TextField
                  id="advancedSearchSlopDistance"
                  name="slopDistance"
                  type="number"
                  inputProps={{min: "0"}}
                  value={this.state.slopDistance}
                  onChange={this.handleFieldChange}
                  className="distance field"
                />
                <span className="label"> words of each other</span>
                <div className="clear">
                  <If test={this.state.slopWords || this.state.slopDistance}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({slopWords: "", slopDistance: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider variant="middle" className="divider"/>
        <div className="rowContainer">
          <div className="headingRow row">
            <div className="column1">
              <Typography variant="subtitle1">Narrow your results to...</Typography>
            </div>
          </div>
          <div className="checkbox row">
            <div className="column1">
              <div className="fieldContainer">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="advancedSearchCode"
                      name="code"
                      checked={this.state.code}
                      indeterminate={!this.state.code && this.areAnyCodeChildrenChecked(this.state)}
                      onChange={this.handleCodeCheckboxChange}
                      className="field"
                    />
                  }
                  label="Code"
                  className="checkboxLabel"
                />
              </div>
            </div>
          </div>
          <div className="checkbox row">
            <div className="column1">
              <div className="child fieldContainer">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="advancedSearchCodeContent"
                      name="content"
                      checked={this.state.content}
                      onChange={this.handleCodeChildCheckboxChange}
                      className="field"
                    />
                  }
                  label="Content"
                  className="checkboxLabel"
                />
              </div>
            </div>
          </div>
          <div className="checkbox row">
            <div className="column1">
              <div className="child fieldContainer">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="advancedSearchCodeAttachment"
                      name="attachment"
                      checked={this.state.attachment}
                      onChange={this.handleCodeChildCheckboxChange}
                      className="field"
                    />
                  }
                  label="Attachment"
                  className="checkboxLabel"
                />
              </div>
            </div>
          </div>
          <div className="checkbox row">
            <div className="column1">
              <div className="child fieldContainer">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="advancedSearchCodeDefinition"
                      name="definition"
                      checked={this.state.definition}
                      onChange={this.handleCodeChildCheckboxChange}
                      className="field"
                    />
                  }
                  label="Definition"
                  className="checkboxLabel"
                />
              </div>
            </div>
          </div>
          <div className={"textField row" + (this.state.definition ? " shown" : " hidden")}>
            <div className="child column1">
              <div className="child fieldContainer">
                <TextField
                  id="advancedSearchTermWords"
                  name="termWords"
                  label="Term name includes these words:"
                  value={this.state.termWords}
                  onChange={this.handleFieldChange}
                  className="field"
                />
                <div className="clear">
                  <If test={this.state.termWords}>
                    <IconButton aria-label="Clear value" onClick={this.setStateValue({termWords: ""})}>
                      <ClearIcon/>
                    </IconButton>
                  </If>
                </div>
              </div>
            </div>
          </div>
          <If test={!archiveId}>
            <div className="rowGroup">
              <div className="checkbox row">
                <div className="column1">
                  <div className="fieldContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="advancedSearchDocs"
                          name="doc"
                          checked={this.state.doc}
                          indeterminate={!this.state.doc && this.areAnyDocsChildrenChecked(this.state, this.props)}
                          onChange={this.handleDocsCheckboxChange}
                          className="field"
                        />
                      }
                      label="Public Documents"
                      className="checkboxLabel"
                    />
                  </div>
                </div>
              </div>
              {pubDocTypes.map(type =>
                <div className="checkbox row" key={type.title}>
                  <div className="column1">
                    <div className="child fieldContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={"advancedSearchCode-" + type.id}
                            name={type.title}
                            checked={checkedTypes[type.title.toLowerCase()] || this.state.doc}
                            onChange={this.handleDocsChildCheckboxChange}
                            className="field"
                          />
                        }
                        label={type.title}
                        className="checkboxLabel"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={"textField row" + ((this.state.doc || this.areAnyDocsChildrenChecked(this.state, this.props)) ? " shown" : " hidden")}>
                <div className="column1">
                  <div className="child fieldContainer">
                    <TextField
                      id="advancedSearchCategoryWords"
                      name="categoryWords"
                      label="Category name includes these words:"
                      value={this.state.categoryWords}
                      onChange={this.handleFieldChange}
                      className="field"
                    />
                    <div className="clear">
                      <If test={this.state.categoryWords}>
                        <IconButton aria-label="Clear value" onClick={this.setStateValue({categoryWords: ""})}>
                          <ClearIcon/>
                        </IconButton>
                      </If>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"textField row" + ((this.state.doc || this.areAnyDocsChildrenChecked(this.state, this.props)) ? " shown" : " hidden")}>
                <div className="column1">
                  <div className="child fieldContainer">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker clearable
                        id="advancedSearchDocsDateFrom"
                        name="docsDateFrom"
                        label="Document from:"
                        value={this.state.docsDateFrom}
                        onChange={this.handleFromDateFieldChange("docsDateFrom")}
                        labelFunc={(date) => date ? date.toDateString() : ""}
                        className="half field"
                      />
                      <div className="clear">
                        <If test={this.state.docsDateFrom}>
                          <IconButton aria-label="Clear value" onClick={this.setStateValue({docsDateFrom: null})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                      <DatePicker clearable
                        id="advancedSearchDocsDateTo"
                        name="docsDateTo"
                        label="Document to:"
                        value={this.state.docsDateTo}
                        onChange={this.handleFromDateFieldChange("docsDateTo")}
                        labelFunc={(date) => date ? date.toDateString() : ""}
                        className="half field"
                      />
                      <div className="clear">
                        <If test={this.state.docsDateTo}>
                          <IconButton aria-label="Clear value" onClick={this.setStateValue({docsDateTo: null})}>
                            <ClearIcon/>
                          </IconButton>
                        </If>
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            </div>
          </If>
          <div className="rowGroup">
            <div className="checkbox row">
              <div className="column1">
                <div className="fieldContainer">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="advancedSearchLaw"
                        name="law"
                        checked={this.state.law}
                        onChange={this.handleFieldChange}
                        className="field"
                      />
                    }
                    label="New Laws"
                    className="checkboxLabel"
                  />
                </div>
              </div>
            </div>
            <div className={"textField row" + (this.state.law ? " shown" : " hidden")}>
              <div className="column1">
                <div className="child fieldContainer">
                  <TextField
                    id="advancedSearchSubjectWords"
                    name="subjectWords"
                    label="Subject includes these words:"
                    value={this.state.subjectWords}
                    onChange={this.handleFieldChange}
                    className="field"
                  />
                  <div className="clear">
                    <If test={this.state.subjectWords}>
                      <IconButton aria-label="Clear value" onClick={this.setStateValue({subjectWords: ""})}>
                        <ClearIcon/>
                      </IconButton>
                    </If>
                  </div>
                </div>
              </div>
            </div>
            <div className={"textField row" + (this.state.law ? " shown" : " hidden")}>
              <div className="column1">
                <div className="child fieldContainer">
                  <TextField
                    id="advancedSearchCommentWords"
                    name="commentWords"
                    label="Comment includes these words:"
                    value={this.state.commentWords}
                    onChange={this.handleFieldChange}
                    className="field"
                  />
                  <div className="clear">
                    <If test={this.state.commentWords}>
                      <IconButton aria-label="Clear value" onClick={this.setStateValue({commentWords: ""})}>
                        <ClearIcon/>
                      </IconButton>
                    </If>
                  </div>
                </div>
              </div>
            </div>
            <div className={"textField row" + (this.state.law ? " shown" : " hidden")}>
              <div className="column1">
                <div className="child fieldContainer">
                  <Select
                    id="affectsCodeSelect"
                    className="affectsCodeControl field"
                    label="Affects code"
                    value={!this.state.affectsCode ? "" : this.state.affectsCode}
                    onChange={this.handleFieldChange}
                    inputProps={{
                      id: 'affectsCodeSelectInput',
                      name: 'affectsCode'
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Affects Code</MenuItem>
                    <MenuItem value="false">Does Not Affect Code</MenuItem>
                  </Select>
                  <div className="clear">
                    <If test={this.state.affectsCode}>
                      <IconButton aria-label="Clear value" onClick={this.setStateValue({affectsCode: null})}>
                        <ClearIcon/>
                      </IconButton>
                    </If>
                  </div>
                </div>
              </div>
            </div>
            <div className={"textField row" + (this.state.law ? " shown" : " hidden")}>
              <div className="column1">
                <div className="child fieldContainer">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker clearable
                      id="advancedSearchLawsDateFrom"
                      name="lawsDateFrom"
                      label="Adopted from:"
                      value={this.state.lawsDateFrom}
                      onChange={this.handleFromDateFieldChange("lawsDateFrom")}
                      labelFunc={(date) => date ? date.toDateString() : ""}
                      className="half field"
                    />
                    <div className="clear">
                      <If test={this.state.lawsDateFrom}>
                        <IconButton aria-label="Clear value" onClick={this.setStateValue({lawsDateFrom: null})}>
                          <ClearIcon/>
                        </IconButton>
                      </If>
                    </div>
                    <DatePicker clearable
                      id="advancedSearchLawsDateTo"
                      name="lawsDateTo"
                      label="Adopted to:"
                      value={this.state.lawsDateTo}
                      onChange={this.handleFromDateFieldChange("lawsDateTo")}
                      labelFunc={(date) => date ? date.toDateString() : ""}
                      className="half field"
                    />
                    <div className="clear">
                      <If test={this.state.lawsDateTo}>
                        <IconButton aria-label="Clear value" onClick={this.setStateValue({lawsDateTo: null})}>
                          <ClearIcon/>
                        </IconButton>
                      </If>
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <If test={!archiveId}>
            <div className="rowGroup">
              <div className="checkbox row">
                <div className="column1">
                  <div className="fieldContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="advancedSearchNotes"
                          name="note"
                          checked={this.state.note}
                          indeterminate={!this.state.note && this.areAnyNotesChildrenChecked(this.state)}
                          onChange={this.handleNotesCheckboxChange}
                          className="field"
                        />
                      }
                      label="Notes"
                      className="checkboxLabel"
                    />
                  </div>
                </div>
              </div>
              <div className="checkbox row">
                <div className="column1">
                  <div className="child fieldContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="advancedSearchPublicVisibility"
                          name="publicVisibility"
                          checked={this.state.publicVisibility}
                          onChange={this.handleNotesChildCheckboxChange}
                          className="field"
                        />
                      }
                      label="Public"
                      className="checkboxLabel"
                    />
                  </div>
                </div>
              </div>
              {
                this.state.groupVisibility.length > 0 && this.state.groupVisibility.map((group) =>  (
                  <div key={group.id} className="checkbox row">
                    <div className="column1">
                      <div className="child fieldContainer">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={group.id + "Visibility"}
                              name={group.id}
                              checked={group.checked}
                              onChange={this.handleGroupVisibilityCheckboxChange}
                              className="field"
                            />
                          }
                          label={"Group - " + group.name}
                          className="checkboxLabel"
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className="checkbox row">
                <div className="column1">
                  <div className="child fieldContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="advancedSearchPrivateVisibility"
                          name="privateVisibility"
                          checked={this.state.privateVisibility}
                          onChange={this.handleNotesChildCheckboxChange}
                          className="field"
                        />
                      }
                      label="Private"
                      className="checkboxLabel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </If>
        </div>
      </Dialog>
    );
  };
}
export default AdvancedSearchDialog;
