import React from 'react';
import {Checkbox, FormControlLabel, TextField} from "../../../common/components";

const SignageTypeForm = ({formData, signageTypeForm, handleChange}) => {

  const handleFormChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleSwitchChange = (e) => {
    handleChange(e.target.id, e.target.checked);
  };

  const hasMetReq = (inp) => {
    return formData[inp.req];
  };

  return (
    <div className='signageFormControlContainer'>
      {(signageTypeForm && signageTypeForm.length > 0) && <div className='signageFormInputContainer'>
        {signageTypeForm.map((form) => {
          return (
            <div key={form.value_designator}>
              {(!form.req || hasMetReq(form)) && form.type === 'number' &&
                <TextField
                  id={form.value_designator}
                  key={form.value_designator}
                  name={form.value_designator}
                  label={form.label}
                  type={form.type}
                  value={(formData[form.value_designator]) ? formData[form.value_designator] : ""}
                  onChange={handleFormChange}
                  placeholder={form.label}
                  inputProps={{ min: 0}}
                  fullWidth
                />
              }
              {(!form.req || hasMetReq(form)) && form.type === 'switch' &&
                <FormControlLabel
                  key={form.value_designator}
                  className="filterLabel"
                  control={
                    <Checkbox
                      id={form.value_designator}
                      checked={!!formData[form.value_designator]}
                      onChange={handleSwitchChange}
                      style={{height: 'auto'}}
                    />
                  }
                  label={form.label}
                />
              }
            </div>
          );
        })}
      </div>}
    </div>
  );
};
export default SignageTypeForm;