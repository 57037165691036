import React from "react";
import ReactDOM from "react-dom";
import reducer, {initialState} from "./reducers";

import HtmlElement from "./components/HtmlElement";
import HomePageContainer from "./containers/HomePageContainer";
import NavigationRailContainer from "./containers/NavigationRailContainer";
import DialogContainer from "./containers/DialogContainer";
import VersionSelectorContainer from "./containers/VersionSelectorContainer";
import {
  closeDialog,
  disableDialogSubmit,
  initDialog,
  loadServerData,
  matchMediaHeight,
  matchMediaWidth,
  setBodyTop
} from "./actions";
import {replaceNodeWithReactComponent} from "./utils/ReactHelper";
import {ContentPreview, TocPageControl} from "./components";
import {newNav, pageSubType, pageType} from "./utils/server-data";
import {CALCULATOR, LAWS, PUBLIC_DOCUMENTS, QUESTIONS} from "./utils/page-types";
import {INDEX, NOTES, PUBLIC_DOCUMENTS_LANDING, QUESTIONS_STATUS, SEARCH_RESULTS} from "./utils/page-sub-types";
import ResponsivePageControls from "./components/pagecontrol/ResponsivePageControls";
import IndexPageControlsContainer from "../index/containers/IndexPageControlsContainer";
import LawsPageControlsContainer from "../laws/containers/LawsPageControlsContainer";
import NoteFilterContainer from "../notes/containers/NoteFilterContainer";
import PubDocsPageControlsContainer from "../pub-docs/containers/PubDocsPageControlsContainer";
import QuestionFiltersContainer from "../questions/containers/QuestionFiltersContainer";
import QuestionStatusPageControlsContainer from "../questions/containers/QuestionStatusPageControlsContainer";
import SearchResultsPageControlsContainer from "../search/containers/SearchResultsPageControlsContainer";
import UserMessageDialog from "./components/dialog/UserMessageDialog";

import "./preload";
import "./style/print.css";
import CalculatorPageControlsContainer from "../calculators/containers/CalculatorPageControlsContainer";
import EnhancedTocPageControl from "./components/pagecontrol/EnhancedTocPageControl";

const getInitialState = (loadingState) => {
  const commonState = loadingState.get(ModuleKey) || initialState;
  const serverData = window?.SERVER_DATA ? window.SERVER_DATA : {};
  return loadingState.set(ModuleKey, reducer(commonState, loadServerData(serverData)));
};

const init = (Providers, dispatch) => {
  const top = window.getComputedStyle(document.body).getPropertyValue("top");
  dispatch(setBodyTop(top === "auto" || top === "inherit" ? 0 : parseInt(top, 10)));

  const railElement = document.getElementById("rail");
  if (railElement) {
    replaceNodeWithReactComponent(railElement,
      <Providers key="nav-rail-providers" name="nav-rail">
        <UserMessageDialog/>
        <NavigationRailContainer/>
      </Providers>
    );
  }

  const homeElement = document.getElementById("homeContainer");
  if (homeElement) {
    replaceNodeWithReactComponent(homeElement,
      <Providers key={"home-page-providers"} name={"home-page"}>
        <HomePageContainer/>
      </Providers>
    );
  }

  const versionSelectorElement = document.getElementById("version-selector");
  if (versionSelectorElement) {
    replaceNodeWithReactComponent(versionSelectorElement,
      <Providers key="version-selector-providers" name="version-selector">
        <VersionSelectorContainer/>
      </Providers>
    );
  }

  const contentPreviewElement = document.getElementById("content-preview-wrapper");
  if (contentPreviewElement) {
    ReactDOM.render(
      <Providers key="content-preview-provider" name="content-preview">
        <ContentPreview/>
      </Providers>,
      contentPreviewElement
    );
  }

  if (newNav) {
    const pageControlsElement = document.getElementById("page-controls");
    if (pageControlsElement) {
      let pageControlWidgets = [];
      let wrapperClasses = "";
      const pageControlsWrapperElement = document.getElementById("page-controls-wrapper");
      if (pageControlsWrapperElement !== null) {
        wrapperClasses = pageControlsWrapperElement.className;
      }
      if (pageSubType === INDEX) {
        pageControlWidgets.push(<IndexPageControlsContainer key={"indexPageControls"}/>);
      } else if (pageType === LAWS) {
        const lawTable = document.querySelector(".lawTableContainer");
        let lawTableProps = lawTable ? {...lawTable.dataset} : {};
        if (lawTable) {
          pageControlWidgets.push(<LawsPageControlsContainer key={"lawsPageControls"} {...lawTableProps}/>);
        }
      } else if (pageType === CALCULATOR) {
        pageControlWidgets.push(<CalculatorPageControlsContainer key={"calculatorPageControls"} />);
      } else if (pageSubType === NOTES) {
        const noteFilters = document.querySelectorAll(".noteFilter");
        const noteFiltersContainer = document.querySelector(".noteFiltersContainer");
        if (noteFilters.length > 0 && noteFiltersContainer) {
          for (const noteFilter of noteFilters) {
            pageControlWidgets.push(
              <NoteFilterContainer key={noteFilter.dataset.key} {...(noteFilter.dataset)}/>
            );
          }
        }
      } else if (pageType === PUBLIC_DOCUMENTS) {
        pageControlWidgets.push(
          <PubDocsPageControlsContainer
            key={"pubDocsPageControls"}
            showAllCategories={pageSubType === PUBLIC_DOCUMENTS_LANDING}
            typeId={(pageSubType === PUBLIC_DOCUMENTS_LANDING) ? "all" : null}
          />
        );
      } else if (pageType === QUESTIONS) {
        let analysisContainers = document.getElementsByClassName("analysisContainer");
        if (analysisContainers.length > 1) {
          throw new Error("We cant handle multiple analysis yet");
        }
        if (analysisContainers.length > 0) {
          const analysisId = Number(analysisContainers[0].dataset.analysisId);
          if (pageSubType === QUESTIONS_STATUS) {
            pageControlWidgets.push(
              <QuestionStatusPageControlsContainer
                key={"questionStatusPageControls"}
                analysisId={analysisId}
              />
            );
          } else {
            pageControlWidgets.push(<QuestionFiltersContainer key={"questionsPageControls"} analysisId={analysisId}/>);
          }
        }
      } else if (pageSubType === SEARCH_RESULTS) {
        pageControlWidgets.push(<SearchResultsPageControlsContainer key={"searchPageControls"}/>);
      } else if (pageControlsWrapperElement !== null) {
        let pageControlWidgetElements = Array.from(pageControlsWrapperElement.getElementsByClassName("page-control-widget"));
        for (const pageControlWidgetElement of pageControlWidgetElements) {
          const isTocWidget = pageControlWidgetElement.classList.contains("toc-widget");
          if (isTocWidget && pageControlWidgetElement.dataset?.tocNodes) {
            const tocType = pageControlWidgetElement.dataset?.type;
            const tocLabel = pageControlWidgetElement.dataset?.label;
            const tocView = pageControlWidgetElement.dataset?.tocView;
            try {
              const tocNodes = (pageControlWidgetElement.dataset?.tocNodes)
                ? JSON.parse(pageControlWidgetElement.dataset.tocNodes) : [];
              pageControlWidgets.push((tocView && tocView === 'enhanced') ? <EnhancedTocPageControl key={tocType + "Toc"} type={tocType} label={tocLabel}
                initialTocNodes={tocNodes}/> : <TocPageControl key={tocType + "Toc"} type={tocType} label={tocLabel}
                tocNodes={tocNodes}/>);
            } catch (e) {
              console.error(e);
            }
          } else {
            pageControlWidgets.push(
              <HtmlElement key={pageControlWidgetElement.outerHTML} element={pageControlWidgetElement}></HtmlElement>
            );
          }
        }
      }
      ReactDOM.render(
        <Providers key="page-controls-provider" name="page-controls">
          <ResponsivePageControls className={wrapperClasses}>
            {pageControlWidgets}
          </ResponsivePageControls>
        </Providers>,
        pageControlsElement
      );
    }
  }

  //if you add to this list add to the list of matching states in the reducer
  //you cannot have overlapping states
  const mediaWidthConfig = {
    small: 'only screen and (max-width: 480px)',
    medium: 'only screen and (min-width: 481px) and (max-width: 912px)',
    large: 'only screen and (min-width: 913px) and (max-width: 1394px)',
    wide: 'only screen and (min-width: 1395px)',
  };

  const mediaHeightConfig = {
    short: 'only screen and (max-height: 480px)',
    medium: 'only screen and (min-height: 481px) and (max-height: 640px)',
    large: 'only screen and (min-height: 641px) and (max-height: 747px)',
    tall: 'only screen and (min-height: 748px)',
  };

  //for each state register a listener and when it matches, dispatch the event to the reducer
  const match = (config, action) => {
    Object.keys(config).forEach((media) => {
      const mql = window.matchMedia(config[media]);
      mql.addEventListener("change", ({matches}) =>
        matches && dispatch(action(media))
      );
      if (mql.matches) {
        dispatch(action(media));
      }
    });
  };
  match(mediaHeightConfig, matchMediaHeight);
  match(mediaWidthConfig, matchMediaWidth);

  //Export external interaction functions
  window.generalcode = window.generalcode || {};

  window.generalcode.closeDialog = async (dialogId) => {
    dispatch(closeDialog(dialogId));
  };

  window.generalcode.openDialog = async (dialogId, dialogProps) => {

    // Look for the dialog wrapper element
    let dialogWrapper = document.getElementById(dialogId);
    if (!dialogWrapper) {

      // If the dialog wrapper doesn't exist, find the container where the dialogs go
      let dialogsContainer = document.getElementById("dialogsContainer");
      if (!dialogsContainer) {

        // If the container doesn't exist, create it
        dialogsContainer = document.createElement("div");
        dialogsContainer.id = "dialogsContainer";
        document.body.appendChild(dialogsContainer);
      }

      // Then create the dialog wrapper element and add it to the dialog container
      dialogWrapper = document.createElement("div");
      dialogWrapper.id = dialogId;
      dialogsContainer.append(dialogWrapper);
    }

    // Add the new dialog to the Redux state
    dispatch(initDialog(dialogId, {
      onClose: () => {
        window.generalcode.closeDialog(dialogId);
      }, ...dialogProps
    }));

    // Then create the React element
    ReactDOM.render(
      <Providers key={`dialog-container-providers-${dialogId}`} name={`dialog-container-${dialogId}`}>
        <DialogContainer id={dialogId}/>
      </Providers>,
      dialogWrapper
    );
  };

  window.generalcode.disableDialogSubmit = async (dialogId) => {
    dispatch(disableDialogSubmit(dialogId));
  };

};

export const ModuleKey = "common";
export {reducer, getInitialState, init};

