import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";
import {PERMANENT, TEMPORARY} from "./SignageClasses";

const SignageClassSelection = (props) => {
  let signageClassMap = {
    [PERMANENT]: "Permanent Sign",
    [TEMPORARY]: "Temporary Sign"
  };

  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      label={props.label}
      id={props.listName}
      value={props.currentValue}
      onChange={handleChange}
    >
      {Object.keys(signageClassMap).map((byClass) => (
        <MenuItem key={byClass} value={byClass}>
          {signageClassMap[byClass]}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SignageClassSelection;
SignageClassSelection.propTypes = {
  currentValue: PropTypes.string,
  label: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};
