import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";
import {DISTRICT, PARKING} from "./BufferyardTypes";

const BufferyardTypeSelection = (props) => {
  let bufferyardTypeMap = {
    [DISTRICT]: "District Bufferyard",
    //[STREET]: "Street Bufferyard",
    [PARKING]: "Parking Bufferyard"
  };

  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      label={props.label}
      id={props.listName}
      value={props.currentValue}
      onChange={handleChange}
    >
      {Object.keys(bufferyardTypeMap).map((byType) => (
        <MenuItem key={byType} value={byType}>
          {bufferyardTypeMap[byType]}
        </MenuItem>
      ))}
    </Select>
  );
};
export default BufferyardTypeSelection;
BufferyardTypeSelection.propTypes = {
  currentValue: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};
