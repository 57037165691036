import {Record} from "immutable";
import {handleActions} from "redux-actions";
import * as actions from "../../calculators/actions";

const State = Record({
  bufferyardType: null,
  parkingType: null,
  signageClass: null
});

const initialState = State({});

const setBufferyardType = (state, {payload: {bufferyardType}}) => {
  return state.set('bufferyardType', bufferyardType);
};

const setSignageClass = (state, {payload: {signageClass}}) => {
  return state.set('signageClass', signageClass);
};

const reducer = handleActions({
  [actions.setBufferyardType]: setBufferyardType,
  [actions.setSignageClass]: setSignageClass
}, initialState);

export {State, initialState};
export default reducer;