
import React from "react";

const EnhancedTocLabel = ({prefix, labelText, depth}) => {
  return (
    <span>
      <span className={"toc-label-prefix toc-label-prefix-" + depth}>{prefix}</span> <span className={"toc-label-text toc-label-text-" + depth}>{labelText}</span>
    </span>
  );
}
export default EnhancedTocLabel;