import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../../common/components";
import PropTypes from "prop-types";

const BufferyardDistrictResult = (props) => {
  const CLASS_INFO = {
    "A": {
      width: {
        structure: 5,
        natural: 5,
        berm: 34
      },
      canopy: {
        structure: 1,
        natural: 1,
        berm: 1
      },
      ornimental: {
        structure: 2,
        natural: 4,
        berm: 4
      },
      shrubs: {
        structure: 10,
        natural: 15,
        berm: 15
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_a_wall.jpg",
        natural: "/images/calculators/class_a_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    },
    "B": {
      width: {
        structure: 10,
        natural: 10,
        berm: 34
      },
      canopy: {
        structure: 2,
        natural: 2,
        berm: 2
      },
      ornimental: {
        structure: 4,
        natural: 6,
        berm: 6
      },
      shrubs: {
        structure: 15,
        natural: 20,
        berm: 20
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_b_wall.jpg",
        natural: "/images/calculators/class_b_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    },
    "C": {
      width: {
        structure: 15,
        natural: 15,
        berm: 34
      },
      canopy: {
        structure: 3,
        natural: 3,
        berm: 3
      },
      ornimental: {
        structure: 4,
        natural: 6,
        berm: 6
      },
      shrubs: {
        structure: 20,
        natural: 20,
        berm: 20
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_c_wall.jpg",
        natural: "/images/calculators/class_c_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    },
    "D": {
      width: {
        structure: 20,
        natural: "Not Allowed",
        berm: 34
      },
      canopy: {
        structure: 4,
        natural: 4,
        berm: 4
      },
      ornimental: {
        structure: 6,
        natural: 8,
        berm: 8
      },
      shrubs: {
        structure: 30,
        natural: 30,
        berm: 30
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_d_wall.jpg",
        natural: null,
        berm: "/images/calculators/required_berm.jpg"
      }
    }
  };

  const propertyLength = props.propertyLength;
  const bclass = props.bufferyardClass;
  return (
    <Table id="bufferyardClassDetail" className="bufferyardClassTable reactTable">
      <colgroup>
        <col key="aspect" style={{width: '25%'}}/>
        <col key="structural" style={{width: '25%'}}/>
        <col key="natural" style={{width: '25%'}}/>
        <col key="naturalberm" style={{width: '25%'}}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell key="aspect" colSpan={4}>
            <h3>Bufferyard Type - {bclass}</h3>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
          </TableCell>
          <TableCell key="structural">
            <h3>Structural</h3>
          </TableCell>
          <TableCell key="natural">
            <h3>Natural Without Berm</h3>
          </TableCell>
          <TableCell key="naturalberm">
            <h3>Natural With Berm</h3>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="width">
          <TableCell key="width-aspect" style={{border: "1px"}} data-column-name="A">
            Min. Bufferyard Width (ft. from property line)
          </TableCell>
          <TableCell key="width-structural" style={{border: "1px"}} data-column-name="A">
            {CLASS_INFO[bclass]['width']['structure']} ft.
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            {(bclass !== "D") ? CLASS_INFO[bclass]['width']['natural'] + "ft." : "Not Allowed"}
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {CLASS_INFO[bclass]['width']['berm']} ft.
          </TableCell>
        </TableRow>
        <TableRow key="canopy">
          <TableCell key="canopy-aspect" style={{border: "1px"}} data-column-name="A">
            Canopy Trees
          </TableCell>
          <TableCell key="width-structural" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['canopy']['structure'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['canopy']['structure']} per 100 ft)
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            {(bclass !== "D") ? Math.round(CLASS_INFO[bclass]['canopy']['natural'] * Math.max(propertyLength / 100, 1)) + " (" + (CLASS_INFO[bclass]['canopy']['natural'] + " per 100 ft)") : "Not Allowed"}
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['canopy']['berm'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['canopy']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="ornimental">
          <TableCell key="ornimental-aspect" style={{border: "1px"}} data-column-name="A">
            Ornimental Trees
          </TableCell>
          <TableCell key="width-structural" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['ornimental']['structure'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['ornimental']['structure']} per 100 ft)
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            {(bclass !== "D") ? Math.round(CLASS_INFO[bclass]['ornimental']['natural'] * Math.max(propertyLength / 100, 1)) + " (" + (CLASS_INFO[bclass]['ornimental']['natural'] + " per 100 ft)") : "Not Allowed"}
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['ornimental']['berm'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['ornimental']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="shrubs">
          <TableCell key="shrubs-aspect" style={{border: "1px"}} data-column-name="A">
            Shrubs
          </TableCell>
          <TableCell key="width-structural" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['shrubs']['structure'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['shrubs']['structure']} per 100 ft)
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            {(bclass !== "D") ? Math.round(CLASS_INFO[bclass]['shrubs']['natural'] * Math.max(propertyLength / 100, 1)) + " (" + (CLASS_INFO[bclass]['shrubs']['natural'] + " per 100 ft)") : "Not Allowed"}
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['shrubs']['berm'] * Math.max(propertyLength / 100, 1)) } ({CLASS_INFO[bclass]['shrubs']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="height">
          <TableCell key="height-aspect" style={{border: "1px"}} data-column-name="A">
            Heigth of Wall or Fence / Berm
          </TableCell>
          <TableCell key="width-structural" style={{border: "1px"}} data-column-name="A">
            {CLASS_INFO[bclass]['height']['structure']} ft.[1]
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            N/A
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {CLASS_INFO[bclass]['height']['berm']} ft.[2]
          </TableCell>
        </TableRow>
        <TableRow key="image">
          <TableCell key="image-aspect" style={{border: "1px"}} data-column-name="A">
            Illustration
          </TableCell>
          <TableCell key="height-aspect" style={{border: "1px"}} data-column-name="A">
            <img alt="buffer yard with structure" src={CLASS_INFO[bclass]['images']['structure']}></img>
          </TableCell>
          <TableCell key="width-natural" style={{border: "1px"}} data-column-name="A">
            {bclass !== "D" ? <img alt="natural buffer yard without berm" src={CLASS_INFO[bclass]['images']['natural']}></img> : "N/A"}
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            <img  alt="natural buffer yard with berm" src={CLASS_INFO[bclass]['images']['berm']}></img>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

  );
};
export default BufferyardDistrictResult;
BufferyardDistrictResult.propTypes = {
  propertyLength: PropTypes.number,
  bufferyardClass: PropTypes.string
};