import React, {forwardRef, useState, useEffect, useCallback, useImperativeHandle, useRef} from "react";
import PageControl from "./PageControl";
import PageControlContent from "./PageControlContent";
import {TreeItem, TreeView} from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {makeStyles} from "@material-ui/core";
import {isNotBlank} from "../../utils";
import EnhancedTocLabel from "./EnhancedTocLabel";

import "./EnhancedTocPageControl.css";
import {archiveId} from "../../utils/server-data";

let scrollTimer = null;
let locationChanged = true;
let scrollCallback = function () {
  if (locationChanged) {
    const selectedTocNodeElement = document.querySelector("#code-toc-widget .Mui-selected");
    if (selectedTocNodeElement !== null) {
      selectedTocNodeElement.scrollIntoView({block: "nearest", inline: "start"});
    }
  }
  // Reset the scroll timer and the location change state after the callback is executed
  scrollTimer = null;
  locationChanged = false;
};

const thisGuid = () => {
  let guid = window.location.hash;
  if (guid) {
    return guid.substring(1);
  } else {
    let parts = window.location.pathname.split("/");
    if (parts.length > 0) {
      return parts[parts.length - 1];
    }
  }
  return null;
};

const EnhancedTocPageControl = forwardRef(({id, type, label, initialTocNodes}, ref) => {
  const useStylesDepth0 = makeStyles({
    root: {
      paddingLeft: 2,
    },
    content: {
      alignItems: "start"
    },
    iconContainer: {
      paddingTop: 4,
    },
    label: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.231,
      paddingLeft: "1.25rem",
      textIndent: "-1.25rem"
    }
  });
  const classesDepth0 = useStylesDepth0();
  const useStylesDepthX = makeStyles({
    root: {
      paddingLeft: 4,
    },
    content: {
      alignItems: "start"
    },
    iconContainer: {
      paddingTop: 1
    },
    label: {
      fontSize: "1rem",
      fontVariantNumeric: "lining-nums",
      lineHeight: 1.231,
      paddingLeft: "1.25rem",
      textIndent: "-1.25rem"

    }
  });
  const classesDepthX = useStylesDepthX();

  const getSessionExpandedNodeIds = () => {
    let saved = sessionStorage.getItem("expandedNodes");
    if (saved === null) {
      return [];
    } else {
      return saved.split(",");
    }
  };

  const [tocId, setTocId] = useState("toc-widget");
  const [pageGuids, setPageGuids] = useState([]);
  const [pageGuidMap, setPageGuidMap] = useState({});
  const [selectedTocNodeId, setSelectedTocNodeId] = useState(null);
  const [expandedTocNodeIds, setExpandedTocNodeIds] = useState([initialTocNodes.guid]);
  const [pageTopGuid, setPageTopGuid] = useState(null);
  const [tocNodes, setTocNodes] = useState(initialTocNodes);
  const controller = useRef(new AbortController());

  const getFullToc = async (custId) => {
    try {
      controller.current = new AbortController();
      let response = await fetch('/toc/' + custId + (isNotBlank(archiveId) ? "/" + archiveId : ""),
        {
          signal: controller.current.signal,
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        });
      let json = await response.json();
      if (json.hasOwnProperty("guid")) {
        setTocNodes(json);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const populateIds = useCallback((typePrefix, tocNode, tocNodeIds, pageHeadingIds, pageHeadingMap, defaultSelectedId) => {
    const tocNodeId = `${typePrefix}toc-node-${tocNode.guid}`;
    tocNodeIds.push(tocNodeId);
    if (tocNode.guid) {
      pageHeadingIds.push(tocNode.guid);
      pageHeadingMap[tocNode.guid] = {...tocNode, tocNodeId: tocNodeId};
    }
    if (tocNode.children?.length > 0) {
      for (const childNode of tocNode.children) {
        defaultSelectedId = populateIds(typePrefix, childNode, tocNodeIds, pageHeadingIds, pageHeadingMap, defaultSelectedId);
      }
    }
    return defaultSelectedId;
  }, []);

  const onScroll = useCallback(() => {
    let contentOffset = document.getElementById("page-content").offsetTop;
    for (let contentOffsetElement of document.getElementsByClassName("page-content-offset")) {
      contentOffset += contentOffsetElement.getBoundingClientRect().height;
    }

    let found = false;
    for (let i = (pageGuids.length - 1); i > -1; i--) {
      const pageHeadingId = pageGuids[i];
      const pageHeadingElement = document.getElementById(pageHeadingId);
      // If offsetParent is null, then the element isn't visible on the page
      if (pageHeadingElement?.offsetParent) {
        const tocNodeId = pageHeadingId;
        const tocNodeElement = document.getElementById(pageHeadingId);
        if (tocNodeElement) {
          if ((pageHeadingElement.getBoundingClientRect().top - 8) <= contentOffset) {
            // If the heading is in view, add the selected class if needed
            if (!tocNodeElement.classList.contains("Mui-selected")) {
              locationChanged = true;
            }
            found = true;

            setPageTopGuid(tocNodeId);
            break;
          }
        }
      }
    }
    if (!found) {
      let titleElement = document.querySelector("#pageTitle .titleTitle");
      let tg = titleElement.getAttribute("data-guid");

      setPageTopGuid(tg);
    }
    // Begin the scroll timer, which is used to determine when the scroll finishes
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    scrollTimer = setTimeout(scrollCallback, 250);
  }, [pageGuids]);

  const fillExpansionChildren = (newExpansion, tocNode) => {
    tocNode && tocNode.children.forEach(child => {
      if (!newExpansion.includes(child.guid)) {
        newExpansion.push(child.guid);
        child.children.forEach(cn => fillExpansionChildren(newExpansion, cn));
      }
    });
  };

  const fillExpansion = (currentExpansion, pageGuidMap) => {
    const theGuid = thisGuid();
    if (theGuid === tocNodes.guid) {
      return;
    }
    const newExpansion = Array.from(currentExpansion);
    let parent = pageGuidMap[theGuid]?.parent;
    while (isNotBlank(parent)) {
      if (!expandedTocNodeIds.includes(parent)) {
        newExpansion.push(parent);
      }
      parent = pageGuidMap[parent]?.parent;
    }
    if (!newExpansion.includes(tocNodes.guid)) {
      newExpansion.push(tocNodes.guid);
    }
    if (!newExpansion.includes(theGuid)) {
      newExpansion.push(theGuid);
    }
    fillExpansionChildren(newExpansion, pageGuidMap[theGuid]);
    return newExpansion;
  };

  const changeExpandedNodes = (nodeIds) => {
    setExpandedTocNodeIds(nodeIds);
    sessionStorage.setItem("expandedNodes", nodeIds);
  };

  useEffect(() => {
    const typePrefix = (type ? (type + "-") : "");
    const tocNodeIds = [];
    const newPageGuids = [];
    const newPageGuidMap = {};
    if (tocNodes) {
      for (const tocNode of tocNodes.children) {
        populateIds(typePrefix, tocNode, tocNodeIds, newPageGuids, newPageGuidMap, tocNodes.guid);
      }
    }
    setTocId(id ? id : (typePrefix + "toc-widget"));
    setPageGuids(newPageGuids);
    setPageGuidMap(newPageGuidMap);
    setSelectedTocNodeId(sessionStorage.getItem("selectedNode"));
    let expanded = fillExpansion(getSessionExpandedNodeIds(), newPageGuidMap);
    if (thisGuid() === tocNodes.guid) {
      expanded = [tocNodes.guid];
      tocNodes.children.forEach(tn => {expanded.push(tn.guid);});
      sessionStorage.removeItem("expandedNodes");
      sessionStorage.removeItem("selectedNode");
    }
    changeExpandedNodes(expanded);
  }, [tocNodes, populateIds]);

  useEffect(() => {
    setTimeout(onScroll, 750);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  useEffect(() => {
    locationChanged = true;
    setTimeout(scrollCallback, 250);
  }, [pageTopGuid]);

  // Function call by ResponsivePageControls when the page controls are expanded
  useImperativeHandle(ref, () => ({
    onContentSizeChange: () => {
      setTimeout(onScroll, 750);
    }
  }));

  useEffect(() => {
    if (pageTopGuid) {
      let checkNode = pageTopGuid;
      let parent = pageGuidMap[checkNode]?.parent;
      while (isNotBlank(parent)) {
        if (expandedTocNodeIds.includes(parent)) {
          changeSelectedNode(checkNode);
          break;
        }
        checkNode = parent;
        parent = pageGuidMap[parent]?.parent;
      }
    }
  },[pageGuidMap, pageTopGuid, expandedTocNodeIds]);

  useEffect(() => {
    getFullToc(tocNodes.guid);
  }, []);


  const handleNodeToggle = (event, nodeIds) => {
    if ("string" === typeof event.target.className && isNotBlank(event.target.className) && event.target.className.includes("-label")) {
      if (nodeIds.length < expandedTocNodeIds.length) {
        return;
      }
    }
    changeExpandedNodes(nodeIds);
  };

  const changeSelectedNode = (nodeId) => {
    setSelectedTocNodeId(nodeId);
    sessionStorage.setItem("selectedNode", nodeId);
  };

  const handleNodeSelect = (event, nodeId) => {
    if ("string" === typeof event.target.className && isNotBlank(event.target.className) && event.target.className.includes("-label")) {
      changeSelectedNode(nodeId);
      window.location.href = pageGuidMap[nodeId].href;
    }
  };

  const renderTocNode = (tocNode, depth) => {
    let classes = classesDepthX;
    if (depth < 1) {
      classes = classesDepth0;
    }
    return(
      <TreeItem
        key={tocNode.guid}
        classes={classes}
        nodeId={tocNode.guid}
        label={<EnhancedTocLabel prefix={tocNode.prefix} labelText={tocNode.tocName} depth={depth}></EnhancedTocLabel>}  >
        {tocNode.children.map(tN => renderTocNode(tN, depth + 1))}
      </TreeItem>
    );
  };

  return (
    <PageControl id={tocId} className="toc-widget enhanced-toc" scrollable>
      <PageControlContent>
        <TreeView
          className="toc-tree-widget"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expandedTocNodeIds}
          selected={selectedTocNodeId}
          onNodeToggle={handleNodeToggle}
          onNodeSelect={handleNodeSelect}
        >
          {tocNodes.children.map(tN => renderTocNode(tN, 0))}
        </TreeView>
      </PageControlContent>
    </PageControl>

  );
});
export {EnhancedTocPageControl};
export default EnhancedTocPageControl;