/*
 * Copyright 2018 General Code
 */

import React from "react";
import * as PropTypes from "prop-types";

import {Select} from "../../common/components";

class ReviewerSelect extends React.Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    assignedTo: PropTypes.string,
    reviewers: PropTypes.object.isRequired,
    isFinalized: PropTypes.bool,
    isLocked: PropTypes.bool,
    setAssignedTo: PropTypes.func.isRequired
  };

  handleAssignedToChange = (event) => {
    const {questionId} = this.props;
    const assignedTo = event.target.value;
    this.props.setAssignedTo(questionId, assignedTo);
  };

  render() {
    const {questionId, assignedTo, reviewers, isFinalized, isLocked} = this.props;
    return (
      <div className="reviewerSelectContainer">
        <Select
          label="Assigned To"
          id={"reviewerSelect-" + questionId}
          className={"formSelectControl" + (!assignedTo ? " blank" : "")}
          value={assignedTo == null ? "" : assignedTo}
          onChange={this.handleAssignedToChange}
          disabled={isFinalized || isLocked}
          options={Object.values(reviewers).map((reviewer) => ({value: reviewer.username, label: reviewer.displayName}))}
          emptyOption={{value: "", label: "None"}}
          style={{marginTop: 16, marginBottom: 12}}
          fullWidth={false}
        />
      </div>
    );
  }
}
export default ReviewerSelect;