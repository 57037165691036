import * as PropTypes from "prop-types";
import React, {useState, useMemo, useCallback} from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import {Paper} from "../";
import {AccordionDetails, AccordionSummary} from "./";
import {Expand as ExpandIcon, Collapse as CollapseIcon} from "../icons";
import {makeStyles} from "@material-ui/core";

const useSummaryStyles = makeStyles({
  root: {
    minHeight: "48px !important",
    padding: "0 0 0 8px !important"
  },
  content: {
    order: 1,
    margin: "0 !important"
  },
  expandIcon: {
    order: 0,
    margin: "0 -8px !important"
  },
});

const Accordion = (props) => {

  const summaryStyles = useSummaryStyles(props);

  const { expanded: controlledExpanded = null, defaultExpanded = null, onChange, summary, details, SummaryProps = {}, DetailsProps = {}, paperOnExpand = false, PaperProps = {}, elevation = 1, iconExpandOnly = false, ...otherProps } = props;
  const { classes: summaryClasses = {}, ...otherSummaryProps } = SummaryProps;
  const {
    root: summaryRootClasses = "",
    expanded: summaryExpandedClasses = "",
    content: summaryContentClasses = "",
    expandIcon: summaryExpandIconClasses = "",
    ...otherSummaryClasses
  } = summaryClasses;
  const { style: detailsStyle = {}, ...otherDetailsProps } = DetailsProps;
  const { flexDirection: detailsFlexDirection = "column", ...otherDetailsStyles } = detailsStyle;

  const [nonControlledExpanded, setNonControlledExpanded] = useState(Boolean(defaultExpanded));

  const controlled = useMemo(() => (controlledExpanded != null && defaultExpanded == null), [controlledExpanded, defaultExpanded]);

  const expanded = useMemo(() => (controlled ? controlledExpanded : nonControlledExpanded), [controlled, controlledExpanded, nonControlledExpanded]);

  const panelIcon = useMemo(() => (expanded ? <CollapseIcon className={"MuiAccordionSummary-expandIcon-icon"} /> : <ExpandIcon className={"MuiAccordionSummary-expandIcon-icon"} />), [expanded]);

  const onExpansionChange = useCallback((e) => {
    if (!iconExpandOnly || e.target?.classList?.contains("MuiAccordionSummary-expandIcon-icon") || e.target?.classList?.contains("MuiAccordionSummary-expandIcon")) {
      if (!controlled) {
        setNonControlledExpanded((ce) => !ce);
      }
      if (onChange) {
        onChange(e);
      }
    }
  }, [controlled, iconExpandOnly, onChange]);

  const renderAccordion = useCallback(() => (
    <MuiAccordion elevation={paperOnExpand ? 0 : elevation} expanded={expanded} onChange={onExpansionChange} {...otherProps}>
      <AccordionSummary
        classes={{
          root: (summaryStyles.root ? summaryStyles.root + " " : "") + summaryRootClasses,
          expanded: (summaryStyles.expanded ? summaryStyles.expanded + " " : "") + summaryExpandedClasses,
          content: (summaryStyles.content ?  summaryStyles.content + " " : "") + summaryContentClasses,
          expandIcon: (summaryStyles.expandIcon ? summaryStyles.expandIcon + " " : "") + summaryExpandIconClasses,
          ...otherSummaryClasses,
        }}
        expandIcon={panelIcon}
        {...otherSummaryProps}
      >
        {typeof summary === "string" ? (<div><span className="cardTitleSmall">{summary}</span></div>) : summary}
      </AccordionSummary>
      <AccordionDetails
        style={{ flexDirection: detailsFlexDirection, ...otherDetailsStyles }}
        {...otherDetailsProps}
      >
        <>{details}</>
      </AccordionDetails>
    </MuiAccordion>
  ), [summaryStyles.content, summaryStyles.expandIcon, summaryStyles.expanded, summaryStyles.root, details, detailsFlexDirection, elevation, expanded, onExpansionChange, otherDetailsProps, otherDetailsStyles, otherProps, otherSummaryClasses, otherSummaryProps, panelIcon, paperOnExpand, summary, summaryContentClasses, summaryExpandIconClasses, summaryExpandedClasses, summaryRootClasses]);

  if (paperOnExpand) {
    return (
      <Paper elevation={expanded ? (elevation + 2) : elevation} {...PaperProps}>
        {renderAccordion()}
      </Paper>
    );
  } else {
    return renderAccordion();
  }
};
Accordion.propTypes = {
  expanded: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  summary: PropTypes.node,
  details: PropTypes.node,
  SummaryProps: PropTypes.object,
  DetailsProps: PropTypes.object,
  paperOnExpand: PropTypes.bool,
  PaperProps: PropTypes.object,
  elevation: PropTypes.number,
  iconExpandOnly: PropTypes.bool,
};
export default Accordion;