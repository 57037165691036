import React from 'react';
import theme from './theme';
import {createGenerateClassName} from "@material-ui/core";
import {jssPreset} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {create} from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';

const styleNode = document.createComment("jss-insertion-point");
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: styleNode,
});

const Wrapper = (props) => (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  </JssProvider>
);

export default Wrapper;
