import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import {ParkingUseTypes} from "./ParkingUses";
import PropTypes from "prop-types";

const ParkingUseTypeSelection = (props) => {
  return (
    <Select
      label={props.label}
      id={props.listName}
      value={props.currentValue}
      onChange={props.onChange}
    >
      {Object.keys(ParkingUseTypes[props.parkingUseCategory]).map((useType) => (
        <MenuItem key={useType} value={useType}>
          {useType}
        </MenuItem>
      ))}
    </Select>
  );
};
export default ParkingUseTypeSelection;
ParkingUseTypeSelection.propTypes = {
  currentValue: PropTypes.string,
  label: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};