import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../../common/components";
import PropTypes from "prop-types";

const BufferyardStreetResult = (props) => {
  const CLASS_INFO = {
    "A": {
      width: {
        structure: 5,
        natural: 5,
        berm: 34
      },
      canopy: {
        structure: 1,
        natural: 1,
        berm: 1
      },
      ornimental: {
        structure: 2,
        natural: 4,
        berm: 4
      },
      shrubs: {
        structure: 10,
        natural: 15,
        berm: 15
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_a_wall.jpg",
        natural: "/images/calculators/class_a_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    },
    "B": {
      width: {
        structure: 10,
        natural: 10,
        berm: 34
      },
      canopy: {
        structure: 2,
        natural: 2,
        berm: 2
      },
      ornimental: {
        structure: 4,
        natural: 6,
        berm: 6
      },
      shrubs: {
        structure: 15,
        natural: 20,
        berm: 20
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_b_wall.jpg",
        natural: "/images/calculators/class_b_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    },
    "C": {
      width: {
        structure: 15,
        natural: 15,
        berm: 34
      },
      canopy: {
        structure: 3,
        natural: 3,
        berm: 3
      },
      ornimental: {
        structure: 4,
        natural: 6,
        berm: 6
      },
      shrubs: {
        structure: 20,
        natural: 20,
        berm: 20
      },
      height: {
        structure: 6,
        berm: 4
      },
      images: {
        structure: "/images/calculators/class_c_wall.jpg",
        natural: "/images/calculators/class_c_natural.jpg",
        berm: "/images/calculators/required_berm.jpg"
      }
    }
  };

  const propertyLength = props.propertyLength;
  const bclass = props.bufferyardClass;
  return (
    <Table id="bufferyardClassDetail" className="bufferyardClassTable reactTable">
      <colgroup>
        <col key="aspect" style={{width: '50%'}}/>
        <col key="value" style={{width: '50%'}}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell key="aspect" colSpan={2}>
            <h3>Bufferyard Type - {bclass}</h3>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell key="aspect" colSpan={2}>
            <h4>Min. Bufferyard Width (ft. from property line)</h4>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
            Natural Without Berm
          </TableCell>
          <TableCell key="value">
            {CLASS_INFO[bclass]['width']['natural']} ft.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect">
            Natural With Berm
          </TableCell>
          <TableCell key="value">
            {CLASS_INFO[bclass]['width']['berm']} ft.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect" colSpan={2}>
            <h4>Required Plantings - Natural Bufferyards</h4>
          </TableCell>
        </TableRow>
        <TableRow key="canopy">
          <TableCell key="canopy-aspect" style={{border: "1px"}} data-column-name="A">
            Canopy Trees
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['canopy']['berm'] * propertyLength / 100) } ({CLASS_INFO[bclass]['canopy']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="ornimental">
          <TableCell key="ornimental-aspect" style={{border: "1px"}} data-column-name="A">
            Ornimental Trees
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['ornimental']['berm'] * propertyLength / 100) } ({CLASS_INFO[bclass]['ornimental']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="shrubs">
          <TableCell key="shrubs-aspect" style={{border: "1px"}} data-column-name="A">
            Shrubs
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {Math.round(CLASS_INFO[bclass]['shrubs']['berm'] * propertyLength / 100) } ({CLASS_INFO[bclass]['shrubs']['berm']} per 100 ft)
          </TableCell>
        </TableRow>
        <TableRow key="height">
          <TableCell key="height-aspect" style={{border: "1px"}} data-column-name="A">
            Heigth of Berm if Provided
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            {CLASS_INFO[bclass]['height']['berm']} ft.[2]
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell key="aspect" colSpan={2}>
            <h4>Illustration</h4>
          </TableCell>
        </TableRow>
        <TableRow key="image">
          <TableCell key="image-aspect" style={{border: "1px"}} data-column-name="A">
            Natural Without Berm
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            <img alt="natural buffer yard without berm" src={CLASS_INFO[bclass]['images']['natural']}></img>
          </TableCell>
        </TableRow>
        <TableRow key="bermimage">
          <TableCell key="image-aspect" style={{border: "1px"}} data-column-name="A">
            Natural With Berm
          </TableCell>
          <TableCell key="width-berm" style={{border: "1px"}} data-column-name="A">
            <img alt="natural buffer yard with berm" src={CLASS_INFO[bclass]['images']['berm']}></img>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

  );
};
export default BufferyardStreetResult;
BufferyardStreetResult.propTypes = {
  propertyLength: PropTypes.number,
  bufferyardClass: PropTypes.string
};