import React, {useCallback, useEffect, useState} from 'react';
import lookupDistrictBufferyardClass from "./BufferyardDistrictXref";
import BufferyardDistrictResult from "./BufferyardDistrictResult";
import BufferyardDistrictForm from "./BufferyardDistrictForm";
import lookupStreetBufferyardClass from "./BufferyardStreetXref";
import BufferyardStreetForm from "./BufferyardStreetForm";
import BufferyardStreetResult from "./BufferyardStreetResult";
import {isNotBlank} from "../../../common/utils";
import BufferyardParkingForm from "./BufferyardParkingForm";
import BufferyardParkingResult from "./BufferyardParkingResult";
import {DISTRICT, PARKING, STREET} from "./BufferyardTypes";
import {capitalize} from "lodash";
import BufferyardTypeSelection from "./BufferyardTypeSelection";
import {custId} from "../../../common/utils/server-data";

const BufferyardCalculator = (props) => {
  const [abuttingStreetClass, setAbuttingStreetClass] = useState("");
  const [adjoiningDistrict, setAdjoiningDistrict] = useState("");
  const [developmentDistrict, setDevelopmentDistrict] = useState("");
  const [propertyLength, setPropertyLength] = useState("");
  const [gotResult, setGotResult] = useState(false);
  const [bufferyardClass, setBufferyardClass] = useState("");
  const searchParams = new URLSearchParams(document.location.search);
  const bufferyardType = props.bufferyardType;
  const setBufferyardType = props.setBufferyardType;

  const handleFormChange = (e) => {
    if (e.target.name === 'developmentDistrict') {
      setDevelopmentDistrict(e.target.value);
    } else if (e.target.name === 'adjoiningDistrict') {
      setAdjoiningDistrict(e.target.value);
    } else if (e.target.name === 'abuttingStreet') {
      setAbuttingStreetClass(e.target.value);
    } else {
      setPropertyLength(e.target.value.replaceAll(/[^0-9]/g,''));
    }
  };

  const calcResult = useCallback( () => {
    if (bufferyardType && isNotBlank(developmentDistrict)) {
      if (bufferyardType === DISTRICT  && isNotBlank(adjoiningDistrict)) {
        setBufferyardClass(lookupDistrictBufferyardClass(developmentDistrict, adjoiningDistrict));
      } else if (bufferyardType === STREET && isNotBlank(abuttingStreetClass)) {
        setBufferyardClass(lookupStreetBufferyardClass(developmentDistrict, abuttingStreetClass));
      }
    }
  }, [abuttingStreetClass, adjoiningDistrict, bufferyardType, developmentDistrict]);

  const clearForm = () => {
    setAbuttingStreetClass("");
    setDevelopmentDistrict("");
    setAdjoiningDistrict("");
    setPropertyLength("");
    setBufferyardClass("");
    setGotResult(false);
  };

  const handleBufferyardTypeChange = (e) => {
    window.location.href = `/calculators/${custId}/bufferyard?bufferyardType=${e.toLowerCase()}`;
  };

  useEffect(() => {
    if (propertyLength && propertyLength > 0) {
      if (bufferyardType === DISTRICT || bufferyardType === STREET) {
        calcResult();
      }
      setGotResult(true);
    } else {
      setGotResult(false);
    }
  }, [bufferyardType, calcResult, propertyLength]);

  useEffect( () => {
    setBufferyardType(capitalize(searchParams.get('bufferyardType')));
  }, [searchParams, setBufferyardType]);

  return (
    <div className="calculator">
      {bufferyardType && <div className="calculatorForm">
        <h5>See <a href={"/45661894#45661916"}>Section 39.03.016, Bufferyard Landscaping</a></h5>
        <h2>Required Information</h2>
        {(bufferyardType === DISTRICT) && <BufferyardDistrictForm
          adjoiningDistrict={adjoiningDistrict}
          developmentDistrict={developmentDistrict}
          propertyLength={propertyLength}
          handleChange={handleFormChange}
          clearForm={clearForm}
        />}
        {(bufferyardType === STREET) && <BufferyardStreetForm
          abuttingStreetClass={abuttingStreetClass}
          developmentDistrict={developmentDistrict}
          propertyLength={propertyLength}
          handleChange={handleFormChange}
          clearForm={clearForm}
        />}
        {(bufferyardType === PARKING) && <BufferyardParkingForm
          parkingSpaces={propertyLength}
          handleChange={handleFormChange}
          clearForm={clearForm}
        />}

      </div>}
      {!bufferyardType &&
        <BufferyardTypeSelection
          label="Choose Bufferyard Type"
          currentValue={bufferyardType}
          onChange={handleBufferyardTypeChange}
          listName="bufferyardType"
        />
      }
      {(bufferyardType === DISTRICT) && gotResult && <div className="calculatorResult">
        <h2>Results</h2>
        {(isNotBlank(bufferyardClass)) ? <BufferyardDistrictResult bufferyardClass={bufferyardClass} propertyLength={propertyLength}/>: "No bufferyard required"}
      </div>}
      {(bufferyardType === STREET) && gotResult && <div className="calculatorResult">
        <h2>Results</h2>
        {(isNotBlank(bufferyardClass)) ? <BufferyardStreetResult bufferyardClass={bufferyardClass} propertyLength={propertyLength}/>: "No bufferyard required"}
      </div>}
      {(bufferyardType === PARKING) && gotResult && <div className="calculatorResult">
        <h2>Results</h2>
        <BufferyardParkingResult parkingSpaces={propertyLength}/>
      </div>}
      <div className="calculatorNotes">
        <h2>Notes</h2>
        <h3>General Notes</h3>
        <ol>
          <li>
            The RE Zoning District is exempt from all landscaping requirements.
          </li>
          <li>
            In addition to the required plantings, walls, fences, and berms, the remaining portions of all bufferyards shall contain groundcover.
          </li>
          <li>
            Some limited or specific uses may have different requirements for bufferyards, as specified in <a href={"/45660753#45660794"}>Section 39.02.018, Limited Use Standards</a>, and <a href={"/45660753#45661100"}>Section 39.02.019, Specific Use Standards</a>. If bufferyards are required by another section of this UDC along property boundaries that are also district boundaries, then the most restrictive bufferyard requirement is required.
          </li>
          <li>
            See <a href={"/45661916#45661916"}>Section 39.03.016, Bufferyard Landscaping</a>, paragraph d.5. Substitutions, for permitted planting substitutions and exemptions.
          </li>
        </ol>
        {(isNotBlank(bufferyardClass) && bufferyardType) && <div className="specificNotes">
          <h3>Specific Notes</h3>
          <ol>
            <li>
              Fences and walls that are part of the required district bufferyard shall:
              <ol className="specific-notes-sublist">
                <li>Be composed of treated wood, composite material, or masonry only. Prohibited materials include, but are not limited to: plywood, corrugated steel sheets, and chain-link with or without weave mesh or slats;</li>
                <li>Not encroach into the public right-of-way;</li>
                <li>Be maintained by the business, homeowner or homeowners' association, property owners' association, or public improvement district or tax increment finance district as applicable;</li>
                <li>Include natural accents, including landscaping and groundcover, in accordance with Table 39.03.016-2, Bufferyard Classifications, in addition to the structure; and</li>
                <li>Have plans and details designed and sealed by a licensed professional engineer and be approved by the City Engineer if required.</li>
              </ol>
            </li>
            <li>
              Earthen berms that are part of a required district bufferyard shall have:
              <ol className="specific-notes-sublist">
                <li>A minimum two-foot-wide flat area on top;</li>
                <li>Slopes of not less than four feet horizontal for each one foot vertical; and</li>
                <li>In order to accommodate a berm, the bufferyard may need to be wider than required in Table 39.03.016-2, Bufferyard Classifications. For example, a Type A bufferyard would have to be over 34 feet in width in order to accommodate the berm but will only require the number of plantings required for a Type A bufferyard.</li>
              </ol>
            </li>
          </ol>
        </div>}
      </div>
    </div>
  );
};

export default BufferyardCalculator;
BufferyardCalculator.propTypes = {
};