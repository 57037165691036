import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {toJS} from "../../common/utils";
import {
  getAnalysis,
  getAssignedQuestions,
  getIncompleteQuestions,
  getNeedsReviewQuestions,
  getQuestions
} from "../selectors";
import {completeAnalysis} from "../actions";
import * as PropTypes from "prop-types";
import QuestionStatusPageControls from "../components/QuestionStatusPageControls";
import {getCustId, hasIa2CompletePermission, hasIa2FinalizePermission, hasIa2PrintPermission} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  analysis: getAnalysis,
  questions: state => getQuestions(state).size,
  assigned: state => getAssignedQuestions(state).size,
  needsReview: state => getNeedsReviewQuestions(state).size,
  incomplete: state => getIncompleteQuestions(state).size,
  custId: getCustId,
  hasPrintPermission: hasIa2PrintPermission,
  hasFinalizePermission: hasIa2FinalizePermission,
  hasCompletePermission: hasIa2CompletePermission
});

const mapDispatchToProps = (dispatch) => ({
  completeAnalysis: (analysisId) => {
    dispatch(completeAnalysis(analysisId));
  },
});

const QuestionStatusPageControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionStatusPageControls);
QuestionStatusPageControlsContainer.propTypes = {
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default QuestionStatusPageControlsContainer;