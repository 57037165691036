import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';
import {setPubDocTypeEditOpen} from "../../pub-docs/actions";
import {getPubDocsSidebarName, getPubDocTypeId, getPubDocTypes} from "../../pub-docs/selectors";
import {getDisplayedQuestionIds} from "../../questions/selectors";
import {
  getAdminLongName,
  getAdminShortName,
  getAnalysisId,
  getArchiveId,
  getCalculatorsEnabled,
  getCodeId,
  getCodeLabel,
  getCodeLongName,
  getCodeShortName,
  getCustId,
  getGlobalPublicAutoHide,
  getGlobalPublicMessage,
  getGlobalPublicMessageDismissalDays,
  getHelpLongName,
  getHelpShortName,
  getHomeShortName,
  getLawCount,
  getLawLedgerCount,
  getLawLedgerName,
  getLawsShortName,
  getMapUrl,
  getNewLawsName,
  getNoteCount,
  getNotesShortName,
  getPageSubType,
  getPageType,
  getPubDocsOnly,
  getPubdocsLongName,
  getPubdocsShortName,
  getQuestionsShortName,
  getSearchResultsUrl,
  getZoningChapterName,
  getZoningGuid,
  getZoningLongName,
  getZoningMapName,
  getZoningShortName,
  hasArchivesViewPermission,
  hasCustomizationAdminPermission,
  hasDashboardBudgetPermission,
  hasDashboardDistributionPermission,
  hasDashboardHistoryPermission,
  hasDashboardPermission,
  hasDashboardTitlesPermission,
  hasDashboardStatsPermission,
  hasDocumentAdminPermission,
  hasFeatureAdminPermission,
  hasIndexViewPermission,
  hasLawViewPermission,
  hasLawLedgerViewPermission,
  hasPubDocPrivateViewPermission,
  hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission,
  hasUserAdminPermission,
  hasViewPermission, getHasDistributionData, getIsIndexViewVisible, getIsAdminOptionHide,
} from "../selectors";
import {toJS} from "../utils";
import NavigationRail from "../components/navrail/NavigationRail";

const mapStateToProps = createStructuredSelector({
  adminLongName: getAdminLongName,
  adminShortName: getAdminShortName,
  analysisId: getAnalysisId,
  archiveId: getArchiveId,
  calculatorsEnabled: getCalculatorsEnabled,
  codeId: getCodeId,
  codeLabel: getCodeLabel,
  codeLongName: getCodeLongName,
  codeShortName: getCodeShortName,
  custId: getCustId,
  displayedQuestionIds: getDisplayedQuestionIds,
  globalPublicAutoHide: getGlobalPublicAutoHide,
  globalPublicMessage: getGlobalPublicMessage,
  globalPublicMessageDismissalDays: getGlobalPublicMessageDismissalDays,
  hasArchivesViewPermission: hasArchivesViewPermission,
  hasCustomizationAdminPermission: hasCustomizationAdminPermission,
  hasDashboardBudgetPermission: hasDashboardBudgetPermission,
  hasDashboardDistributionPermission: hasDashboardDistributionPermission,
  hasDashboardHistoryPermission: hasDashboardHistoryPermission,
  hasDashboardPermission: hasDashboardPermission,
  hasDashboardTitlesPermission: hasDashboardTitlesPermission,
  hasDashboardStatsPermission: hasDashboardStatsPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  hasFeatureAdminPermission: hasFeatureAdminPermission,
  hasIndexViewPermission: hasIndexViewPermission,
  hasLawViewPermission: hasLawViewPermission,
  hasLawLedgerViewPermission: hasLawLedgerViewPermission,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  hasPubDocTypeAddPermission: hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
  hasUserAdminPermission: hasUserAdminPermission,
  hasViewPermission: hasViewPermission,
  helpLongName: getHelpLongName,
  helpShortName: getHelpShortName,
  homeShortName: getHomeShortName,
  lawCount: getLawCount,
  lawLedgerCount: getLawLedgerCount,
  lawLedgerName: getLawLedgerName,
  lawsShortName: getLawsShortName,
  mapUrl: getMapUrl,
  newLawsName: getNewLawsName,
  noteCount: getNoteCount,
  notesShortName: getNotesShortName,
  pageSubType: getPageSubType,
  pageType: getPageType,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypes: getPubDocTypes,
  pubDocsLabel: getPubDocsSidebarName,
  pubDocsOnly: getPubDocsOnly,
  pubdocsLongName: getPubdocsLongName,
  pubdocsShortName: getPubdocsShortName,
  questionsShortName: getQuestionsShortName,
  searchResultsUrl: getSearchResultsUrl,
  zoningChapterName: getZoningChapterName,
  zoningGuid: getZoningGuid,
  zoningLongName: getZoningLongName,
  zoningMapName: getZoningMapName,
  zoningShortName: getZoningShortName,
  hasDistributionData: getHasDistributionData,
  indexViewVisible: getIsIndexViewVisible,
  adminOptionHide: getIsAdminOptionHide
});

const mapDispatchToProps = (dispatch) => ({
  closePubDocTypeAddDialog: () => {
    dispatch(setPubDocTypeEditOpen(false, null));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(NavigationRail);
