import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";

const SignageDistrictSelection = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      id={props.listName}
      label={props.label}
      value={props.currentValue}
      onChange={handleChange}
    >
      {props.signageDistricts.map((category) => (
        <MenuItem key={category} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SignageDistrictSelection;
SignageDistrictSelection.propTypes = {
  currentValue: PropTypes.string,
  label: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
  signageDistricts: PropTypes.array
};