import * as PropTypes from "prop-types";
import React, {useMemo, useState} from "react";
import {connect} from "react-redux";

import {Button, Divider, IconButton, ListItemIcon, Typography} from "../../common/components";
import {Menu, MenuItem} from "../../common/components/menu";
import {
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  Note as NotesIcon,
  Settings as SettingsIcon,
} from "../../common/components/icons";
import If from "../../common/containers/If";
import {mediaWidths} from "../../common/reducers";
import {getCustId, getUsername, getUserFirstName, getUserLastName, getMyNotesCount, getMediaWidth} from "../../common/selectors";

import "./MenubarSearchComponent.css";

const AccountActionsMenu = ({custId, username, userFirstName, userLastName, myNotesCount, mediaWidth}) => {

  const [menuButton, setMenuButton] = useState(null);

  const userHasName = useMemo(() => (userFirstName || userLastName), [userFirstName, userLastName]);

  return (
    <>
      <If test={username}>
        <div id="account-actions" className="search-bar-action">
          <IconButton id="account-actions-button" aria-label="Account Actions" onClick={(e) => setMenuButton(e.currentTarget)}>
            <AccountCircleIcon size={26}/>
          </IconButton>
          <Menu
            id="account-actions-menu"
            anchorEl={menuButton}
            open={menuButton !== null}
            onClose={() => setMenuButton(null)}
            keepMounted={true}
            MenuListProps={{"aria-labelledby": "account-actions"}}
          >
            <MenuItem style={{ pointerEvents: 'none' }}>
              <div style={{display: "flex", alignItems: "center", columnGap: 8}}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <AccountCircleIcon size={28} />
                </div>
                <div style={{display: "flex", flexDirection: "column", paddingRight: 8, paddingBottom: 4}}>
                  <If test={userHasName}>
                    <Typography component="span" variant="title" style={{lineHeight: 1, fontWeight: "bold"}}>{userFirstName ?? ""} {userLastName ?? ""}</Typography>
                  </If>
                  <Typography component="span" variant={userHasName ? "subtitle1" : "title"} style={{lineHeight: 1, fontWeight: userHasName ? "normal" : "bold"}}>
                    {username}
                  </Typography>
                </div>
              </div>
            </MenuItem>
            <Divider />
            <MenuItem component="a" href={"/user/settings" + (custId ? ("/" + custId) : "")}>
              <ListItemIcon style={{minWidth: 28}}><SettingsIcon size={20} /></ListItemIcon>
              <Typography component="span" variant="body2">User Settings</Typography>
            </MenuItem>
            <If test={myNotesCount}>
              <MenuItem component="a" href={"/" + (custId ? (custId + "/") : "") + "note/all"}>
                <ListItemIcon style={{minWidth: 28}}><NotesIcon size={20} /></ListItemIcon>
                <Typography component="span" variant="body2">My Notes</Typography>
              </MenuItem>
            </If>
            <Divider />
            <MenuItem component="a" href={"/user/logout" + (custId ? "/" + custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">
              <ListItemIcon style={{minWidth: 28}}><LogoutIcon size={20} /></ListItemIcon>
              <Typography component="span" variant="body2">Logout</Typography>
            </MenuItem>
          </Menu>
        </div>
      </If>
      <If test={!username && !window.location.pathname.startsWith("/user/login") && mediaWidth > mediaWidths.medium}>
        <div className="search-bar-action">
          <Button component="a" variant="contained" color="default" size="small" href={"/user/login" + (custId ? "/" + custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">Login</Button>
        </div>
      </If>
    </>
  );
};
AccountActionsMenu.propTypes = {
  custId: PropTypes.string,
  username: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  myNotesCount: PropTypes.number,
  mediaWidth: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => ({
  custId: getCustId(state),
  username: getUsername(state),
  userFirstName: getUserFirstName(),
  userLastName: getUserLastName(),
  myNotesCount: getMyNotesCount(),
  mediaWidth: getMediaWidth(state),
});
export default connect(mapStateToProps)(AccountActionsMenu);
