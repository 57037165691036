const BufferyardStreetXref = {
  "RE": {
    "Arterial": "C",
    "Collector": "B",
    "Local": null
  },
  "SF-1": {
    "Arterial": "C",
    "Collector": "B",
    "Local": null
  },
  "SF-2": {
    "Arterial": "C",
    "Collector": "B",
    "Local": null
  },
  "OF": {
    "Arterial": "C",
    "Collector": "B",
    "Local": "A"
  },
  "AC": {
    "Arterial": "C",
    "Collector": "B",
    "Local": "A"
  },
};

const lookupStreetBufferyardClass = (development,abutting) => {
  return Object.keys(BufferyardStreetXref).includes(development) ? BufferyardStreetXref[development][abutting] : null;
};
export default lookupStreetBufferyardClass;