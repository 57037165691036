/*
 * Copyright 2018 General Code
 */

import * as PropTypes from "prop-types";
import React, {useState} from "react";

import {Button, Dialog, PrimaryButton} from "../../common/components";
import {PageControl, PageControlContent} from "../../common/components/pagecontrol";
import {If} from "../../common/containers";
import "./QuestionStatusPageControls.css";

const QuestionStatusPageControls = (
  {
    analysis,
    questions,
    assigned,
    needsReview,
    incomplete,
    hasPrintPermission,
    hasFinalizePermission,
    hasCompletePermission,
    completeAnalysis,
    custId
  }
) => {
  const [confirmOpen, setConfirmOpen] = useState(false);


  const handleAnalysisCheckClick = () => {
    window.location.href = "/" + custId + "/questions?check=true"
  };

  const cancelComplete = () => {
    setConfirmOpen(false);
  };

  const handleComplete = () => {
    setConfirmOpen(true);
  };

  const handleCompleteAnalysis = () => {
    cancelComplete();
    completeAnalysis(analysis.id);
  };

  return (
    <>
      <PageControl className="actionButtonsPageControl">
        <PageControlContent className="actionButtonsWrapper">
          <Button className="questionStatusPageButton" href="../questions" variant="contained">
            All Questions ({questions})
          </Button>
          <If test={assigned > 0}>
            <Button className="questionStatusPageButton" href="../questions?myQuestions=true" variant="contained">
              My Questions ({assigned})
            </Button>
          </If>
          <Button className="questionStatusPageButton" href="../questions?needsReview=true" variant="contained">
            Needs Review ({needsReview})
          </Button>
          <Button className="questionStatusPageButton" href="../questions?status=INCOMPLETE" variant="contained">
            Incomplete ({incomplete})
          </Button>
        </PageControlContent>
      </PageControl>

      <If test={(analysis && !analysis.finalized && hasFinalizePermission) || hasPrintPermission}>
        <PageControl className="actionButtonsPageControl">
          <PageControlContent className="actionButtonsWrapper finalizeButtonContainer">
            <If test={analysis && !analysis.finalized && hasFinalizePermission}>
              <PrimaryButton
                variant="contained"
                onClick={handleAnalysisCheckClick}
                title="Check this analysis for errors."
                color="primary"
              >
                Check
              </PrimaryButton>
            </If>

            <If test={hasPrintPermission}>
              <PrimaryButton
                variant="contained"
                href="./print"
                className="downloadIa2Button"
                title="Download analysis"
              >
                Download
              </PrimaryButton>
            </If>

            <If test={hasCompletePermission && analysis && analysis.finalized }>
              <PrimaryButton
                variant="contained"
                onClick={handleComplete}
                title="Complete this analysis"
              >
                Complete
              </PrimaryButton>
              <Dialog
                title="Complete Analysis?"
                open={confirmOpen}
                onCancel={cancelComplete}
                onSubmit={handleCompleteAnalysis}
              >
                Completing this analysis will remove it from view. Complete analysis?
              </Dialog>
            </If>

          </PageControlContent>
        </PageControl>
      </If>
    </>
  );
}

QuestionStatusPageControls.propTypes = {
  questions: PropTypes.number.isRequired,
  assigned: PropTypes.number.isRequired,
  needsReview: PropTypes.number.isRequired,
  incomplete: PropTypes.number.isRequired,
  hasPrintPermission: PropTypes.bool.isRequired,
  hasFinalizePermission: PropTypes.bool.isRequired,
  custId: PropTypes.string.isRequired
};

export default QuestionStatusPageControls;