import * as PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {
  AuditLine,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "../../common/components";
import If from "../../common/containers/If";
import "./EditLawDialog.css";
import moment from "moment";

class EditLawDialog extends PureComponent {
  NOT_SUPPED = "Not Supped";
  static propTypes = {
    law: PropTypes.shape({
      adoptedDate: PropTypes.string,
      documentId: PropTypes.string.isRequired,
      customTitle: PropTypes.string.isRequired,
      comment: PropTypes.string,
      deleted: PropTypes.string,
      description: PropTypes.string,
      originalFilename: PropTypes.string,
      rank: PropTypes.string,
      subject: PropTypes.string,
      codeId: PropTypes.string,
      suppedBy: PropTypes.string,
      legislationYear: PropTypes.string,
      adoptedDateAcuracy: PropTypes.string,
      lineNumber: PropTypes.string,
      scanDate: PropTypes.string,
      legislationType: PropTypes.string,
      title: PropTypes.string
    }).isRequired,
    saveEditing: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
  };

  state = {
    useCustomTitle: this.props.law.customTitle !== "",
    adoptedDate: this.props.law.adopted || "",
    customTitle: (this.props.law.customTitle !== "") ? this.props.law.customTitle : this.props.law.description || "",
    comment: this.props.law.comment || "",
    rank: this.props.law.rank || "",
    subject: this.props.law.subject || "",
    deleted: this.props.law.deleted === 'true',
    description: this.props.law.description || "",
    documentId: this.props.law.documentId || "",
    codeId: this.props.law.appendedTo.id || "",
    suppedBy: (this.props.law.suppedBy) ? this.props.law.suppedBy.id || this.NOT_SUPPED : this.NOT_SUPPED,
    legislationYear: this.props.law.legislationYear || "",
    adoptedDateAccuracy: this.props.law.adoptedDateAccuracy || "D",
    lineNumber: this.props.law.lineNumber,
    scanDate: this.props.law.scanDate,
    legislationType: this.props.law.legislationType,
    titleError: false,
  };

  onTitleChange = event => {
    this.setState({customTitle: event.target.value, titleError: false});
  };

  onCommentChange = event => {
    this.setState({comment: event.target.value});
  };

  onSubjectChange = event => {
    this.setState({subject: event.target.value});
  };

  onDocumentIdChange = event => {
    this.setState({documentId: event.target.value});
  };

  onRankChange = event => {
    this.setState({rank: event.target.value});
  };

  onAdoptedDateChange = event => {
    this.setState({adoptedDate: event.target.value});
  };

  onAdoptedDateAccuracyChange = event => {
    this.setState({adoptedDateAccuracy: event.target.value});
  };

  onLineNumberChange = event => {
    this.setState({lineNumber: event.target.value});
  };

  onScanDateChange = event => {
    this.setState({scanDate: event.target.value});
  };

  onCodeIdChange = event => {
    this.setState({codeId: event.target.value});
  };

  onSuppedByChange = event => {
    this.setState({suppedBy: event.target.value});
  };

  onLegislationYearChange = event => {
    this.setState({legislationYear: event.target.value});
  };

  onUseCustomTitleChange = event => {
    this.setState({useCustomTitle: event.target.checked, titleError: false});
  };

  onLawDeletedChange = event => {
    this.setState({deleted: event.target.checked});
  };

  onDescriptionChange = event => {
    this.setState({description: event.target.value});
  };

  validateAndSave = () => {
    const {useCustomTitle} = this.state;
    const {saveEditing, law} = this.props;
    let updatedLaw = {...this.state};
    if (useCustomTitle && updatedLaw.customTitle.trim()) {
      updatedLaw.customTitle = updatedLaw.customTitle.trim();
    } else {
      updatedLaw.customTitle = "";
    }
    updatedLaw.comment = updatedLaw.comment.trim();
    saveEditing(law.documentId,  updatedLaw);
  };

  render() {
    const {hasLawDeletedViewPermission, hasLawManagePermission, law, cancelEditing, isSaving} = this.props;
    const {originalFilename = ""} = law;
    const {useCustomTitle, customTitle, deleted, description, titleError, comment, rank, adoptedDate, adoptedDateAccuracy, documentId, subject, codeId, suppedBy, legislationYear, lineNumber, scanDate, legislationType} = this.state;
    return (
      <Dialog
        title="Customize new law display"
        contextId="laws#manage"
        open={law !== null}
        onCancel={cancelEditing}
        onSubmit={this.validateAndSave}
        submitDisabled={(useCustomTitle && !customTitle) || isSaving}
        classes={{paper: "editLawDialogPaper"}}
        footer={<AuditLine entity={law}/>}
        processing={isSaving}
        width="md"
      >
        <div className="formSection">
          <FormLabel>Title</FormLabel>
          <Typography variant="subtitle1" className={useCustomTitle ? "textDisabled" : ""}>{useCustomTitle ? customTitle : this.props.law.description}</Typography>
          <FormControlLabel
            disabled={isSaving}
            control={
              <Checkbox
                disabled={isSaving}
                checked={useCustomTitle}
                onChange={this.onUseCustomTitleChange}
                style={{height: 'auto'}}
              />
            }
            label="Set a custom title"
            className="useCustomTitleLabel"
          />
          <TextField
            value={customTitle}
            onChange={this.onTitleChange}
            fullWidth={true}
            className="editLawDialogField"
            required={true}
            disabled={!useCustomTitle || isSaving}
            error={titleError}
            helperText={titleError ? "Please enter a title or uncheck the custom title option" : null}
          />
        </div>
        <div className="formSection">
          <TextField
            label="Comment"
            value={comment}
            onChange={this.onCommentChange}
            fullWidth={true}
            className="editLawDialogField"
            disabled={isSaving}
            multiline={true}
            rowsMax={4}
          />
        </div>
        <If test={hasLawManagePermission}>
          <div className="formSection">
            <TextField
              label="Adopted Date"
              value={adoptedDate}
              onChange={this.onAdoptedDateChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Scan Date"
              value={scanDate}
              onChange={this.onScanDateChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Document Id"
              value={documentId}
              onChange={this.onDocumentIdChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <Select label="Appended Code Version" disabled={isSaving} value={codeId} onChange={this.onCodeIdChange} className="editLawDialogField">
              {window.SERVER_DATA.versions.map((version) => (
                <MenuItem key={version.id} value={version.id}>
                  {!!version.displayDate ?  version.displayDate : "Uploaded on: " + moment(version.updatedDate).format("MM-DD-YYYY") + "*"}
                </MenuItem>
              ))}
            </Select>
            <Select label="Supped Code Version" disabled={isSaving} value={suppedBy} onChange={this.onSuppedByChange} className="editLawDialogField">
              <MenuItem key="ns" value={this.NOT_SUPPED}>{this.NOT_SUPPED}</MenuItem>
              {window.SERVER_DATA.versions.map((version) => (
                <MenuItem key={version.id} value={version.id}>
                  {!!version.displayDate ?  version.displayDate : "Uploaded on: " + moment(version.updatedDate).format("MM-DD-YYYY") + "*"}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Subject"
              value={subject}
              onChange={this.onSubjectChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Adopted Date Accuracy"
              value={adoptedDateAccuracy}
              onChange={this.onAdoptedDateAccuracyChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Legislation Type"
              value={legislationType}
              onChange={this.onCommentChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Legislation Year"
              value={legislationYear}
              onChange={this.onLegislationYearChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Line Number"
              value={lineNumber}
              onChange={this.onLineNumberChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Rank"
              value={rank}
              onChange={this.onRankChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <TextField
              label="Description"
              value={description}
              onChange={this.onDescriptionChange}
              fullWidth={true}
              className="editLawDialogField"
              multiline={false}
              disabled={isSaving}
              rowsMax={1}
            />
            <FormLabel>Information</FormLabel>
            <If test={originalFilename !== ""}>
              <Typography variant="subtitle1">Original Filename - {originalFilename}</Typography>
            </If>
          </div>
          <If test={hasLawDeletedViewPermission}>
            <FormControlLabel
              disabled={isSaving}
              control={
                <Checkbox
                  checked={deleted}
                  onChange={this.onLawDeletedChange}
                  style={{height: 'auto'}}
                />
              }
              label="Law Deleted"
              className="useCustomTitleLabel"
            />
          </If>
        </If>
        <If test={!hasLawManagePermission}>
          <If test={description !== "" || originalFilename !== ""}>
            <div className="formSection">
              <FormLabel>Information</FormLabel>
              <If test={description !== ""}>
                <Typography variant="subtitle1">Designation - {description}</Typography>
              </If>
              <If test={originalFilename !== ""}>
                <Typography variant="subtitle1">Original Filename - {originalFilename}</Typography>
              </If>
            </div>
          </If>
        </If>
      </Dialog>
    );
  }
}

export default EditLawDialog;
