import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {toJS} from "../../common/utils";
import ParkingCalculator from "../components/parking/ParkingCalculator";

const mapStateToProps = createStructuredSelector({
});
const mapDispatchToProps = (dispatch) => ({
});

const ParkingContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ParkingCalculator);
export default ParkingContainer;