import React from "react";
import PageTitleContainer from "../../common/containers/PageTitleContainer";
import * as PageSubTypes from "../../common/utils/page-sub-types";
import {capitalize} from "lodash";

const CalculatorPageTitle = (props) => {
  let newTitle = capitalize(props.pageSubType.toLowerCase()) + " Calculator";
  if (props.pageSubType === PageSubTypes.BUFFERYARD) {
    newTitle = ((props.bufferyardType) ? props.bufferyardType + " " : "") + newTitle;
  }
  if (props.pageSubType === PageSubTypes.PARKING) {
    newTitle = ((props.parkingType) ? props.parkingType + " " : "") + newTitle;
  }
  if (props.pageSubType === PageSubTypes.SIGNAGE) {
    newTitle = ((props.signageClass) ? props.signageClass + " " : "") + newTitle;
  }
  return (<PageTitleContainer pageTitle={newTitle}/>);
};

export default CalculatorPageTitle;

