import * as PropTypes from "prop-types";
import React from "react";
import IndexPageWord from "./IndexPageWord";

const IndexPage = (props) => {

  const { words } = props;

  return (
    <div>
      {words.map((word) => (
        <IndexPageWord
          key={word.word}
          word={word}
        />
      ))}
    </div>
  );
};
IndexPage.propTypes = {
  words: PropTypes.array.isRequired
};
export default IndexPage;
