import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import CalculatorPageTitle from "../components/CalculatorPageTitle";
import {getPageSubType} from "../../common/selectors";
import {getBufferyardType, getParkingType, getSignageClass} from "../selectors";

const mapStateToProps = createStructuredSelector({
  bufferyardType: getBufferyardType,
  parkingType: getParkingType,
  signageClass: getSignageClass,
  pageSubType: getPageSubType
});

const mapDispatchToProps = (dispatch) => ({
});

const CalulatorPageTitleContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(CalculatorPageTitle);
export default CalulatorPageTitleContainer;