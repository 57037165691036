import React from 'react';
import {Button, TextField} from "../../../common/components";
import PropTypes from "prop-types";

const BufferyardParkingForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <TextField
        id="parkingSpaces"
        name="parkingSpaces"
        label="Number of Parking Spaces"
        type="number"
        value={props.parkingSpaces}
        onChange={props.handleChange}
        placeholder="Number of Parking Spaces"
        inputProps={{ min: 0}}
        fullWidth
      />
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardParkingForm;
BufferyardParkingForm.propTypes = {
  clearForm: PropTypes.func,
  parkingSpaces: PropTypes.number,
  handleChange: PropTypes.func,
};