import {createTheme} from '@material-ui/core';

let customerColors = {};
let defaultColorName = "teal";
const g = window || global || {};
if (g.SERVER_DATA?.colors) {
  customerColors = {
    accent: `#${g.SERVER_DATA.colors.accentColor}`,
    title: `#${g.SERVER_DATA.colors.accentTextColor}`,
    banner: `#${g.SERVER_DATA.colors.bannerBackgroundColor}`,
  };
  /* the default color is set by the first of these that has a value: accent, text,banner.  If none have a value it is teal */
  if (customerColors.accent !== '#') {
    defaultColorName = "accent";
  } else if (customerColors.text !== '#') {
    defaultColorName = "text";
  } else if (customerColors.banner !== '#') {
    defaultColorName = "banner";
  }
}
//https://material.io/color/#!/?view.left=1&view.right=1&primary.color=095c66&secondary.color=333333

const teal = '#095c66';
export const palette = {
  primary: {
    main: '#454545',
    light: '#707070',
    dark: '#1e1e1e',
    contrastText: '#ffffff',
  },
  //yes this is the same as primary.  We need to figure out colors better
  secondary: {
    main: '#454545',
    light: '#707070',
    dark: '#1e1e1e',
    contrastText: '#ffffff',
  },
  /* GC Teal TBD where we use it
    secondary: {
    main: '#095c66',
    light: '#458994',
    dark: '#00323c',
    contrastText: '#ffffff',
  },*/
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  site: {
    teal: teal,
    gray: '#333',
    mint: '#01a6b9',
    berry: '#620055',
    pineapple: '#ccc000',
    roc: '#404040',
    lightGray: '#999',
    default: customerColors.accent || customerColors.text || customerColors.banner || teal,
  },
  customer: customerColors,
};

const overrides = {
  MuiPaper: {
    root: {
      backgroundColor: '#fafafa',
    }
  },
  MuiButton: {
    root: {
      textTransform: 'initial',
    },
  },
};

const typography = {
  fontFamily: '"freight-sans-pro", sans-serif',
  fontSize: 16,
  useNextVariants: true,
};

const breakpoints = {
  values: {
    xl: 1240,
    lg: 996,
    md: 760,
    sm: 360,
    xs: 0,
  }
};


export default createTheme({breakpoints, palette, typography, overrides});
export {customerColors, defaultColorName};
