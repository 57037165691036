import React, {forwardRef, useState, useEffect, useCallback, useImperativeHandle} from "react";
import {If} from "../../containers";
import PageControl from "./PageControl";
import PageControlContent from "./PageControlContent";

let scrollTimer = null;
let locationChanged = true;
let scrollCallback = function () {
  if (locationChanged) {
    const selectedNotePageControl = document.querySelector(".toc-node.selected");
    if (selectedNotePageControl !== null) {
      selectedNotePageControl.scrollIntoView({block: "center", inline: "start"});
    }
  }
  // Reset the scroll timer and the location change state after the callback is executed
  scrollTimer = null;
  locationChanged = false;
};

const TocPageControl = forwardRef(({id, type, label, tocNodes}, ref) => {

  const [typePrefix, setTypePrefix] = useState("");
  const [tocId, setTocId] = useState("toc-widget");
  const [pageHeadingIds, setPageHeadingIds] = useState([]);
  const [pageHeadingMap, setPageHeadingMap] = useState({});
  const [selectedTocNodeId, setSelectedTocNodeId] = useState(null);
  const [selectedPageTitleId, setSelectedPageTitleId] = useState(null);
  const [defaultSelectedId, setDefaultSelectedId] = useState(null);

  const populateIds = useCallback((typePrefix, tocNode, tocNodeIds, pageHeadingIds, pageHeadingMap, defaultSelectedId) => {
    const tocNodeId = `${typePrefix}toc-node-${tocNode.id}`;
    if (tocNode.selected) {
      defaultSelectedId = tocNodeId;
    }
    tocNodeIds.push(tocNodeId);
    if (tocNode.pageHeadingId) {
      pageHeadingIds.push(tocNode.pageHeadingId);
      pageHeadingMap[tocNode.pageHeadingId] = tocNodeId;
    }
    if (tocNode.childNodes?.length > 0) {
      for (const childNode of tocNode.childNodes) {
        defaultSelectedId = populateIds(typePrefix, childNode, tocNodeIds, pageHeadingIds, pageHeadingMap, defaultSelectedId);
      }
    }
    return defaultSelectedId;
  }, []);

  const onScroll = useCallback(() => {

    // Begin the scroll timer, which is used to determine when the scroll finishes
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    scrollTimer = setTimeout(scrollCallback, 200);

    let contentOffset = document.getElementById("page-content").offsetTop;
    for (let contentOffsetElement of document.getElementsByClassName("page-content-offset")) {
      contentOffset += contentOffsetElement.getBoundingClientRect().height;
    }

    let selectedTocNodeId = null;
    let selectedPageTitleId = null;
    for (let i = (pageHeadingIds.length - 1); i > -1; i--) {
      const pageHeadingId = pageHeadingIds[i];
      const pageHeadingElement = document.getElementById(pageHeadingId);
      // If offsetParent is null, then the element isn't visible on the page
      if (pageHeadingElement?.offsetParent) {
        const tocNodeId = pageHeadingMap[pageHeadingId];
        const tocNodeElement = document.getElementById(tocNodeId);
        if (tocNodeElement) {
          if ((pageHeadingElement.getBoundingClientRect().top - 8) <= contentOffset) {
            // If the heading is in view, add the selected class if needed
            if (!tocNodeElement.classList.contains("selected")) {
              locationChanged = true;
            }
            selectedTocNodeId = tocNodeId;
            selectedPageTitleId = pageHeadingElement.getAttribute("id");
            break;
          }
        }
      }
    }
    setSelectedTocNodeId(selectedTocNodeId);
    setSelectedPageTitleId(selectedPageTitleId);
  }, [pageHeadingIds, pageHeadingMap]);

  useEffect(() => {
    const typePrefix = (type ? (type + "-") : "");
    let tocNodeIds = [];
    let pageHeadingIds = [];
    let pageHeadingMap = {};
    let defaultSelectedId = null;
    if (tocNodes) {
      for (const tocNode of tocNodes) {
        defaultSelectedId = populateIds(typePrefix, tocNode, tocNodeIds, pageHeadingIds, pageHeadingMap, defaultSelectedId);
      }
    }
    setTypePrefix(typePrefix);
    setTocId(id ? id : (typePrefix + "toc-widget"));
    setPageHeadingIds(pageHeadingIds);
    setPageHeadingMap(pageHeadingMap);
    setSelectedTocNodeId(null);
    setSelectedPageTitleId(null);
    setDefaultSelectedId(defaultSelectedId);
  }, [tocNodes, id, type, populateIds]);

  useEffect(() => {
    setTimeout(onScroll, 1250);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  // Function call by ResponsivePageControls when the page controls are expanded
  useImperativeHandle(ref, () => ({
    onContentSizeChange: () => {
      const selectedPageTitle = document.getElementById(selectedPageTitleId);
      if (selectedPageTitle !== null) {
        selectedPageTitle.scrollIntoView();
        setTimeout(onScroll, 100);
      }
    }
  }));

  const renderTocNode = (tocNode, depth = 1) => {
    const nodeId = `${typePrefix}toc-node-${tocNode.id}`;
    return (
      <li key={tocNode.id} id={nodeId} className={"toc-node depth-" + (tocNode.depthOverride != null ? tocNode.depthOverride : depth) + ((nodeId === selectedTocNodeId || (!selectedTocNodeId && (nodeId === defaultSelectedId))) ? " selected" : "") + (tocNode.className ? (" " + tocNode.className) : "")}>
        <a className="link" href={tocNode.href}>
          <If test={tocNode.labelPrefix}><span className="label-prefix">{tocNode.labelPrefix}</span> </If><span>{tocNode.label}</span>
        </a>
        <If test={tocNode.childNodes?.length > 0}>
          <ul>
            {tocNode.childNodes?.map(childNode => renderTocNode(childNode, (depth + 1)))}
          </ul>
        </If>
      </li>
    );
  };

  return (
    <If test={tocNodes.length > 0}>
      <PageControl id={tocId} className="toc-widget" scrollable>
        <PageControlContent>
          <ul>
            <If test={label}>
              <li className="toc-node depth-0 widget-label">
                <span>{label}</span>
              </li>
            </If>
            {tocNodes.map(tocNode => renderTocNode(tocNode))}
          </ul>
        </PageControlContent>
      </PageControl>
    </If>
  );
});
export {TocPageControl};
export default TocPageControl;