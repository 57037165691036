import React, {useState, useEffect, useCallback} from "react";
import {connect} from "react-redux";

import If from "../containers/If";
import {getContentPreviewDelay, getContentPreviewSelector, hasContentPreviewPermission} from "../selectors";

const ContentPreview = ({contentPreviewDelay, contentPreviewSelector, hasContentPreviewPermission}) => {

  const [displayed, setDisplayed] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [contentUrl, setContentUrl] = useState("");
  const [isPdfLink, setIsPdfLink] = useState(false);
  const [topPosition, setTopPosition] = useState(true);
  const [leftPosition, setLeftPosition] = useState(true);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const onMouseMove = useCallback((e) => {
    if (hasContentPreviewPermission) {
      let x = e.clientX;
      let y = e.clientY;
      document.documentElement.style.setProperty("--mouse-x", `${x}px`);
      document.documentElement.style.setProperty("--mouse-y", `${y}px`);
      setTopPosition(y >= (window.innerHeight / 2));
      setLeftPosition(x >= (window.innerWidth / 2));

      let newHoveredElement = e.target;
      let targetLink = newHoveredElement.closest(contentPreviewSelector);
      if (targetLink && targetLink.ownerDocument === document) {
        newHoveredElement = targetLink;
        if (newHoveredElement !== hoveredElement) {
          let url = new URL(window.location.href);
          url.search = "";
          if (targetLink.getAttribute("href")) {
            let linkHref = targetLink.getAttribute("href");
            if (linkHref.startsWith("http")) {
              url = new URL(linkHref);
            } else {
              let newUrl = new URL(url.origin + (linkHref.startsWith("/") ? "" : "/") + linkHref);
              if (!linkHref.startsWith("#")) {
                url.pathname = newUrl.pathname;
              }
              url.hash = newUrl.hash;
            }
          } else {
            let path = url.pathname.substring(1);
            let pathPrefix = "";
            if (path.indexOf("/") !== -1) {
              pathPrefix = path.substring(0, path.indexOf("/"));
            }
            let guid = targetLink.getAttribute("guid") || targetLink.dataset.guid;
            url.pathname = pathPrefix + guid;
            url.hash = guid;
          }
          url.searchParams.set("content-only", "true");

          if (url.href !== contentUrl) {
            setIsPdfLink(url.pathname.endsWith(".pdf"));
            setContentUrl(url.href);
            if (hoverTimeout) {
              clearTimeout(hoverTimeout);
              setDisplayed(false);
            }
            setHoverTimeout(setTimeout(function () {
              setDisplayed(true);
            }, contentPreviewDelay));
          }
          setHoveredElement(newHoveredElement);
        }
      } else {
        setIsPdfLink(false);
        setContentUrl("");
        setDisplayed(false);
        setHoveredElement(null);
        if (hoverTimeout) {
          clearTimeout(hoverTimeout);
          setHoverTimeout(null);
        }
      }
    }
  }, [contentUrl, hoverTimeout, contentPreviewDelay, contentPreviewSelector, hasContentPreviewPermission, hoveredElement]);

  useEffect(() => {
    document.documentElement.addEventListener("mousemove", onMouseMove);
    return () => {
      document.documentElement.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove]);

  return (
    <div id="content-preview" className={`${topPosition ? "top" : "bottom"} ${leftPosition ? "left" : "right"}`} style={{display: (displayed ? "block" : "none")}}>
      <If test={isPdfLink}>
        <object data={contentUrl} type="application/pdf">
          <div>No online PDF viewer installed</div>
        </object>
      </If>
      <If test={!isPdfLink}>
        <iframe src={contentUrl} title={contentUrl} allowtransparency="true" frameBorder="0" scrolling="no"></iframe>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contentPreviewDelay: getContentPreviewDelay(state),
  contentPreviewSelector: getContentPreviewSelector(state),
  hasContentPreviewPermission: hasContentPreviewPermission(state)
});

export default connect(mapStateToProps)(ContentPreview);