import React from 'react';
import BufferyardDistrictSelection from "./BufferyardDistrictSelection";
import {Button, TextField} from "../../../common/components";
import BufferyardStreetSelection from "./BufferyardStreetSelection";
import PropTypes from "prop-types";
import {STREET} from "./BufferyardTypes";

const BufferyardStreetForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <BufferyardDistrictSelection
        label="Zoning of Parcel Proposed for Development"
        bufferyardType={STREET}
        currentValue={props.developmentDistrict}
        onChange={props.handleChange}
        listName="developmentDistrict"
      />
      <BufferyardStreetSelection
        label="Abutting Street Classification"
        currentValue={props.abuttingStreetClass}
        onChange={props.handleChange}
        listName="abuttingStreet"
      />
      <TextField
        id="propertyLength"
        name="propertyLength"
        label="Length of Property Line (linear feet)"
        type="number"
        value={props.propertyLength}
        onChange={props.handleChange}
        placeholder="Linear Feet"
        inputProps={{ min: 0}}
        fullWidth
      />
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardStreetForm;
BufferyardStreetForm.propTypes = {
  abuttingStreetClass: PropTypes.string,
  clearForm: PropTypes.func,
  developmentDistrict: PropTypes.string,
  propertyLength: PropTypes.number,
  handleChange: PropTypes.func,
};