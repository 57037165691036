import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";

const SignageTypeSelection = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      label={props.label}
      id={props.listName}
      value={props.currentValue}
      onChange={handleChange}
    >
      {props.signageTypes.map((useType) => (
        <MenuItem key={useType} value={useType}>
          {useType}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SignageTypeSelection;
SignageTypeSelection.propTypes = {
  currentValue: PropTypes.string,
  label: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
  signageTypes: PropTypes.array
};