import {List, Map, Record} from "immutable";
import defaultTo from "lodash/defaultTo";
import {handleActions} from "redux-actions";
import * as actions from "../actions";
import * as referencesActions from "../actions/references";
import * as searchActions from "../../search/actions";
import * as notesActions from "../../notes/actions";
import {getReferenceGroupNames} from "../ReferenceGroupFactory";

const Customization = Record({
  accentBorderColor: null,
  accentColor: null,
  accentHighlightColor: null,
  accentLineColor: null,
  accentTextColor: null,
  adminX: 0,
  adminY: 0,
  banner: false,
  bannerBackgroundColor: null,
  calculatorsEnabled: false,
  cname: null,
  seal: false,
  website: null,
  mapUrl: null,
  websiteHeight: 0,
  websiteOffsetX: 0,
  websiteOffsetY: 0,
  websiteWidth: 0,
  showCountyLine: false,
  lawTitleField: null,
  translate: false,
  ia2: false,
  codeReviewPhase: null,
  created: null,
  updated: null,
  bannerBackgroundColorLight: false,
  customerName: "",
  pubdocsDateDisabled: false,
  trackbackEnabled: false,
  sidebarLabel: "",
  pubDocsOnlyText: "",
  allowRemoteAuth: false,
  codifiedLawViewEnabled: false,
  ia2ImportEnabled: false,
  mixedContentEnabled: false,
  shallowSchemeEnabled: false,
  homeLongName: "",
  homeShortName: "",
  codeLongName: "",
  codeShortName: "",
  zoningLongName: "",
  zoningShortName: "",
  lawsLongName: "",
  lawsShortName: "",
  notesLongName: "",
  notesShortName: "",
  pubdocsLongName: "",
  pubdocsShortName: "",
  questionsLongName: "",
  questionsShortName: "",
  adminLongName: "",
  adminShortName: "",
  helpLongName: "",
  helpShortName: "",
  zoningMapName: "",
  zoningChapterName: "",
  newLawsName: "",
  lawLedgerName: "",
  codeGraphicalLinkEnabled: false,
  lawsGraphicalLinkEnabled: false,
  codifiedLawsGraphicalLinkEnabled: false,
  helpGraphicalLinkEnabled: false,
  muniAddress: "",
  muniContactPerson: "",
  muniEmail: "",
  muniPhone: "",
  muniUrl: "",
  homePageMessage: "",
  newNavEnabled: false,
  analysisName: "",
});

const Customer = Record({
  id: null,
  county: null,
  custId: null,
  customization: null,
  featureLevel: null,
  govtype: null,
  municipality: null,
  name: null,
  population: null,
  solo: false,
  state: null,
  warning: null,
  zipcode: null,
  subdivisionName: null,
  subdivisionNamePlural: null
});

const DialogProps = Record({
  id: null,
  onCancel: null,
  onSubmit: null,
  onClose: null,
  width: null,
  maxWidth: null,
  fullWidth: null,
  open: true,
  title: "",
  contextId: "",
  actions: null,
  content: null,
  cancelLabel: "Cancel",
  submitLabel: "Ok",
  submitDisabled: false,
  DialogTitleProps: {},
  DialogActionsProps: {},
  DialogContentProps: {}
});

const ReferenceNode = Record({
  type: null,
  key: null,
  title: null,
  context: null,
  name: null,
  index: null,
  children: List([]),
  active: false,
  toggled: false,
  loading: false,
});

const ReferenceMatch = Record({
  type: null,
  key: null,
  title: null,
  context: null,
});

const ReferenceGroup = Record({
  matches: List(),
  areMatchesLoading: false,
  nodes: List(),
  activeNodeIndex: null,
});
const referenceGroups = {};
getReferenceGroupNames().forEach((name) => referenceGroups[name] = ReferenceGroup());

export const mediaWidths = {
  small: 1,
  medium: 2,
  large: 3,
  wide: 4
};

export const mediaHeights = {
  short: 1,
  medium: 2,
  large: 3,
  tall: 4
};

const State = Record({
  errorTitles: List(),
  errorMessages: List(),
  errorRedirectUrl: null,
  username: null,
  archiveId: null,
  custId: null,
  customerName: null,
  customer: null,
  bodyTop: 0,
  searchSuggestionWaitTime: 0,
  isDisplayDateAdmin: false,
  displayDate: null,
  analysisId: null,
  questions: Map(),
  reviewers: Map(),
  dueWarningDays: 0,
  dueWarningIntervalHours: 24,
  displayedQuestionIds: null,
  needsReviewFilter: null,
  assignedFilter: null,
  assignedToFilter: null,
  deferrableFilter: null,
  statusFilter: null,
  filterText: "",
  deferFilter: null,
  ia2FileTypes: null,
  pubDocsFileTypes: null,
  termsOfUseUrl: null,
  isPrintLoading: false,
  helpTopics: Map(),
  helpVideos: Map(),
  grantedPermissions: List(),
  showDiscussionText: "",
  hideDiscussionText: "",
  multicodeStates: List(),
  referenceGroups: Map(referenceGroups),
  dialogs: Map(),
  mediaHeight: mediaHeights.tall,
  mediaWidth: mediaWidths.wide,
  pageFilters: List(),
  userMessageTitle: null,
  userMessageText: null,
});

export const initialState = State({});

const loadServerData = (state, {
  payload: {
    username,
    archiveId,
    custId,
    customerName,
    customer,
    searchSuggestionWaitTime,
    analysisId,
    questions,
    reviewers,
    dueWarningDays,
    dueWarningIntervalHours,
    displayedQuestionIds,
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText,
    deferFilter,
    ia2FileTypes,
    pubDocsFileTypes,
    termsOfUseUrl,
    helpTopics,
    helpVideos,
    grantedPermissions,
    showDiscussionText,
    hideDiscussionText,
    multicodeStates
  }
}) => state
  .set('username', username)
  .set('custId', custId)
  .set('customerName', customerName)
  .set('customer', customer ? Customer({...customer, customization: customer.customization ? Customization(customer.customization) : null}) : null)
  .set('archiveId', archiveId)
  .set('searchSuggestionWaitTime', searchSuggestionWaitTime)
  .set('analysisId', analysisId)
  .set('questions', Map(questions))
  .set('reviewers', Map(reviewers))
  .set('dueWarningDays', dueWarningDays)
  .set('dueWarningIntervalHours', dueWarningIntervalHours)
  .set('displayedQuestionIds', (displayedQuestionIds ? List(displayedQuestionIds) : null))
  .set('needsReviewFilter', needsReviewFilter)
  .set('assignedFilter', assignedFilter)
  .set('assignedToFilter', assignedToFilter)
  .set('deferrableFilter', deferrableFilter)
  .set('statusFilter', statusFilter)
  .set('filterText', defaultTo(filterText, ""))
  .set('deferFilter', deferFilter)
  .set('ia2FileTypes', ia2FileTypes)
  .set('pubDocsFileTypes', pubDocsFileTypes)
  .set('termsOfUseUrl', termsOfUseUrl)
  .set('helpTopics', helpTopics)
  .set('helpVideos', helpVideos)
  .set("showDiscussionText", showDiscussionText)
  .set("hideDiscussionText", hideDiscussionText)
  .set('grantedPermissions', grantedPermissions)
  .set('multicodeStates', multicodeStates ? List(multicodeStates) : List())
  .set('referenceGroups', Map(referenceGroups));

const registerError = (state, {payload: {error}}) => {
  let newState = state.set('errorRedirectUrl', error.redirectUrl)
    .update('errorMessages', errorMessages => errorMessages.push(error.message));
  if (error.title) {
    newState = newState.update('errorTitles', errorTitles => errorTitles.push(error.title));
  }
  return newState;
};
const dismissErrors = (state) => state
  .set('errorTitles', List())
  .set('errorMessages', List()).set('errorRedirectUrl', null);
const setBodyTop = (state, {payload: {bodyTop}}) => state.set('bodyTop', bodyTop);
const setPrintLoading = (state, {payload: {isLoading}}) => state.set('isPrintLoading', isLoading);

const initDialog = (state, {payload: {id, dialogProps}}) =>
  state.setIn(['dialogs', id], DialogProps({id: id, ...dialogProps}));
const closeDialog = (state, {payload: {id}}) =>
  state.updateIn(['dialogs', id], dialogProps => dialogProps.set('open', false));
const disableDialogSubmit = (state, {payload: {id}}) =>
  state.updateIn(['dialogs', id], dialogProps => dialogProps.set('submitDisabled', true));
const changeMediaHeight = (state, {payload: {height}}) => state.set('mediaHeight', mediaHeights[height]);
const changeMediaWidth = (state, {payload: {width}}) => state.set('mediaWidth', mediaWidths[width]);

const addPageFilterToState = (state, filterId) => {
  if (!filterId) return state;
  return state.pageFilters.indexOf(filterId) !== -1 ? state
    : state.update('pageFilters', (pageFilters) => pageFilters.push(filterId));
};
const addPageFilter = (state, {payload: {filterId}}) => addPageFilterToState(state, filterId);
const addPageFilters = (state, {payload: {addedSelections}}) => {
  let newState = state;
  if (addedSelections) {
    for (const filterId of addedSelections) {
      newState = addPageFilterToState(newState, filterId);
    }
  }
  return newState;
};
const removePageFilterFromState = (state, filterId) => {
  if (!filterId) return state;
  const index = state.pageFilters.indexOf(filterId);
  return (index !== -1) ? state.update('pageFilters', (pageFilters) => pageFilters.splice(index, 1)) : state;
};
const removePageFilter = (state, {payload: {filterId}}) => removePageFilterFromState(state, filterId);
const removePageFilters = (state, {payload: {removedSelections}}) => {
  let newState = state;
  if (removedSelections) {
    for (const filterId of removedSelections) {
      newState = removePageFilterFromState(newState, filterId);
    }
  }
  return newState;
};

const setPageFilters = (state, {payload: {filterIds, filterValues}}) => {
  let newState = state;
  for (const pageFilterId of filterIds) {
    if (filterValues[pageFilterId]) {
      newState = addPageFilterToState(newState, pageFilterId);
    } else {
      newState = removePageFilterFromState(newState, pageFilterId);
    }
  }
  return newState;
};

const getRefNodePath = (groupName, index) => {
  const indices = index.split('-');
  const depth = indices.length;
  for (let i = 1; i < depth; i++) {
    indices.splice((i * 2) - 1, 0, 'children');
  }
  return [groupName, 'nodes', ...indices];
};

const setReferenceNodes = (state, {payload: {groupName, nodes}}) => {
  return state.setIn(['referenceGroups', groupName, 'nodes'], List(nodes.map(ReferenceNode)));
};
const toggleReferenceNode = (state, {payload: {groupName, node, toggled}}) => {
  const {index, children} = node;
  const {activeNodeIndex} = state.getIn(['referenceGroups', groupName]);
  if (activeNodeIndex) {
    state = state.setIn(['referenceGroups', ...getRefNodePath(groupName, activeNodeIndex), 'active'], false);
  }
  return state
    .setIn(['referenceGroups', groupName, 'activeNodeIndex'], index)
    .updateIn(['referenceGroups', ...getRefNodePath(groupName, index)], (node) => node
      .set('toggled', toggled && children)
      .set('active', true)
      .set('loading', children && children.length === 0));
};

const setReferenceNodeChildren = (state, {payload: {groupName, node, children}}) => state
  .setIn(['referenceGroups', ...getRefNodePath(groupName, node.index), 'children'], List(children.map(ReferenceNode)))
  .setIn(['referenceGroups', ...getRefNodePath(groupName, node.index), 'loading'], false);

const removeActiveReferenceNode = (state, {payload: {groupName}}) => {
  const {activeNodeIndex} = state.getIn(['referenceGroups', groupName]);
  if (activeNodeIndex) {
    state = state.setIn(['referenceGroups', ...getRefNodePath(groupName, activeNodeIndex), 'active'], false);
  }
  return state.removeIn(['referenceGroups', groupName, 'activeNodeIndex']);
};

const removeReferenceNodes = (state, {payload: {groupName}}) => state
  .removeIn(['referenceGroups', groupName, 'nodes'])
  .removeIn(['referenceGroups', groupName, 'activeNodeIndex']);

const removeReferenceMatches = (state, {payload: {groupName}}) => state
  .removeIn(['referenceGroups', groupName, 'matches']);

const setReferenceMatches = (state, {payload: {groupName, matches}}) => state
  .setIn(['referenceGroups', groupName, 'matches'], List(matches.map(ReferenceMatch)));

const setReferenceMatchesLoading = (state, {payload: {groupName}}) => state
  .setIn(['referenceGroups', groupName, 'areMatchesLoading'], true);

const setReferenceMatchesDoneLoading = (state, {payload: {groupName}}) => state
  .setIn(['referenceGroups', groupName, 'areMatchesLoading'], false);

const showUserMessage = (state, {payload: {title, text}}) => state
  .set('userMessageTitle', title)
  .set('userMessageText', text);

const hideUserMessage = (state) => state
  .set('userMessageTitle', null)
  .set('userMessageText', null);

const reducer = handleActions({
  [actions.loadServerData]: loadServerData,
  [actions.registerError]: registerError,
  [actions.dismissErrors]: dismissErrors,
  [actions.setBodyTop]: setBodyTop,
  [actions.setPrintLoading]: setPrintLoading,
  [actions.addPageFilter]: addPageFilter,
  [searchActions.addFacetSelections]: addPageFilters,
  [notesActions.setNoteFilter]: addPageFilter,
  [actions.removePageFilter]: removePageFilter,
  [searchActions.removeFacetSelections]: removePageFilters,
  [notesActions.removeNoteFilter]: removePageFilter,
  [actions.setPageFilters]: setPageFilters,
  [actions.initDialog]: initDialog,
  [actions.closeDialog]: closeDialog,
  [actions.disableDialogSubmit]: disableDialogSubmit,
  [actions.matchMediaHeight]: changeMediaHeight,
  [actions.matchMediaWidth]: changeMediaWidth,
  [actions.showUserMessage]: showUserMessage,
  [actions.hideUserMessage]: hideUserMessage,

  [referencesActions.clearActiveReferenceNode]: removeActiveReferenceNode,
  [referencesActions.clearReferenceNodes]: removeReferenceNodes,
  [referencesActions.clearReferenceMatches]: removeReferenceMatches,

  [referencesActions.fetchRootReferenceNodesSuccess]: setReferenceNodes,
  [referencesActions.toggleReferenceNodeSuccess]: toggleReferenceNode,
  [referencesActions.fetchReferenceNodeChildrenSuccess]: setReferenceNodeChildren,
  [referencesActions.fetchReferenceMatchesStart]: setReferenceMatchesLoading,
  [referencesActions.fetchReferenceMatchesSuccess]: setReferenceMatches,
  [referencesActions.fetchReferenceMatchesFinally]: setReferenceMatchesDoneLoading,
}, initialState);

export default reducer;