const ParkingUseCategories = [
  'Agricultural Uses',
  'Automobile Uses',
  'Civic and Institutional Uses',
  'Commercial Uses',
  'Entertainment and Recreational Uses',
  'Industrial and Manufacturing Uses',
  'Nonresidential Accessory Uses',
  'Residential Uses',
  'Transportation, Utility, and Communication Uses',
  'Temporary Uses'
];

const ParkingUseTypes = {
  'Agricultural Uses': {
    'Community Garden': {},
    'Farm, Ranch, or Orchard': {},
    'Farming, Landscaping, and Horticultural Sales and Services': {
      bicycle_spaces: 0,
      loading_required: false,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Greenhouse / Nursery': {
      bicycle_spaces: 0,
      loading_required: false,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Riding Stables': {},
    'Other agricultural-related uses not specified': {
      bicycle_spaces: 0,
      loading_required: false,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Automobile Uses': {
    'Automobile and Vehicle Wash': {
      bicycle_spaces: 0,
      loading_required: false,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Automobile Structured Parking (Primary Use)': {},
    'Automobile Parking Lot (Primary Use)': {},
    'Automobile / Vehicle Parts and/or Accessories': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Automobile / Vehicle Sales and Rental': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Automobile / Vehicle Repair and Auto Body': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Automobile / Vehicle Service': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Travel Plaza': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 200, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Truck Parking Lot (Primary Use)': {},
    'Other automobile-related uses not specified': {
      bicycle_spaces: 0,
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Civic and Institutional Uses': {
    'Adult Care Center': {
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 200, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Cemetery / Funeral Services': {},
    'College or University': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 1, value_designator: 'numcrs', group: 1},
        {max: 0, base: 1, quanta: 4, value_designator: 'maxso', group: 1}
      ],
      form: [
        { value_designator: 'numcrs', label: 'Total number of classrooms, laboratories, and instruction areas', type: 'number'},
        { value_designator: 'maxso', label: 'Maximum student occupancy', type: 'number'}
      ]
    },
    'Correctional Institution': {
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Child Care, Day Care Center': {
      loading_required: true,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      note: 'This use must also provide an off-street drive, having separate ingress and egress, capable of the temporary storage of 3 or more vehicles',
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Club or Lodge': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 200, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Educational Services (excluding transportation-related instruction)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 400, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Educational Services (transportation-related instruction only)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Elementary Scool or Junior High School': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 1, value_designator: 'numcrs', group: 1},
        {max: 0, base: 1, quanta: 4, value_designator: 'seats', group: 1}
      ],
      form: [
        { value_designator: 'numcrs', label: 'Total number of classrooms, laboratories, and instruction areas', type: 'number'},
        { value_designator: 'seats', label: 'Maximum seats in any auditorium or gymnasium', type: 'number'}
      ]
    },
    'High School': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 1, value_designator: 'numcrs', group: 1},
        {max: 0, base: 1, quanta: 4, value_designator: 'maxso', group: 1}
      ],
      form: [
        { value_designator: 'numcrs', label: 'Total number of classrooms', type: 'number'},
        { value_designator: 'maxso', label: 'Maximum student occupancy', type: 'number'}
      ]
    },
    'Fire Station': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 100, value_designator: 'ps', group: 1},
        {max: 0, base: 4, quanta: 1, value_designator: 'vb', group: 1}
      ],
      form: [
        { value_designator: 'ps', label: 'Sq. ft. of public meeting area', type: 'number'},
        { value_designator: 'vb', label: 'Number of emergency vehicle bays', type: 'number'}
      ]
    },
    'Other Government Service (Police, Emergency Medical Service)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 800, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Hospital / Rehabilitative Care': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 2, value_designator: 'beds'}
      ],
      form: [
        { value_designator: 'beds', label: 'Number of beds', type: 'number'}
      ]
    },
    'Library, Museum, or Gallery': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Medical and Diagnostic Laboratories': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Medical Office / Clinic': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Place of Public Assembly, Indoor': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 200, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Religious Institution': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 4, value_designator: 'seats'}
      ],
      form: [
        { value_designator: 'seats', label: 'Number of seats in the main auditorium, chapel, or sanctuary', type: 'number'}
      ]
    },
    'Shelter': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 6, value_designator: 'beds'}
      ],
      form: [
        { value_designator: 'beds', label: 'Number of beds', type: 'number'}
      ]
    },
    'Other civic- and institutional-related uses not specified': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Commercial Uses': {
    'Bank, Credit Union, and Financial Services': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 600, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Brewpub, Microbrewery, Microdistillery, or Microwinery': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 175, value_designator: 'retail', group: 1},
        {max: 0, base: 1, quanta: 2000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'retail', label: 'Sq. ft. of the retail, tasting, or eating area', type: 'number'},
        { value_designator: 'storage', label: 'Sq. ft. of the manufacturing or storage area', type: 'number'}
      ]
    },
    'Building Materials and Hardware Sales': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 35000, base: 1, quanta: 400, value_designator: 'gfa', group: 1},
        {max: 0, base: 1, quanta: 600, value_designator: 'gfa', group: 1}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'},
      ]
    },
    'Fuel Sales': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 200, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Sq. Ft. retail floor area, excluding fueling positions', type: 'number'}
      ]
    },
    'Grocery (Food Sales)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 3500, base: 1, quanta: 500, value_designator: 'gfa', group: 1},
        {max: 35000, base: 1, quanta: 300, value_designator: 'gfa', group: 2},
        {max: 0, base: 1, quanta: 600, value_designator: 'gfa', group: 2}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'},
      ]
    },
    'Heavy Machinery Sales and Rentals': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Home Furnishing Store': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 35000, base: 1, quanta: 400, value_designator: 'gfa', group: 1},
        {max: 0, base: 1, quanta: 600, value_designator: 'gfa', group: 1}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'},
      ]
    },
    'Kennel': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Manufactured Home Sales and Storage': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Sales Office Sq. Ft.', type: 'number'}
      ]
    },
    'Hotel or Motel': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Number of guest rooms', type: 'number'}
      ]
    },
    'Office, General': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Pawn Shop': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Personal Service': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Recreational Vehicle Parks and Campgrounds': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 4, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Number of recreational vehicles or camping space', type: 'number'}
      ]
    },
    'Repair Service': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 400, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Restaurant': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 100, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Retail Sales': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 35000, base: 1, quanta: 300, value_designator: 'gfa', group: 1},
        {max: 0, base: 1, quanta: 600, value_designator: 'gfa', group: 1}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'},
      ]
    },
    'Self-storage, mini-warehouse': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'retail', group: 1},
        {max: 0, base: 1, quanta: 100, value_designator: 'units', min_result: 8, group: 1}
      ],
      form: [
        { value_designator: 'retail', label: 'Sq. ft. of office or retail', type: 'number'},
        { value_designator: 'units', label: 'Number of rental units', type: 'number'}
      ]
    },
    'Sexually-Oriented Business': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Studio (Arts, Crafts, or Recording)': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Veterinary Clinic and/or Service, Large Animal': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Veterinary Clinic and/or Service, Small Animal': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Other commercial-related uses not specified': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 500, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Entertainment and Recreational Uses': {
    'Alcoholic Beverages, Off-Site Consumption (Package Store)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Alcoholic Beverages, On-Site Consumption (Nightclub or Bar)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 100, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Automobile / Motorcycle Race Track': {},
    'Commercial Amusement, Indoor': {
      loading_required: true,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 250, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Commercial Amusement, Outdoor': {},
    'Outdoor Shooting / Archery Range': {},
    'Park & Recreation Facility or Center': {
      loading_required: false,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1, quanta: 400, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Outdoor Swimming Pool': {},
    'Other entertainment- and recreational-related uses not specified': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 250, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Industrial and Manufacturing Uses': {
    'Bakery, Wholesale': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Batch Plant, Permanent': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    "Contractor's Shop and/or Service Yard": {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Industrial and Manufacturing Product Sales and Supply': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Junkyard, Salvage Yard, and Wrecking Yard': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Manufacturing, Heavy (includes handling of explosive and/or foul materials)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 4000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Manufacturing, Light (includes product assembly and processing)': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Meat Packing and Related Industries': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Micromanufacturing': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Publishing Services': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Resource Extraction': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'office', group: 1},
        {max: 0, base: 1, quanta: 4000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'office', label: 'Gross Floor Area Sq. Ft. of Office', type: 'number'},
        { value_designator: 'storage', label: 'Gross Floor Area Sq. Ft. of Outdoor Storage', type: 'number'}
      ]
    },
    'Stockyard': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'office', group: 1},
        {max: 0, base: 1, quanta: 4000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'office', label: 'Gross Floor Area Sq. Ft. of Office', type: 'number'},
        { value_designator: 'storage', label: 'Gross Floor Area Sq. Ft. of Outdoor Storage', type: 'number'}
      ]
    },
    'Warehousing and Storage': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 4000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Other industrial- and manufacturing-related uses not specified': {
      loading_required: true,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Nonresidential Accessory Uses': {
    'Automated Teller Machine (ATM), Non-Freestanding or Vending Kiosk': {},
    'Billboard': {},
    'Donation Bin / Recycling Collection, Drop-Off': {},
    'Drive-In or Drive-Through Facility': {},
    'Helipad (Hospital/Public Safety)': {},
    'Outdoor Display of Merchandise': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Outdoor Storage': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 2000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    }
  },
  'Residential Uses': {
    'Single-Family Detached Dwelling': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Duplex': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Single-Family Garden Home or Cottage': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Townhouse': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Industrialized Housing': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Apartment (>4 du)': {
      loading_required: false,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1.0, quanta: 1, value_designator: 'd1'},
        {max: 0, base: 1.25, quanta: 1, value_designator: 'd2'},
        {max: 0, base: 2.0, quanta: 1, value_designator: 'd3'}
      ],
      form: [
        { value_designator: 'd1', label: 'Number of Dwelling Units with 1 bedroom', type: 'number'},
        { value_designator: 'd2', label: 'Number of Dwelling Units with 2 bedrooms', type: 'number'},
        { value_designator: 'd3', label: 'Number of Dwelling Units with 3+ bedrooms', type: 'number'}
      ]
    },
    'Boarding or Rooming House': {},
    'Dormitory': {},
    'Fraternity/Sorority House': {},
    'Group Home': {},
    'Live-Work Unit': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Manufactured Home Park or Manufactured Home Subdivision': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 2, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Multiplex (3 - 4 du)': {
      loading_required: false,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 1.25, quanta: 1, value_designator: 'd1'},
        {max: 0, base: 1.75, quanta: 1, value_designator: 'd2'},
        {max: 0, base: 2.25, quanta: 1, value_designator: 'd3'}
      ],
      form: [
        { value_designator: 'd1', label: 'Number of Dwelling Units with 1 bedroom', type: 'number'},
        { value_designator: 'd2', label: 'Number of Dwelling Units with 2 bedrooms', type: 'number'},
        { value_designator: 'd3', label: 'Number of Dwelling Units with 3+ bedrooms', type: 'number'}
      ]
    },
    'Retirement Housing': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 0.75, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Vertical Mixed-Use': {
      loading_required: false,
      bicycle_spaces: 2,
      thresholds: [
        {max: 0, base: 0.5, quanta: 1, value_designator: 'du'}
      ],
      form: [
        { value_designator: 'du', label: 'Number of Dwelling Units', type: 'number'}
      ]
    },
    'Accessory Dwelling Unit': {
      min_standard_spaces: 1,
      note: 'This use shall provide 1 space in addition to the spaces required for primary residential use',
    },
    'Accessory Structures': {},
    'Home Occupation': {},
    'Short-Term Rental': {
      min_standard_spaces: 1,
      note: 'This use shall provide 1 space in addition to the spaces required for primary residential use',
    }
  },
  'Transportation, Utility, and Communication Uses': {
    'Cargo Terminal': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'office', group: 1},
        {max: 0, base: 1, quanta: 4000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'office', label: 'Gross Floor Area Sq. Ft. of Office', type: 'number'},
        { value_designator: 'storage', label: 'Gross Floor Area Sq. Ft. of Outdoor Storage', type: 'number'}
      ]
    },
    'Landfill': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'office', group: 1},
        {max: 0, base: 1, quanta: 4000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'office', label: 'Gross Floor Area Sq. Ft. of Office', type: 'number'},
        { value_designator: 'storage', label: 'Gross Floor Area Sq. Ft. of Outdoor Storage', type: 'number'}
      ]
    },
    'Passenger Terminal': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Power Generation, Transmission, and Distribution (includes large solar collectors and windmills)': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 300, value_designator: 'office', group: 1},
        {max: 0, base: 1, quanta: 4000, value_designator: 'storage', group: 1}
      ],
      form: [
        { value_designator: 'office', label: 'Gross Floor Area Sq. Ft. of Office', type: 'number'},
        { value_designator: 'storage', label: 'Gross Floor Area Sq. Ft. of Outdoor Storage', type: 'number'}
      ]
    },
    'Utilities': {},
    'Water and Sewage Treatment': {
      loading_required: false,
      bicycle_spaces: 0,
      thresholds: [
        {max: 0, base: 1, quanta: 1000, value_designator: 'gfa'}
      ],
      form: [
        { value_designator: 'gfa', label: 'Gross Floor Area Sq. Ft.', type: 'number'}
      ]
    },
    'Water Storage': {},
    'Wireless Telecommunications Tower': {}
  },
  'Temporary Uses': {
    'All Temporary Uses': {
      note: 'Subject to Temporary Use Permit'
    }
  }
};
export {ParkingUseCategories, ParkingUseTypes};