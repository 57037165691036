import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";

import {setBufferyardType, setSignageClass} from "../actions";
import {getBufferyardType, getSignageClass} from "../selectors";
import {getCustId} from "../../common/selectors";
import CalculatorPageControls from "../components/CalculatorPageControls";

const mapStateToProps = createStructuredSelector({
  bufferyardType: getBufferyardType,
  custId: getCustId,
  signageClass: getSignageClass
});
const mapDispatchToProps = (dispatch, state) => ({
  setBufferyardType: (bufferyardType) => {
    dispatch(setBufferyardType(bufferyardType));
  },
  setSignageClass: (signageClass) => {
    dispatch(setSignageClass(signageClass));
  },
});

const CalculatorPageControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(CalculatorPageControls);
export default CalculatorPageControlsContainer;