import React, { useMemo } from "react";
import MuiTextField from '@material-ui/core/TextField';
import {IconButton, InputAdornment} from './';
import PropTypes from "prop-types";
import {Clear as ClearIcon} from "./icons";

const TextField = (props) => {
  const { size = "small", variant = "standard", margin = "dense", value, onClear = null,
    clearable = onClear != null, endAdornment = null, InputProps = {}, ...otherProps
  } = props;
  const { endAdornment: inputEndAdornment, ...otherInputProps } = InputProps;

  const clearButton = useMemo(() => clearable && onClear && value ? (
    <IconButton aria-label="Clear value" onClick={onClear}>
      <ClearIcon/>
    </IconButton>
  ) : null, [clearable, onClear, value]);
  return (
    <MuiTextField
      size={size}
      variant={variant}
      margin={margin}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end">{clearButton} {inputEndAdornment} {endAdornment}</InputAdornment>,
        ...otherInputProps
      }}
      {...otherProps}
    />
  );
};
TextField.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.any,
  clearable: PropTypes.bool,
  onClear: PropTypes.func
};
export default TextField;