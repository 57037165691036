import React from 'react';
import {TableBody, TableCell, TableRow} from "../../../common/components";

const ParkingResultTableBody = ({result}) => {
  return(
    <TableBody>
      {!result.parkingRequired &&  <TableRow>
        <TableCell className={(!result.hasOwnProperty('note'))?'lastRow':''} key="aspect" colSpan={2}>
          <h4>No parking spaces are required for this use.</h4>
        </TableCell>
      </TableRow>}
      {result.hasOwnProperty('standardSpaces') && <TableRow>
        <TableCell key="aspect" colSpan={2}>
          <h4>Min. Automobile Spaces Required: {result.standardSpaces.toString()}</h4>
        </TableCell>
      </TableRow>}
      {result.hasOwnProperty('reducedSpaces') && result.hasOwnProperty('standardSpaces') && (result.reducedSpaces < result.standardSpaces) && <TableRow>
        <TableCell key="aspect" colSpan={2}>
          <h4>Reduced Minimum: {result.reducedSpaces.toString()}  (Infill Overlay Districts Only - zone change approval may be required)</h4>
        </TableCell>
      </TableRow>}
      {result.hasOwnProperty('bicycleSpaces') && (result.bicycleSpaces > 0) && <TableRow>
        <TableCell key="aspect" colSpan={2}>
          <h4>Minumim Bicycle Spaces: {result.bicycleSpaces} Required (MU, NIO, and CIO districts only)</h4>
        </TableCell>
      </TableRow>}
      {result.hasOwnProperty('loadingSpaces') && <TableRow>
        <TableCell className={(!result.hasOwnProperty('note'))?'lastRow':''} key="aspect" colSpan={2}>
          <h4>Loading Spaces: {(!isNaN(result.loadingSpaces) && result.loadingSpaces > 0) ? result.loadingSpaces.toString() + ' (10 ft. wide x 25 ft. deep with 14-foot vertical clearance)' : 'Not Required'}</h4>
        </TableCell>
      </TableRow>}
      {result.hasOwnProperty('note') && <TableRow>
        <TableCell className={'lastRow'} key="aspect" colSpan={2}>
          <h4>Specific Notes:</h4>
          <div>{result.note}</div>
        </TableCell>
      </TableRow>}
    </TableBody>
  );
};
export default ParkingResultTableBody;