import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import {ParkingUseTypes} from "./ParkingUses";
import PropTypes from "prop-types";

const ParkingUseCategorySelection = (props) => {
  return (
    <Select
      label={props.label}
      id={props.listName}
      value={props.currentValue}
      onChange={props.onChange}
    >
      {Object.keys(ParkingUseTypes).map((category) => (
        <MenuItem key={category} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  );
};
export default ParkingUseCategorySelection;
ParkingUseCategorySelection.propTypes = {
  currentValue: PropTypes.string,
  label: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};