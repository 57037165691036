import React, {useCallback, useEffect, useState} from 'react';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "../../../common/components";
import {ParkingUseTypes} from "./ParkingUses";
import {isNotBlank} from "../../../common/utils";
import {haveFormData} from "./ParkingCalculator";
import ParkingResultTableBody from "./ParkingResultTableBody";

const ParkingResults = ({useCategory, useType, formData, infill, compliance, pinned, pin, unpin}) => {
  const [result, setResult] = useState({});
  const [descriptor, setDescriptor] = useState(null);
  const [totalResult, setTotalResult] = useState(null);

  const pinResult =  (result) => {
    pin(result);
    setResult({});
  };

  const unpinResult = (key) => {
    unpin(key);
  };

  const calcResult = useCallback(() => {
    let groupResults = [0,0,0,0,0,0,0,0]; //lets start with 8 input groups
    if (descriptor && descriptor.thresholds) {
      descriptor.thresholds.forEach((quantity) => {
        let group = (quantity.group) ? quantity.group : 0;
        let newVal = formData[quantity.value_designator];
        newVal = quantity.base * (newVal / quantity.quanta);
        groupResults[group] = groupResults[group] + newVal;
      });
    }
    let total = 0;
    groupResults.forEach((val) => {
      total += val;
    });
    return total;
  }, [descriptor, formData]);

  const standardSpaces = useCallback(() => {
    let minresult = (descriptor && descriptor.min_result) ? descriptor.min_result : 0;
    let minstandard = (descriptor && descriptor.min_standard_spaces) ? descriptor.min_standard_spaces : 0;
    return Math.max(minstandard,Math.round(Math.max(minresult, calcResult())));
  }, [calcResult, descriptor]);

  const loadingSpaces = useCallback(() => {
    let tfa = formData['tfa'];
    if (tfa <= 10000) {
      return 0;
    } else if (tfa >= 10001 && tfa <= 75000) {
      return 1;
    } else if (tfa >= 75001 && tfa <= 150000) {
      return 2;
    } else if (tfa <= 300000) {
      return 3;
    } else {
      return (tfa/100000);
    }
  }, [formData]);

  const bicycleSpaces = useCallback(() => {
    if (descriptor) {
      if (descriptor.bicycle_spaces) {
        return descriptor.bicycle_spaces;
      }
    }
    return 0;
  }, [descriptor]);

  const buildKey = useCallback(() => {
    let key = useType;
    if (descriptor) {
      if (descriptor.form) {
        descriptor.form.forEach((form) => {
          let fragment = " | " + formData[form.value_designator] + " " + form.label;
          key = key + fragment;
        });
      }
      if (descriptor.loading_required) {
        let fragment = " | " + formData['tfa'] + " Sq. Ft. Total Floor Area";
        key = key + fragment;
      }
    }
    return key;
  }, [descriptor, formData, useType]);

  const isAlreadyPinned = useCallback(() => {
    let rc = false;
    if (pinned && pinned.length > 0) {
      for (const pin in pinned) {
        if (pinned[pin].key === buildKey(useType, formData)) {
          rc = true;
          break;
        }
      }
    }
    return rc;
  }, [buildKey, formData, pinned, useType]);

  const isParkingRequired = useCallback(() => {
    return(descriptor && (descriptor.form || descriptor.min_standard_spaces));
  },[descriptor]);

  useEffect(() => {
    let result = {};
    result.key = buildKey();
    result.parkingRequired = false;
    if (descriptor) {
      if (descriptor.min_standard_spaces || descriptor.form) {
        result.parkingRequired = true;
        result.standardSpaces = standardSpaces();
        if (Object.keys(formData).length > 0) {
          result.loadingSpaces = loadingSpaces();
          result.bicycleSpaces = bicycleSpaces();
          result.infill = infill;
          result.complies = compliance;
          result.reducedSpaces = Math.round(result.standardSpaces * ((infill) ? ((compliance) ? 0.5 : 0.75) : 1));
        }
      }
      if (descriptor.note) {
        result.note = descriptor.note;
      }
      setResult(result);
      if (pinned && pinned.length > 0) {
        let tr = {
          key: 'Totals',
          standardSpaces: 0,
          loadingSpaces: 0,
          bicycleSpaces: 0,
          reducedSpaces: 0,
          infill: false,
          complies: false,
          parkingRequired: true
        };
        tr.standardSpaces += (isNaN(result.standardSpaces)) ? 0 : result.standardSpaces;
        tr.loadingSpaces += (isNaN(result.loadingSpaces)) ? 0 : result.loadingSpaces;
        tr.bicycleSpaces += (isNaN(result.bicycleSpaces)) ? 0 : result.bicycleSpaces;
        tr.reducedSpaces += (isNaN(result.reducedSpaces)) ? 0 : result.reducedSpaces;
        pinned.forEach((pin) => {
          if (Object.keys(pin).length > 1) {
            tr.standardSpaces += (isNaN(pin.standardSpaces)) ? 0 : pin.standardSpaces;
            tr.loadingSpaces += (isNaN(pin.loadingSpaces)) ? 0 : pin.loadingSpaces;
            tr.bicycleSpaces += (isNaN(pin.bicycleSpaces)) ? 0 : pin.bicycleSpaces;
            tr.reducedSpaces += (isNaN(pin.reducedSpaces)) ? 0 : pin.reducedSpaces;
          }
        });
        setTotalResult(tr);
      }
    }
  },[bicycleSpaces, buildKey, descriptor, infill, compliance, loadingSpaces, standardSpaces, pinned, formData]);

  useEffect(() => {
    setDescriptor((isNotBlank(useCategory) && isNotBlank(useType)) ?  ParkingUseTypes[useCategory][useType] : null);
  }, [useCategory, useType]);

  return (
    <div className="calculatorResult">
      <h2>Results</h2>
      {isNotBlank(useType) && !isParkingRequired() && !isAlreadyPinned() &&  <Table id="parkingResultDetail" className="parkingResultTable reactTable">
        <colgroup>
          <col key="aspect" style={{width: '90%'}}/>
          <col key="value" style={{width: '10%'}}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell key="aspect">
              <h3>{result.key}</h3>
            </TableCell>
            <TableCell key="value">
              <Button variant={'contained'} onClick={() => pinResult(result)}>Pin</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={(!result.hasOwnProperty('note'))?'lastRow':''} key="aspect" colSpan={2}>
              <h4>No parking spaces are required for this use.</h4>
            </TableCell>
          </TableRow>
          {result.hasOwnProperty('note') && <TableRow>
            <TableCell className={'lastRow'} key="aspect" colSpan={2}>
              <h4>Specific Notes:</h4>
              <div>{result.note}</div>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      }
      {isParkingRequired() && haveFormData(useCategory, useType, formData) && <div className="parkingSpaceResults">
        <Table id="parkingResultDetail" className="parkingResultTable reactTable">
          <colgroup>
            <col key="aspect" style={{width: '90%'}}/>
            <col key="value" style={{width: '10%'}}/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell key="aspect">
                <h3>{result.key}</h3>
              </TableCell>
              <TableCell key="value">
                <Button variant={'contained'} onClick={() => pinResult(result)}>Pin</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <ParkingResultTableBody result={result}/>
        </Table>
      </div>}
      {pinned && pinned.length > 0 && <div className='parkingPinnedResultsContainer'>
        {pinned.map((result, index) => {
          return (
            <div className="parkingPinnedResultsTablesContainer">
              <Table id="parkingResultDetail" className="parkingResultTable reactTable">
                <colgroup>
                  <col key="aspect" style={{width: '90%'}}/>
                  <col key="value" style={{width: '10%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell key="aspect">
                      <h3>{result.key}</h3>
                    </TableCell>
                    <TableCell key="value">
                      <Button variant={'contained'} onClick={() => unpinResult(result.key)}>Unpin</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <ParkingResultTableBody result={result}/>
              </Table>

            </div>
          );
        })}
        {totalResult && <Table id="parkingResultDetail" className="parkingResultTable reactTable">
          <colgroup>
            <col key="aspect" style={{width: '90%'}}/>
            <col key="value" style={{width: '10%'}}/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell key="aspect" colSpan={2}>
                <h3>Totals</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <ParkingResultTableBody result={totalResult}/>
        </Table>}
      </div>
      }
    </div>
  );
};
export default ParkingResults;


