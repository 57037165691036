import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import React, {useCallback, useMemo} from "react";
import TextField from './TextField';
import Chip from './Chip';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";

const useAutocompleteStyles = makeStyles({
  groupUl: { margin: "0 !important" }
});

// Copied from the MUI useAutocomplete.js code
const normalizeValue = (value) => value?.toString()?.trim()?.toLowerCase()
  ?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

const defaultIsOptionEqualToValue = (option, value) =>
  option === value || option?.value === value || option?.label === value;

const Autocomplete = (props) => {

  const { label, renderInput, options, filterOptions = null,
    isOptionEqualToValue = defaultIsOptionEqualToValue, getOptionSelected = defaultIsOptionEqualToValue,
    getOptionLabel = null, classes = {}, variant = "standard", size = "small",
    name, freeSolo = false, autoHighlight = !freeSolo, onChange,
    onInputChange = freeSolo ? onChange : null, matchFromStart = true,
    disablePortal = true, ...otherProps
  } = props;
  const {
    groupUl: groupUlClasses = "",
    ...otherClasses
  } = classes;

  const autocompleteStyles = useAutocompleteStyles(props);

  const defaultFilterOptions = useCallback((options, { inputValue, getOptionLabel }) => {
    let input = normalizeValue(inputValue);
    return !input
      ? options
      : options.filter((option) => {
        const optionValue = normalizeValue(option?.value ?? option);
        const optionLabel = normalizeValue(getOptionLabel ? getOptionLabel(option) : option?.label ?? option);
        return matchFromStart ? optionValue.startsWith(input) || optionLabel.startsWith(input)
          : optionValue.includes(input) || optionLabel.includes(input);
      });
  }, [matchFromStart]);

  const defaultGetOptionLabel = useCallback((option) => {
    if (typeof option === "string") {
      if (options) {
        const optionMatch = options.find((o) => o === option || o?.value === option || o?.label === option);
        if (optionMatch) {
          return typeof optionMatch === "string" ? optionMatch : optionMatch?.label ?? "";
        }
      }
      return option;
    }
    return option?.label ?? "";
  }, [options]);

  const renderChips = useMemo(() => props.multiple
    ? (values, getTagProps) => values.map((v, i) => {
      const {key, ...tagProps} = getTagProps({i});
      const optionMatch = options.find((o) => o === v || o?.value === v || o?.label === v);
      const label = getOptionLabel ? getOptionLabel(optionMatch) : defaultGetOptionLabel(optionMatch);
      return (<Chip key={key} label={label != null ? label.toString() : null} {...tagProps} />);
    }) : null, [defaultGetOptionLabel, getOptionLabel, options, props.multiple]);

  return (
    <MuiAutocomplete
      renderInput={renderInput ? renderInput : (params) =>
        <TextField
          {...params}
          label={label}
          variant={variant}
          name={name}
        />
      }
      options={options}
      filterOptions={filterOptions ? filterOptions : defaultFilterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel ? getOptionLabel : defaultGetOptionLabel}
      renderTags={renderChips}
      classes={{
        groupUl: (autocompleteStyles.groupUl ? autocompleteStyles.groupUl + " " : "") + groupUlClasses,
        ...otherClasses
      }}
      size={size}
      freeSolo={freeSolo}
      autoHighlight={autoHighlight}
      disablePortal={disablePortal}
      onChange={onChange}
      onInputChange={onInputChange}
      {...otherProps}
    />
  );
};
Autocomplete.propTypes = {
  label: PropTypes.string,
  renderInput: PropTypes.func,
  options: PropTypes.array.isRequired,
  filterOptions: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
  classes: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  freeSolo: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  matchFromStart: PropTypes.bool,
  disablePortal: PropTypes.bool
};
export default Autocomplete;