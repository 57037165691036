const PermanentSignageUseTypes = {
  'Base Residential Districts(RE, SF-1, SF-2, MDR, and HDR)': {
    'Subdivision Entry': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '2 per entry to subdivision'
        },
        'Sign Area (max)': {
          fixed: '25 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '8 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '200 ft. from other related subdivision signs'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/subdivision_entry_sign.jpg',
        'Notes': null
      }
    },
    'Monument': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 50, base: 15, quanta: 0, value_designator: 'sf'},
            {max: 75, base: 20, quanta: 0, value_designator: 'sf'},
            {max: 100, base: 30, quanta: 0, value_designator: 'sf'},
            {max: 125, base: 40, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 50, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 100, base: 5, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 10, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '50 ft. from any other freestanding sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/freestanding_sign.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'sf', label: 'Street Frontage Ft.', type: 'number'}
      ],
    },
    'Wall': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building'
        },
        'Sign Area (max)': {
          fixed: '48 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.5, quanta: 1, value_designator: 'width'},
          ],
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 inches; 0\' horizontal projection from wall'
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/wall_sign.jpg',
        'Notes': null,
      },
      form: [
        {value_designator: 'width', label: 'Width of Building Façade (ft.)', type: 'number'}
      ]
    },
    'Mural': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          max_result: 1500,
          thresholds: [
            {max: 0, base: 1, quanta: 0.75, value_designator: 'abf'},
          ],
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 1, quanta: 0.5, value_designator: 'wbf'},
          ],
        },
        'Sign Height (max)': {
          fixed: '30 ft. from grade',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/mural_sign.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'abf', label: 'Area of Building Façade (sq. ft.)', type: 'number'},
        {value_designator: 'wbf', label: 'Width of Building Façade (ft.)', type: 'number'}
      ],
    },
  },
  'Base Public and Nonresidential (NC, AC, OF, HC, IP, LI, and GI) ': {
    'Monument': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 300, base: 50, quanta: 0, value_designator: 'sf'},
            {max: 450, base: 120, quanta: 0, value_designator: 'sf'},
            {max: 625, base: 180, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 250, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Width (max)': {
          fixed: '15 ft.'
        },
        'Sign Height (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 300, base: 15, quanta: 0, value_designator: 'sf'},
            {max: 450, base: 25, quanta: 0, value_designator: 'sf'},
            {max: 625, base: 30, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 35, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Setback (min)': {
          fixed: 'ft.',
          thresholds: [
            {max: 15, base: 20, quanta: 0, link_designator: 'Sign Height (max)'},
            {max: 25, base: 30, quanta: 0, link_designator: 'Sign Height (max)'},
            {max: 30, base: 40, quanta: 0, link_designator: 'Sign Height (max)'},
            {max: 35, base: 45, quanta: 0, link_designator: 'Sign Height (max)'},
          ],
        },
        'On-Site Spacing (min)': {
          fixed: '50 ft. from any other freestanding sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/freestanding_sign.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'sf', label: 'Street Frontage Ft.', type: 'number'}
      ],
    },
    'Canopy': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per canopy'
        },
        'Sign Area (max)': null,
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'cw'},
          ],
        },
        'Sign Height (max)': {
          fixed: '3 ft. above roofline',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 inches',
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/canopy_ii.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'cw', label: 'Canopy Width Ft.', type: 'number'}
      ],
    },
    'Freestanding Canopy': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '2 per canopy'
        },
        'Sign Area (max)': null,
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'cw'},
          ],
        },
        'Sign Height (max)': {
          fixed: '3 ft. above roofline',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 inches',
        },
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/canopy_ii.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'cw', label: 'Canopy Width Ft.', type: 'number'}
      ],
    },
    'Mural': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: 'No maximum'
        },
        'Sign Area (max)': {
          fixed: '750 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '35 ft.'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/mural_sign.jpg',
        'Notes': null
      },
    },
    'Pole Frontage on arterial or collector street': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 300, base: 50, quanta: 0, value_designator: 'sf'},
            {max: 450, base: 120, quanta: 0, value_designator: 'sf'},
            {max: 625, base: 180, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 250, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Width (max)': {
          fixed: '15 ft.'
        },
        'Sign Height (max)': {
          fixed: 'ft.',
          max_result: 30,
          min_result: 5,
          thresholds: [
            {max: 99, base: 1, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 1, quanta: 20, baseline: 5, value_designator: 'sf'},
          ],
        },
        'Sign Setback - Front (min)': {
          fixed: 'ft.',
          min_result: 10,
          thresholds: [
            {max: 0, base: 1.43, quanta: 1, link_designator: 'Sign Height (max)'},
          ],
        },
        'Sign Setback - Side (min)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.25, quanta: 1, value_designator: 'sf'},
          ],
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/pole_sign.jpg',
        'Notes': 'Estimated front setback is based on the maximum sign height allowed. Actual setback distance may be different.'
      },
      form: [
        {value_designator: 'sf', label: 'Street Frontage Ft.', type: 'number'}
      ],
    },
    'Pole Frontage on expressway': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 300, base: 50, quanta: 0, value_designator: 'sf'},
            {max: 450, base: 120, quanta: 0, value_designator: 'sf'},
            {max: 625, base: 180, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 250, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Width (max)': {
          fixed: '15 ft.'
        },
        'Sign Height (max)': {
          fixed: 'ft.',
          max_result: 35,
          min_result: 20,
          thresholds: [
            {max: 99, base: 1, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 1, quanta: 40, baseline: 20, value_designator: 'sf'},
          ],
        },
        'Sign Setback - Front (min)': {
          fixed: 'ft.',
          min_result: 10,
          thresholds: [
            {max: 0, base: 1.22, quanta: 1, link_designator: 'Sign Height (max)'},
          ],
        },
        'Sign Setback - Side (min)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.25, quanta: 1, value_designator: 'sf'},
          ],
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/pole_sign.jpg',
        'Notes': 'Estimated front setback is based on the maximum sign height allowed. Actual setback distance may be different.'
      },
      form: [
        {value_designator: 'sf', label: 'Street Frontage Ft.', type: 'number'}
      ],
    },
    'Projecting (Blade)': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building façade'
        },
        'Sign Area (max)': {
          fixed: '24 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '3 ft.'
        },
        'Sign Height (max)': {
          fixed: '10 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft. clearance'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '48 in.'
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/projecting_sign.jpg',
        'Notes': null
      },
    },
    'Wall': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: 'No Maximum'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          max_result: 1500,
          thresholds: [
            {max: 0, base: 0.2, quanta: 1, value_designator: 'abf'},
          ],
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'wbf'},
          ],
        },
        'Sign Area per Tenant (max)': {
          fixed: 'sq. ft.',
          max_result: 1500,
          req: 'multi',
          thresholds: [
            {max: 0, base: 0.1, quanta: 1, value_designator: 'atf'},
          ],
        },
        'Sign Width per Tenant (max)': {
          fixed: 'ft.',
          req: 'multi',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'wtf'},
          ],
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 in.',
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/wall_sign.jpg',
        'Notes': '<ol><li>See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-633"}>Section 39.03.023.d.5.B</a> for more information on computing sign area for multi-tenant buildings.</li></ol>'
      },
      form: [
        {value_designator: 'abf', label: 'Area of Building Façade (sq. ft.)', type: 'number'},
        {value_designator: 'wbf', label: 'Width of Building Façade (ft.)', type: 'number'},
        {value_designator: 'multi', label: 'Multi-Tenant Building', type: 'switch'},
        {value_designator: 'atf', label: 'Area of Individual Tenant Façade (sq. ft.)', type: 'number', req: 'multi'},
        {value_designator: 'wtf', label: 'Width of Individual Tenant  Façade (ft.)', type: 'number', req: 'multi'}
      ],
    },
  },
  'Base Mixed-Use (MU-1, MU-2, MU-3, and MU-5) ': {
    'Monument': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.',
        },
        'Sign Width (max)': {
          fixed: '10 ft.'
        },
        'Sign Height (max)': {
          fixed: '10 ft.',
        },
        'Sign Setback (min)': {
          fixed: '10 ft.',
        },
        'On-Site Spacing (min)': {
          fixed: '50 ft. from any other freestanding sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/freestanding_sign.jpg',
        'Notes': null
      },
    },
    'Canopy': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per canopy'
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'cw'},
          ],
        },
        'Sign Height (max)': {
          fixed: '3 ft. above roofline',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 inches',
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/canopy_ii.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'cw', label: 'Canopy Width Ft.', type: 'number'}
      ],
    },
    'Wall': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          max_result: 1500,
          thresholds: [
            {max: 0, base: 0.2, quanta: 1, value_designator: 'abf'},
          ],
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'wbf'},
          ],
        },
        'Sign Area per Tenant (max)': {
          fixed: 'sq. ft.',
          max_result: 1500,
          thresholds: [
            {max: 0, base: 0.1, quanta: 1, value_designator: 'atf'},
          ],
        },
        'Sign Width per Tenant (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.1, quanta: 1, value_designator: 'wtf'},
          ],
        },
        'Sign Height (max)': {
          fixed: '30 ft. from grade',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '18 in.',
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/wall_sign.jpg',
        'Notes': '<ol><li>See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-633"}>Section 39.03.023.d.5.B</a> for more information on computing sign area for multi-tenant buildings.</li></ol>'
      },
      form: [
        {value_designator: 'abf', label: 'Area of Building Façade (sq. ft.)', type: 'number'},
        {value_designator: 'wbf', label: 'Width of Building Façade (ft.)', type: 'number'},
        {value_designator: 'multi', label: 'Multi-Tenant Building', type: 'switch'},
        {value_designator: 'atf', label: 'Area of Individual Tenant Façade (sq. ft.)', type: 'number', req: 'multi'},
        {value_designator: 'wtf', label: 'Width of Individual Tenant  Façade (ft.)', type: 'number', req: 'multi'}
      ],
    },
    'Mural': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 0, base: 0.5, quanta: 1, value_designator: 'abf'},
          ],
        },
        'Sign Width (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.75, quanta: 1, value_designator: 'wbf'},
          ],
        },
        'Sign Area per Tenant (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 0, base: 0.1, quanta: 1, value_designator: 'atf'},
          ],
        },
        'Sign Width per Tenant (max)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.1, quanta: 1, value_designator: 'wtf'},
          ],
        },
        'Sign Height (max)': {
          fixed: '30 ft. from grade',
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/mural_sign.jpg',
        'Notes': '<ol><li>See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-633"}>Section 39.03.023.d.5.B</a> for more information on computing sign area for multi-tenant buildings.</li></ol>'
      },
      form: [
        {value_designator: 'abf', label: 'Area of Building Façade (sq. ft.)', type: 'number'},
        {value_designator: 'wbf', label: 'Width of Building Façade (ft.)', type: 'number'},
        {value_designator: 'multi', label: 'Multi-Tenant Building', type: 'switch'},
        {value_designator: 'atf', label: 'Area of Individual Tenant Façade (sq. ft.)', type: 'number', req: 'multi'},
        {value_designator: 'wtf', label: 'Width of Individual Tenant  Façade (ft.)', type: 'number', req: 'multi'}
      ],
    },
    'Marquee': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per street facing  building facade'
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.',
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '4 ft.',
        },
        'Sign Setback (min)': {
          fixed: '8 ft. clearance',
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '6 ft.',
        },
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/marquee_sign.jpg',
        'Notes': null
      },
    },


    'Pole Frontage on arterial or collector street': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per lot, per street frontage'
        },
        'Sign Area (max)': {
          fixed: 'sq. ft.',
          thresholds: [
            {max: 300, base: 50, quanta: 0, value_designator: 'sf'},
            {max: 450, base: 120, quanta: 0, value_designator: 'sf'},
            {max: 625, base: 180, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 250, quanta: 0, value_designator: 'sf'},
          ],
        },
        'Sign Width (max)': {
          fixed: '15 ft.'
        },
        'Sign Height (max)': {
          fixed: 'ft.',
          max_result: 30,
          min_result: 5,
          thresholds: [
            {max: 99, base: 1, quanta: 0, value_designator: 'sf'},
            {max: 0, base: 1, quanta: 20, value_designator: 'sf'},
          ],
        },
        'Sign Setback - Front (min)': {
          fixed: 'ft.',
          min_result: 10,
          thresholds: [
            {max: 0, base: 1.43, quanta: 1, link_designator: 'Sign Height (max)'},
          ],
        },
        'Sign Setback - Side (min)': {
          fixed: 'ft.',
          thresholds: [
            {max: 0, base: 0.25, quanta: 1, value_designator: 'sf'},
          ],
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': true,
        'Illustrated Example': '/images/calculators/pole_sign.jpg',
        'Notes': null
      },
      form: [
        {value_designator: 'sf', label: 'Street Frontage Ft.', type: 'number'}
      ],
    },
    'Projecting (Blade)': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: '1 per building façade'
        },
        'Sign Area (max)': {
          fixed: '24 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '3 ft.'
        },
        'Sign Height (max)': {
          fixed: '10 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft. clearance'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': {
          fixed: '48 in.'
        },
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/projecting_sign.jpg',
        'Notes': null
      },
    },
    'Hanging': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': null,
        'Sign Area (max)': {
          fixed: '6 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '3 ft.'
        },
        'Sign Height (max)': {
          fixed: '15 ft. above grade'
        },
        'Sign Setback (min)': {
          fixed: '8 ft. clearance'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/hanging_sign.jpg',
        'Notes': null
      },
    },
    'Incidental': {
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: 'No maximum'
        },
        'Sign Area (max)': {
          fixed: '100 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '10 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '10 ft. from any other incidental sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/incidental_sign.jpg',
        'Notes': null
      },
    },

  }

};

const TemporarySignageUseTypes = {
  'Base Residential Districts(RE, SF-1, SF-2, MDR, and HDR)': {
    'Banners': {
      note: 'Banner standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '2 per lot',
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/banner_sign.jpg',
        'Notes': null
      }
    },
    'Incidental': {
      note: 'Incidental standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '4 per lot',
        },
        'Sign Area (max)': {
          fixed: '32 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '4 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/incidental_sign.jpg',
        'Notes': null
      }
    },
    'Flag': {
      note: 'Flag standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': null,
        'Sign Area (max)': null,
        'Sign Width (max)': {
          fixed: '8 ft.'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': {
          fixed: '5 ft.'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/flag_sign.jpg',
        'Notes': null
      }
    },
    'Temporary': {
      note: 'Temp standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '2 per lot'
        },
        'Sign Area (max)': {
          fixed: '18 x 24 inches (3 sq. ft.)'
        },
        'Sign Width (max)': {
          fixed: '18 or 24 inches'
        },
        'Sign Height (max)': {
          fixed: '18 or 24 inches'
        },
        'Sign Setback (min)': {
          fixed: '5 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '30 ft.'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/temporary_sign.jpg',
        'Notes': null
      }
    },
  },
  'Base Public and Nonresidential (NC, AC, OF, HC, IP, LI, and GI) ': {
    'Banners': {
      note: 'Banner standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '2 per lot',
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/banner_sign.jpg',
        'Notes': null
      }
    },
    'Feather': {
      note: 'Feather standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '3 per lot',
        },
        'Sign Area (max)': {
          fixed: '24 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '3 ft.'
        },
        'Sign Height (max)': {
          fixed: '8 ft.'
        },
        'Sign Setback (min)': {
          fixed: '5 ft.'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/feather_flag_sign.jpg',
        'Notes': null
      }
    },
    'Incidental': {
      note: 'Incidental standards',
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: 'No maximum',
        },
        'Sign Area (max)': {
          fixed: '100 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '10 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '10 ft. from any other incidental sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/incidental_sign.jpg',
        'Notes': null
      }
    },
    'Flag': {
      note: 'Flag standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': null,
        'Sign Area (max)': null,
        'Sign Width (max)': {
          fixed: '8 ft.'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': {
          fixed: '5 ft.'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/flag_sign.jpg',
        'Notes': null
      }
    },
    'Temporary': {
      note: 'Temp standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: 'No maximum',
        },
        'Sign Area (max)': {
          fixed: '100 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum',
        },
        'Sign Height (max)': {
          fixed: '4 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '10 ft. from any other incidental or temporary sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/temporary_sign.jpg',
        'Notes': null
      }
    },

  },
  'Base Mixed-Use (MU-1, MU-2, MU-3, and MU-5) ': {
    'Banners': {
      note: 'Banner standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '2 per lot',
        },
        'Sign Area (max)': {
          fixed: '50 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/banner_sign.jpg',
        'Notes': null
      }
    },
    'A-Frame Sign or Easel': {
      note: 'Easel standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '1 per ground-floor entrance',
        },
        'Sign Area (max)': {
          fixed: '12 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '3 ft.'
        },
        'Sign Height (max)': {
          fixed: '6 ft.'
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/a_frame_sign.jpg',
        'Notes': null
      }
    },
    'Incidental': {
      note: 'Incidental standards',
      specs: {
        'Sign Permit Required': true,
        'Number of Signs (max)': {
          fixed: 'No maximum',
        },
        'Sign Area (max)': {
          fixed: '100 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum'
        },
        'Sign Height (max)': {
          fixed: '10 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '10 ft. from any other incidental sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/incidental_sign.jpg',
        'Notes': null
      }
    },
    'Flag': {
      note: 'Flag standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '3 per lot',
        },
        'Sign Area (max)': {
          fixed: '40 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '8 ft.'
        },
        'Sign Height (max)': null,
        'Sign Setback (min)': {
          fixed: '5 ft.'
        },
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/flag_sign.jpg',
        'Notes': null
      }
    },
    'Portable': {
      note: 'Port standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: '1 per lot',
        },
        'Sign Area (max)': {
          fixed: '4 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: '2 ft.',
        },
        'Sign Height (max)': {
          fixed: '4 ft.'
        },
        'Sign Setback (min)': null,
        'On-Site Spacing (min)': null,
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/a_frame_sign.jpg',
        'Notes': null
      }
    },

    'Temporary': {
      note: 'Temp standards',
      specs: {
        'Sign Permit Required': false,
        'Number of Signs (max)': {
          fixed: 'No maximum',
        },
        'Sign Area (max)': {
          fixed: '100 sq. ft.'
        },
        'Sign Width (max)': {
          fixed: 'No maximum',
        },
        'Sign Height (max)': {
          fixed: '4 ft.'
        },
        'Sign Setback (min)': {
          fixed: '10 ft.'
        },
        'On-Site Spacing (min)': {
          fixed: '10 ft. from any other incidental or temporary sign'
        },
        'Sign Projection (max)': null,
        'Electronic Message Display Allowed': false,
        'Illustrated Example': '/images/calculators/temporary_sign.jpg',
        'Notes': null
      }
    },
  }
};

export {PermanentSignageUseTypes, TemporarySignageUseTypes};