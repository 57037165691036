import * as PropTypes from "prop-types";
import React from "react";

const IndexPageWordReference = (props) => {

  const { reference } = props;

  return (
    <div style={{marginBottom: 4}}>
      <a href={reference.href} className="wordReference">{reference.label}</a>
    </div>
  );
};
IndexPageWordReference.propTypes = {
  reference: PropTypes.object.isRequired
};
export default IndexPageWordReference;
