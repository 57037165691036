import * as PropTypes from "prop-types";
import React from "react";
import {Autocomplete, CircularProgress, Dialog, MenuItem, Select, TextField} from "../../common/components";
import {If} from "../../common/containers";
import "./PubDocTypeEditDialog.css";

class PubDocTypeEditDialog extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    suggestedPubDocTypes: PropTypes.object,
    pubDocTypes: PropTypes.array,
    typeEditOpen: PropTypes.bool.isRequired,
    typeEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addType: PropTypes.func,
    editType: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      isNameFieldActive: false,
      title: "",
      sortType: ""
    };
  }

  handleNameChange = (event, value) => {
    this.setState({name: value});
  };

  handleDescriptionChange = (event) => {
    const value = event.target.value;
    this.setState({description: value});
  };

  handleCancelClick = () => {
    this.setState({name: "", description: "", isNameFieldActive: false});
    this.props.onCancel();
  };

  handleSaveClick = () => {
    const {custId, editType, addType, typeEditId} = this.props;
    const {name, description, sortType} = this.state;
    if (name.length > 0) {
      if (typeEditId) {
        editType(custId, name, description, typeEditId, sortType);
      } else {
        addType(custId, name, description, sortType);
      }
    }
  };

  handleSortTypeChange = (event) => {
    this.setState({"sortType": event.target.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.typeEditOpen;
    const { typeEditOpen, typeEditId } = this.props;
    if (typeEditOpen && !prevEditOpen) {
      const pubDocType = this.props.pubDocTypes.filter(type => {
        return type.id === typeEditId;
      })[0];
      this.setState({"name": pubDocType?.title ? pubDocType.title : ""});
      this.setState({"description": pubDocType?.description ? pubDocType.description : ""});
      this.setState({"sortType": pubDocType?.sortType ? pubDocType.sortType : ""});
      if (typeEditId) {
        this.setState({"title": "Edit a Public Document Type"});
      } else {
        this.setState({"title": "Add a new Public Document Type"});
        this.setState({"sortType": "CATEGORY_NAME_ASC"});
      }
    }
  }

  hasPubDocType = (title) => {
    const {pubDocTypes} = this.props;
    for (const pubDocType of pubDocTypes) {
      if (pubDocType.title === title) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {typeEditOpen, suggestedPubDocTypes, typeEditLoading} = this.props;
    const {name, description, title, sortType} = this.state;
    return (
      <Dialog
        id="doc-type-edit-dialog"
        title={title}
        open={Boolean(typeEditOpen)}
        onCancel={this.handleCancelClick}
        onSubmit={this.handleSaveClick}
        submitLabel="Save"
        submitDisabled={typeEditLoading || name == null || name.length < 1 || !name.trim()}
        width="md"
        DialogContentProps={{className: `dialogContent pubDocTypeDialogContent ${!typeEditLoading ?  "" : "loading"}`}}
      >
        <If test={typeEditLoading}>
          <div className="spinner">
            <CircularProgress size={80}/>
          </div>
        </If>
        <If test={!typeEditLoading}>
          <Autocomplete
            variant="outlined"
            id="doc-type-name-new"
            className="docTypeName suggestionField pubDocTypeDialogName"
            label="Name"
            options={!suggestedPubDocTypes ? [] : Object.keys(suggestedPubDocTypes)}
            getOptionDisabled={(option) => this.hasPubDocType(option)}
            groupBy={() => "Suggested Type names..."}
            renderOption={(option) => (<>
              {option}
              <If test={this.hasPubDocType(option)}>
                <span className="typeExists">&nbsp;[Already Exists]</span>
              </If>
            </>)}
            onChange={this.handleNameChange}
            freeSolo
          />
          <TextField
            variant="outlined"
            id="doc-type-description"
            className="docTypeDescription pubDocTypeDialogDescription"
            label="Description"
            minRows={6}
            value={!description ? "" : description}
            onChange={this.handleDescriptionChange}
            style={{marginTop: 16}}
            multiline
          />
          <Select label="Sorting Type" value={sortType} onChange={this.handleSortTypeChange}>
            <MenuItem value="CATEGORY_NAME_ASC">By Category Name (Ascending)</MenuItem>
            <MenuItem value="CATEGORY_NAME_DESC">By Category Name (Descending)</MenuItem>
          </Select>
        </If>
      </Dialog>
    );
  }
}
export default PubDocTypeEditDialog;
