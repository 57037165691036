import React from "react";
import {BoxedSection, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "../../common/components";
import {If} from "../../common/containers";
import "./LawAdminSection.css";

class LawAdminSection extends React.PureComponent {

  constructor(props) {
    super(props);

    const featureLawView = JSON.parse(props["featureLawViewJson"]);
    const featureLawCciView = JSON.parse(props["featureLawCciViewJson"]);
    this.state = {
      isCustomizationAdmin: (props['isCustomizationAdmin'] === 'true'),
      isFeatureAdmin: (props['isFeatureAdmin'] === 'true'),
      publicLawView: featureLawView["currentLevel"] === "PUBLIC",
      publicLawViewAllowed: featureLawView["maximumLevel"] === "PUBLIC",
      publicLawCciView: featureLawCciView["currentLevel"] === "PUBLIC",
      publicLawCciViewAllowed: featureLawCciView["maximumLevel"] === "PUBLIC",
      lawsTitleField: props.customization.lawTitleField
    };
  }

  handleCheckClick = (field, action) => (event) => {
    const value = event.target.checked;
    action(value);
    this.setState({[field]: value});
  };

  handleRadioClick = (field, action) => (event) => {
    const value = event.target.value;
    action(value);
    this.setState({[field]: value});
  };

  render() {
    const {setLawsEnabled, setLawsTitleField, setLawsCciEnabled} = this.props;
    const {isCustomizationAdmin, isFeatureAdmin, publicLawView, publicLawViewAllowed, publicLawCciView,
      publicLawCciViewAllowed, lawsTitleField} = this.state;
    return (
      <BoxedSection
        title="New Law Customization"
        instructions="Manage legislative features within your Code"
        help={{topic: 'laws', subtopic: 'Manage'}}
        id={"lawSection"}
      >
        <If test={isFeatureAdmin}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={publicLawView}
                  onChange={this.handleCheckClick('publicLawView', setLawsEnabled)}
                />
              }
              label="New Laws page displayed without login"
              disabled={!publicLawViewAllowed}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={publicLawCciView}
                  onChange={this.handleCheckClick('publicLawCciView', setLawsCciEnabled)}
                />
              }
              label="New Law Indicators displayed without login"
              disabled={!publicLawCciViewAllowed}
            />
          </div>
        </If>
        <If test={isCustomizationAdmin}>
          <div>
            <FormControl component="fieldset">
              <FormLabel className="lawAdminTitleFieldLabel" component="legend">Default New Laws "Title"</FormLabel>
              <RadioGroup
                aria-label="Choose legislation title field"
                value={lawsTitleField}
                onChange={this.handleRadioClick('lawsTitleField', setLawsTitleField)}
              >
                <FormControlLabel className="lawAdminTitleRadioLabel" value="description" control={<Radio className="lawAdminTitleRadio"/>} label="Law Designation"/>
                <FormControlLabel className="lawAdminTitleRadioLabel" value="originalFilename" control={<Radio className="lawAdminTitleRadio"/>} label="Original Filename"/>
              </RadioGroup>
            </FormControl>
          </div>
        </If>
      </BoxedSection>
    );
  }
}

export default LawAdminSection;
