import React, {useMemo} from "react";
import MuiSelect from '@material-ui/core/Select';
import {FormControl, InputLabel, MenuItem} from "./index";
import PropTypes from "prop-types";
import "./Select.css";

const isValueEmpty = (value) =>
  value === "" || value?.value === ""
    || value === 0 || value?.value === 0
    || value === "0" || value?.value === "0"
    || value === false || value?.value === false
    || value === "false" || value?.value === "false";

const anyChildrenEmpty = (children) => {
  if (children == null) {
    return false;
  }
  if (Array.isArray(children)) {
    for (const child of children) {
      if (anyChildrenEmpty(child)) {
        return true;
      }
    }
  } else if (typeof children === "object") {
    return isValueEmpty(children?.props?.value);
  }
  return false;
};

const Select = (props) => {
  const { id = null, name = null, label, labelId, className = "", emptyOption, options,
    variant = "standard", size = "small", style = {}, shrinkLabel = null, children,
    InputProps = {}, InputLabelProps = {}, fullWidth = true, displayEmpty = true,
    ...otherProps
  } = props;
  const { id: labelPropsId, ...otherLabelProps } = InputLabelProps;
  const { marginTop = 4, width = fullWidth ? "100%" : "auto", ...otherStyles } = style;

  const inputElementId = useMemo(() => id ? id + "_input" : null, [id]);

  const inputName = useMemo(() => name ? name : id, [id, name]);

  const emptyOptionElement = useMemo(() => {
    if (emptyOption != null) {
      if (typeof emptyOption === "object") {
        return (
          <MenuItem
            key={emptyOption.value != null ? emptyOption.value : emptyOption}
            value={emptyOption.value != null ? emptyOption.value : emptyOption}
          >
            {emptyOption.label != null ? emptyOption.label : emptyOption}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={emptyOption} value={emptyOption}>{emptyOption}</MenuItem>
        );
      }
    }
    return null;
  }, [emptyOption]);

  const hasEmptyOption = useMemo(() => {
    if (emptyOptionElement) {
      return true;
    }
    if (options) {
      return options.some(o => isValueEmpty(o));
    } else if (children) {
      return anyChildrenEmpty(children);
    }
  }, [children, emptyOptionElement, options]);

  const shrinkLabelElement = useMemo(() => shrinkLabel != null ? shrinkLabel
    : (InputLabelProps.shrink != null ? InputLabelProps.shrink : ((props.value || (hasEmptyOption && isValueEmpty(props.value))) ? true : null)),
  [shrinkLabel, InputLabelProps.shrink, props.value, hasEmptyOption]
  );

  const labelIsString = useMemo(() => (typeof label === "string"), [label]);

  const labelElementId = useMemo(() =>
    (labelId ? labelId : (labelPropsId ? labelPropsId : (id ? id + "_label" : null))),
  [id, labelId, labelPropsId]
  );

  const labelElement = useMemo(() =>
    (labelIsString ? (<InputLabel id={labelElementId} shrink={shrinkLabelElement} {...otherLabelProps}>{label}</InputLabel>)
      : (label == null ? null : {label})),
  [label, labelElementId, labelIsString, otherLabelProps, shrinkLabelElement]
  );

  const optionElements = useMemo(() => {
    if (options) {
      return (options.map((o) => (
        <MenuItem
          key={o.value != null ? o.value : o}
          value={o.value != null ? o.value : o}
        >
          {o.label != null ? o.label : o}
        </MenuItem>))
      );
    } else {
      return children;
    }
  }, [children, options]);

  if (labelElement) {
    return (
      <FormControl
        id={id}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        className={className}
        style={{marginTop: marginTop, width: width, ...otherStyles}}
      >
        {labelElement}
        <MuiSelect
          id={inputElementId}
          name={inputName}
          labelId={labelElementId}
          label={labelIsString ? label : ""}
          variant={variant}
          fullWidth={fullWidth}
          displayEmpty={displayEmpty}
          inputProps={InputProps}
          {...otherProps}
        >
          {emptyOptionElement}
          {optionElements}
        </MuiSelect>
      </FormControl>
    );
  } else {
    return (
      <MuiSelect
        id={id}
        name={inputName}
        labelId={labelElementId}
        label={labelIsString ? label : ""}
        variant={variant}
        fullWidth={fullWidth}
        displayEmpty={displayEmpty}
        className={className}
        style={{width: width, ...otherStyles}}
        inputProps={InputProps}
        {...otherProps}
      >
        {emptyOptionElement}
        {optionElements}
      </MuiSelect>
    );
  }
};
Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelId: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  emptyOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  variant: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  InputLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  children: PropTypes.node
};
export default Select;