import * as PropTypes from "prop-types";
import React, {Component, Fragment} from "react";

import {Card, MenuItem, Select} from "../../common/components";
import {PageControl, PageControlContent, PageControlHeader} from "../../common/components/pagecontrol";

class SortComponent extends Component {

  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    hasNaturalOrderSort: PropTypes.bool.isRequired,
    hasDateSort: PropTypes.bool.isRequired,
    sortOrder: PropTypes.string,
    isSearchLoading: PropTypes.bool,
    setSortOrder: PropTypes.func
  };

  handleEvent = (preventDefault, handler) => (event) => {
    if (preventDefault) event.preventDefault();
    handler(event.target.value);
  };

  renderSelect = () => {
    const {sortOrder, hasNaturalOrderSort, hasDateSort, setSortOrder} = this.props;
    return (
      <Select
        value={sortOrder}
        onChange={this.handleEvent(false, setSortOrder)}
        inputProps={{id: "sortInput", name: "sortOrder"}}
        MenuProps={{anchorOrigin: {vertical: "bottom", horizontal: "left"}, getContentAnchorEl: null}}
      >
        <MenuItem value="relevance">Relevance</MenuItem>
        {hasNaturalOrderSort ? <MenuItem value="natural">Natural Order</MenuItem> : null}
        {hasDateSort ? <MenuItem value="date_asc">Date (Ascending)</MenuItem> : null}
        {hasDateSort ? <MenuItem value="date_desc">Date (Descending)</MenuItem> : null}
      </Select>
    );
  };
  renderWidgetContents = () => {
    return (
      <Fragment>
        <div><span className="cardTitleSmall">Sort By</span></div>
        <div>
          {this.renderSelect()}
        </div>
      </Fragment>
    );
  }

  render() {
    const {id, className, isSearchLoading, hasNaturalOrderSort, hasDateSort, isNewNav} = this.props;
    if (isSearchLoading || (!hasNaturalOrderSort && !hasDateSort)) return null;
    if (isNewNav) {
      return (
        <PageControl id={id} className={className}>
          <PageControlHeader label="Sort By" htmlFor="sortOrder"/>
          <PageControlContent>
            {this.renderSelect()}
          </PageControlContent>
        </PageControl>
      );
    } else {
      return (
        <Card id={id} className={`sidebarItem sidebarCard resultSortContainer${className ? className : ""}`}>
          {this.renderWidgetContents()}
        </Card>
      );
    }
  }
}

export default SortComponent;
