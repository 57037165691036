import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import {createAction} from "redux-actions";
import {post} from '../../common/utils/index';
import {custId} from "../utils/server-data";
import {
  API,
  BODY_TOP_SET,
  CALCULATORS_ENABLED_SET,
  DIALOG_CLOSE,
  DIALOG_INIT,
  DIALOG_SUBMIT_DISABLE,
  ERROR_ALL_DISMISS,
  ERROR_NON_FATAL_REGISTER,
  ERROR_REGISTER,
  EVENT_LOG,
  MEDIA_MATCH_HEIGHT,
  MEDIA_MATCH_WIDTH,
  PAGE_FILTER_ADD,
  PAGE_FILTER_REMOVE,
  PAGE_FILTER_SET,
  PRINT_LOADING_SET,
  SERVER_DATA_LOAD,
  USER_MESSAGE_HIDE,
  USER_MESSAGE_SHOW
} from "./types";

const loadServerData = createAction(SERVER_DATA_LOAD, ({
  username,
  publicAnnotation,
  archiveId,
  custId,
  customerName,
  customer,
  searchSuggestionWaitTime,
  analysisId,
  questions,
  reviewers,
  dueWarningDays,
  dueWarningIntervalHours,
  displayedQuestionIds,
  needsReviewFilter,
  assignedFilter,
  assignedToFilter,
  deferrableFilter,
  statusFilter,
  filterText,
  deferFilter,
  ia2FileTypes,
  pubDocsFileTypes,
  termsOfUseUrl,
  helpTopics,
  helpVideos,
  grantedPermissions,
  showDiscussionText,
  hideDiscussionText,
  multicodeStates
}) => mapValues({
  username,
  publicAnnotation,
  archiveId,
  custId,
  customerName,
  customer,
  searchSuggestionWaitTime,
  analysisId,
  questions,
  reviewers,
  dueWarningDays,
  dueWarningIntervalHours,
  displayedQuestionIds,
  needsReviewFilter,
  assignedFilter,
  assignedToFilter,
  deferrableFilter,
  statusFilter,
  filterText,
  deferFilter,
  ia2FileTypes,
  pubDocsFileTypes,
  termsOfUseUrl,
  helpTopics,
  helpVideos,
  grantedPermissions,
  showDiscussionText,
  hideDiscussionText,
  multicodeStates
}, value => isUndefined(value) ? null : value));

const registerError = createAction(ERROR_REGISTER, (errorMessage, errorTitle, errorData = ['no-data'], error) => {
  try {
    console.error(`${errorMessage} [${errorData ? errorData.join(', ') : 'no-data'}]`, error);
    if (errorMessage) error.message = errorMessage;
    if (errorTitle) error.title = errorTitle;
    // noinspection JSUnresolvedVariable
    if (window?.gtag) {
      window.gtag('event', 'exception', {
        description: errorMessage,
        fatal: true
      });
    }
  } catch (e) {
    console.error("Failed to process error", e);
  }
  return {error};
});

const registerNonFatalError = createAction(ERROR_NON_FATAL_REGISTER, (errorMessage, errorTitle, errorData = ['no-data'], error) => {
  try {
    console.error(`${errorMessage} [${errorData.join(', ')}]`, error);
    if (errorMessage) error.message = errorMessage;
    if (errorTitle) error.title = errorTitle;
    // noinspection JSUnresolvedVariable
    if (window?.gtag) {
      //TODO: handle non fatal errors coming through here - maybe a different action for user error?
      window.gtag('event', 'exception', {
        description: errorMessage,
        fatal: false
      });
    }
  } catch (e) {
    console.error("Failed to process error", e);
  }
  return {error};
});


const logEvent = createAction(EVENT_LOG, (eventCategory, eventName, eventInfo) => {
  let eventInfoString = JSON.stringify(eventInfo);
  post(`/event?eventName=${eventName}&custId=${custId}&eventInfo=${encodeURIComponent(eventInfoString)}`, {});
  if (window?.gtag) {
    window.gtag('event', eventName, {
      event_category: (eventCategory ? eventCategory : 'generic'),
      event_label: custId,
      custId: custId,
      cust_id: custId,
      event_info: eventInfoString
    });
  }
});

const dismissErrors = createAction(ERROR_ALL_DISMISS, () => ({}));

const setBodyTop = createAction(BODY_TOP_SET, (bodyTop) => ({bodyTop}));

const setPrintLoading = createAction(PRINT_LOADING_SET, (isLoading) => ({isLoading}));
const setCalculatorsEnabled = createAction(CALCULATORS_ENABLED_SET, (calculatorsEnabled) => ({calculatorsEnabled}));
const addPageFilter = createAction(PAGE_FILTER_ADD, (filterId) => ({filterId}));
const removePageFilter = createAction(PAGE_FILTER_REMOVE, (filterId) => ({filterId}));
const setPageFilters = createAction(PAGE_FILTER_SET, (filterIds, filterValues) => ({filterIds, filterValues}));

const initDialog = createAction(DIALOG_INIT, (id, dialogProps) => ({id, dialogProps}));
const closeDialog = createAction(DIALOG_CLOSE, (id) => ({id}));
const disableDialogSubmit = createAction(DIALOG_SUBMIT_DISABLE, (id) => ({id}));

const matchMediaHeight = createAction(MEDIA_MATCH_HEIGHT, (height) => ({height}));
const matchMediaWidth = createAction(MEDIA_MATCH_WIDTH, (width) => ({width}));

const showUserMessage = createAction(USER_MESSAGE_SHOW, (title, text) => ({title, text}));
const hideUserMessage = createAction(USER_MESSAGE_HIDE, () => ({}));

/**
 * Api action
 * @typedef {Object} ApiAction
 * @property {string} type
 * @property {ApiPayload} payload
 */

/**
 * Api payload
 * @typedef {Object} ApiPayload
 * @param {string} label - The label to be associated with all generic dispatches
 * @param {string} method - One of GET/POST/PUT/DELETE/PATCH
 * @param {string} url - the url to invoke
 * @param {string} [errorMessage] - message to show to the user on failure
 * @property {object} [data]
 * @property {function} [onSuccess]
 * @property {function} [onFailure]
 */

/**
 * Create an api call action.  Creates generic start, end, error dispatches as well as dispatching the callbacks
 *
 * @param {ApiPayload} payload - additional options for the request
 *
 * @returns {ApiAction}
 */
const apiAction = createAction(API,
  ({label, method, url, data, errorMessage, onSuccess, onFailure}) =>
    ({label, method, url, data, errorMessage, onSuccess, onFailure}));

export {
  apiAction,
  loadServerData,
  dismissErrors,
  registerError,
  registerNonFatalError,
  setBodyTop,
  setCalculatorsEnabled,
  setPrintLoading,
  addPageFilter,
  removePageFilter,
  setPageFilters,
  logEvent,
  initDialog,
  closeDialog,
  disableDialogSubmit,
  matchMediaHeight,
  matchMediaWidth,
  showUserMessage,
  hideUserMessage,
};
