import {createAction} from "redux-actions";

const setBufferyardType = createAction('CALCULATOR/BUFFERYARD/TYPE/SET',(bufferyardType) => ({bufferyardType}));
const setParkingType = createAction('CALCULATOR/PARKING/TYPE/SET',(parkingType) => ({parkingType}));
const setSignageClass = createAction('CALCULATOR/SIGNAGE/CLASS/SET',(signageClass) => ({signageClass}));

export {
  setBufferyardType,
  setParkingType,
  setSignageClass
};